.promo-widget {
  margin-bottom: $twice-spacing-unit;

  .media__body {
    position: relative;
  }
}

.promo-widget--app_get {
  .media__img {
    border-radius: 18px;
  }

  .media__body {
    padding-top: 15px;
  }
}

.promo-widget--case_follow {
  .media__body {
    padding-top: 15px;
  }
}


.promo-widget--case_comment {
  .media__body {
    padding-top: 30px;
  }
}

.promo-widget--invite {
  p {
    margin-bottom: $half-spacing-unit;
  }
}


.promo-widget__icon {
  width: 120px;
  height: 120px;
  border: 2px solid $brand-color;
  border-radius: 60px;
  position: relative;
  .icon {
    fill: $brand-color;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.promo-widget--profile_avatar {
  .media__img {
    border-radius: 60px;
  }

  .media__body {
    height: 120px;
    max-width: 16em; // control "thanks" text width
  }

  .btn {
    margin-right: .5em;
    width: 191px;
  }

  .icon-spinner {
    position: absolute;
    top: 3px;
    fill: $low-text-color;
  }

}

@include media-query(palm) {
  .promo-widget .btn--wide,
  .promo-widget--profile_avatar .btn {
    width: auto;
    padding: 0 1em;
  }
}

.promo-widget--verify_confirm_pro_email,
.promo-widget--verify_provide_id,
.promo-widget--verify_provide_proof {
  .media__body {
    height: 120px;
    p {
      margin-bottom: 1em;
    }
    p.promo-widget--verify_confirm_pro_email__edit-text {
      margin-bottom: .5em;
    }
  }
}

.promo-widget__field {
  margin-bottom: $half-spacing-unit;

  #{$all-text-inputs} {
    box-sizing: border-box;
    transition: border-color;
    background-color: white;
    border-radius: 4px;
    border: 1px solid $light-grey;
    font-family: $form-font-family;
    font-size: $form-font-size;
    padding: 5px 10px;
    width: 100%;

    color: $link-color;

    &:hover {
      border: 1px solid darken($light-grey, 5%);
    }

    &:focus {
      border: 1px solid darken($light-grey, 5%);
      outline: none;
    }
  }
}

