.listing-section {
  position : relative;
}

.listing-section__tools a {
  margin-left: 1em;
}

@include media-query(lap-and-up) {
  .listing-section__tools,
  .listing-section__view-all {
    position : absolute;
    top      : $half-spacing-unit;
    right    : 0;
  }
}

.resource--group .profile__cases .listing-section__tools,
.resource--group .profile__cases .listing-section__view-all {
  position : absolute;
  top      : $half-spacing-unit;
  margin-top: 0;
  right    : 0;
}

@include media-query(palm) {
  .listing-section {
    margin-bottom : $base-spacing-unit;

    .load-more {
      padding : 0 $base-spacing-unit;
    }

    .cards {
      padding : 0 $base-spacing-unit;
    }
  }

  .listing-section__tools,
  .listing-section__view-all {
    display : block;
    margin  : -$base-spacing-unit $base-spacing-unit $base-spacing-unit;
  }

  .listing-section.groups-updates {
    .cards {
      padding : 0;
    }

    .listing-section__tools,
    .listing-section__view-all {
      margin-left : 0;
    }
  }
}

.listing {
  background : $content-background-color;
  color      : $mid-text-color;
  padding    : $base-spacing-unit;
  box-shadow : 1px 1px 0 rgba(0, 0, 0, 0.1);
}

.listing__item {
  padding-bottom : $base-spacing-unit;
  margin-bottom  : $base-spacing-unit;
  border-bottom  : 1px solid rgba(0, 0, 0, 0.1);

  &.likes {
    &:last-child {
      margin-bottom : 0;
    }
  }

  &.groupCard {
    &:last-child {
      margin-bottom  : 0;
      padding-bottom : 13px;
    }
  }
}

.layout--flush {
  .layout__item.u-1-of-1 {
    padding-left : 0;
  }
}

@include media-query(desk-and-up) {
  .layout--flush {
    .layout__item.u-1-of-2-desk:nth-child(even) {
      padding-left : 50px;
    }
  }
}
