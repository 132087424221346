@use "sass:math";
/* Settings */
@import 'settings.type';
@import 'settings.palette';
@import 'settings.template';
@import 'settings.inuit';
@import './inuit/inuit-defaults/settings.defaults';
$inuit-base-spacing-unit: 20px;
$inuit-base-spacing-unit--small: round(math.div($inuit-base-spacing-unit, 2));
$inuit-base-spacing-unit--large: round($inuit-base-spacing-unit * 2);
@import 'settings.responsive';
@import 'settings.forms';
