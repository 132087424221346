.cms-page {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  font-size: 16px;

  h1 {
    font-weight: 300;
  }
}

.cms-page .page-updated-at {
  color: #727272;
  font-weight: 500;
  font-size: 14px;

  .icon-clock {
    margin-right: 0.25em;
  }
}

.cms-document-block {
  display: block;
  margin-bottom: $base-spacing-unit;
  width: 100%;
}

.cms-document-block.centered {
  margin-left: auto;
  margin-right: auto;
}

.cms-document-block--float-left {
  float: left;
  margin-right: $base-spacing-unit;
}

.cms-document-block--float-right {
  float: right;
  margin-left: $base-spacing-unit;
}

.cms-document-block_img--fill-x {
  width: 100%;
}

.cms-document-block__img--fill-x-crop-y {
  width: 100%;
  max-height: 300px;
  object-fit: cover;
}

.cms-youtube-block {
  display: block;
  margin: 0 auto 1em;
  max-width: 100%;
}

.cms-iframe-block {
  display: block;
  margin: 0 auto 2em;
  max-width: 100%;
}

.cms-open-graph-block {
  box-shadow: 0 1px 4px 2px rgba(0, 0, 0, 0.1);
  display: block;
  margin-bottom: $base-spacing-unit;
}

.cms-open-graph-block--small {
  display: flex;

  img {
    width: 300px;
  }
}

.cms-open-graph-block__title {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 0.5em;
}

.cms-document-block__caption,
.cms-open-graph-block__about {
  background-color: #fff;
  padding: $half-spacing-unit;
}

.cms-page .image-gallery {
  margin-bottom: 2em;
}

@include media-query(lap-and-up) {
  .cms-columns {
    display: flex;
  }

  .cms-column {
    width: 50%;
    margin-left: 2em;
  }

  .cms-column--0 {
    margin-left: 0;
  }
}

.rt-style-underline {
  text-decoration: underline;
  font-weight: inherit;
}

.rt-style-underline-body-size {
  text-decoration: underline;
  font-size: 100% !important;
  margin-bottom: 0;
}
h1.rt-style-underline-body-size,
h2.rt-style-underline-body-size,
h3.rt-style-underline-body-size,
h4.rt-style-underline-body-size {
  font-weight: bold;
}

a.rt-style-external:after {
  content: ' ';
  display: inline-block;
  margin-left: 0.25em;
  width: 14px;
  height: 14px;
  background: transparent
    url(/assets/20230901-1693555955/app/styles/images/external-link.svg) 50% 50%
    no-repeat;
  background-size: 100%;
}
