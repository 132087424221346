@use 'sass:math';
@import '../../../style/variables';
@import '../../../style/tools';

$global-radius: 4px;

$modal-small: 340px;
$modal-medium: 600px;
$modal-large: 852px;

$modal-push-top: 67px;

// Animation
@keyframes move-down {
  from {
    top: -80px;
  }
  to {
    top: 0;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
    visibility: hidden;
  }
  to {
    opacity: 1;
    visibility: visible;
  }
}

.modalOverlay {
  background-color: rgba(49, 69, 89, 0.95);
  position: fixed;
  display: flex;
  justify-content: space-around;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  z-index: 250;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;

  &.small {
    @include media-query(palm) {
      justify-content: flex-start !important;
      align-items: flex-start !important;
    }
  }
}

.modalContent {
  position: relative;
  max-height: 95%;
  vertical-align: middle;
  border-radius: 0;
  outline-color: darken($base-background-color, 30%);
  outline-width: 0;
  overflow-x: hidden;
  overflow-y: scroll;
  -ms-overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  align-self: center;

  // /////////////////////////////////////////////////////////// Hide scroll bar
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
    outline: 0;
  }
  &::-webkit-scrollbar {
    display: none;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
  }
  // Hide scroll bar END

  &.moveDown {
    animation: fade-in 0.24s ease 0ms,
      move-down 0.5s cubic-bezier(0.51, -0.1, 0.09, 1.44) 0ms;
  }

  &:before {
    top: 0;
  }
  &:after {
    bottom: 0;
  }

  // ////////////////////////////////////////////////////////////// COLOR THEMES
  &.light {
    background: #fff;
    box-shadow: 0 0 0 0 transparent;
    color: $dark-background-color;
  }

  &.dark {
    box-shadow: 0 0 0 0 transparent;
    background: $dark-background-color;
    color: #fff;
  }
  // COLOR THEMES END

  // ///////////////////////////////////////////////////////////////////// SIZES
  &.sampleImg {
    max-width: 100%;
    min-height: 150px;
    height: auto;
  }

  &.information {
    max-width: 400px;
    min-width: 400px;
    min-height: 500px;
  }

  &.small {
    max-width: 600px;
    min-width: $modal-small;
    min-height: math.div($modal-small, 3);
    background-color: $content-background-color;

    &:hover,
    &:focus {
      box-shadow: 0 0 0 0 transparent;
      outline: 0;
    }

    @include media-query(palm) {
      padding-top: 42px;
      vertical-align: top;
      min-width: 100%;
      width: 100%;
      height: 100%;
      max-height: none;
    }
  }

  &.medium {
    min-width: $modal-medium;
    min-height: math.div($modal-medium, 2.8);

    @include media-query(palm) {
      min-height: 100%;
      min-width: 100%;
    }
  }

  &.large {
    width: $modal-large;
    min-height: math.div($modal-large, 2.8);

    @include media-query(palm) {
      min-height: 100%;
    }
  }

  &.fullscreen {
    width: calc(100% - 30px);
    height: calc(100% - 100px);
    max-height: 550px;

    @include media-query(palm-iphone6s) {
      width: calc(100% - 100px);
      height: calc(100% - 30px);
      max-height: none;
    }
  }
  // SIZES END
}

.closeBlock {
  position: fixed;
  top: 0;
  left: 50%;
  margin-left: -475px !important;

  @include media-query(lap) {
    margin-left: -350px !important;
  }

  @include media-query(palm) {
    margin-left: auto !important;
    left: 0;
  }
}

.closeBtn {
  position: absolute;
  right: -7px;
  top: 12px;
  z-index: inherit;
  display: block;
  padding: 10px;

  @include media-query(palm) {
    right: 10px;
  }

  button {
    width: 50px;
    height: 60px;
    background-color: transparent;
    outline: 0;
    border: 0;
  }
}

.closeShape {
  position: relative;
  display: block;
  left: 23px;

  &:after,
  &:before {
    content: '';
    background: #fff;
    height: 2px;
    left: -15px;
    border-radius: 22px;
    position: absolute;
    top: -7px;
    width: 27px;
    transform: rotate(45deg);
  }

  &:before {
    transform: rotate(-45deg);
  }
}
