@import '../../../style/variables';
@import '../../../style/tools';

// Error Text
.textError {
  display: inline-block;
  text-align: right;
  padding: 0;
  font-size: 14px;
  font-weight: 300;
  margin: 0 0 10px;
}

.flexContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 60px;

  // SMALL HACK
  > span > label {
    padding: 12px 0 12px 0 !important;
    margin: 0;
  }
}

.label {
  color: $light-link-color;
}

.extraLabel {
  color: $inuit-base-text-color;
  display: block;
  margin-top: 5px;
}

.hasError {
  color: $danger-color;
}

.container {
  position: relative;
}

.counterCont {
  position: absolute;
  right: -3px;
  top: 47px;
}

.counter {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 10;
}

.counterText {
  margin-right: 3px;
  font-size: 0.9em;

  &.dark {
    color: #bbc2c9;
  }

  &.light {
    color: #bbc2c9;
  }
}
