.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__container--open .react-autosuggest__input {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  position: absolute;
  top: 40px;
  width: 100%;
  border: 0;
  background-color: #fff;
  font-family: $form-font-family;
  font-size: $form-font-size;
  border-bottom-left-radius: $form-border-radius;
  border-bottom-right-radius: $form-border-radius;
  box-shadow: $form-box-shadow-focus;
  z-index: 30;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  border: 1px solid #aaa;
  max-height: 10em;
  overflow-y: auto;
  z-index: 30;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: $half-spacing-unit;
}

.react-autosuggest__suggestion--highlighted {
  background-color: $off-white;
  color: $brand-color;
}
.react-autosuggest__suggestion--focused {
  background-color: $off-white;
  color: $brand-color;
}
