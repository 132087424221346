@use "sass:math";
///*------------------------------------*\
//    #MIXINS
//\*------------------------------------*/

// inuitcss has a number of default mixins that the framework, and developers
// working with it, can make use of.

// Create a fully formed type style (sizing and vertical rhythm) by passing in a
// single value, e.g.:
//
// .foo {
//     @include inuit-font-size(12px);
// }
//
// This will generate a rem-based font-size with its pixel fallback, as well as
// a unitless line-height which will place the element on your baseline, e.g.:
//
// .foo {
//     font-size: 12px;
//     font-size: 0.75rem;
//     line-height: 2;
// }
//
// If you do not want inuitcss to generate you a line-height automatically, you
// simply pass in your own as a second paramater:
//
// .foo {
//     @include inuit-font-size(12px, 1.5);
// }
//
// This will yield:
//
// .foo {
//     font-size: 12px;
//     font-size: 0.75rem;
//     line-height: 1.5;
// }
//
// This parameter can be any integer, ‘inherit’, or ‘normal’. If you don’t want
// a line-height at all, pass in a second paramater of ‘none’ or ‘false’:
//
// .foo {
//     @include inuit-font-size(12px, none);
// }
//
// This will yield:
//
// .foo {
//     font-size: 12px;
//     font-size: 0.75rem;
// }

@mixin inuit-font-size($inuit-font-size, $inuit-line-height: auto) {
  font-size: $inuit-font-size;
  font-size: math.div($inuit-font-size, $inuit-base-font-size) * 1rem;

  @if $inuit-line-height == auto {
    line-height: ceil(math.div($inuit-font-size, $inuit-base-line-height)) *
      math.div($inuit-base-line-height, $inuit-font-size);
  } @else {
    @if (
      type-of($inuit-line-height) ==
        number or
        $inuit-line-height ==
        inherit or
        $inuit-line-height ==
        normal
    ) {
      line-height: $inuit-line-height;
    } @else if ($inuit-line-height != none and $inuit-line-height != false) {
      @warn "D’oh! ‘#{$inuit-line-height}’ is not a valid value for `line-height`.";
    }
  }
}
