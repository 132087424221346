.profile-basic__job-title,
.profile-basic__institution {
  font-weight: 400;
  @include inuit-font-size($small-font-size);
  text-overflow: ellipsis;
  overflow: hidden;
}

.profile-basic__job-title {
  @include line-clamp(2, $small-font-size, $small-font-size + 4);
}

.card .profile-basic__job-title {
  @include line-clamp(1, $small-font-size, $small-font-size + 4);
}

.profile-basic__institution {
  white-space: nowrap;
}

.profile-basic__job-title {
  color: #272727;
}

.profile-basic__institution {
  color: $mid-text-color;
}

.profile-basic.media--large {
  .media__img {
    width: 85px;
  }
}

@include media-query(palm) {
  .profile-basic.media--large {
    .media__img {
      width: 60px;
      img {
        max-width: 100%;
      }
    }
  }
}

.profile-basic img {
  border-radius: 500px;
  overflow: hidden;
}

.profile-basic .media__img {
  opacity: 1;
  transition: opacity 0.13s ease-in-out;
}

.profile-basic .media__img--loading {
  opacity: 0;
  transition: none;
}

.profile-basic .profile__img--group img,
.profile-basic img.profile__img--group {
  border-radius: 0;
}

.profile-basic {
  position: relative;

  .media__body {
    position: absolute;
    @include center-vertically();
    left: 60px + $inuit-media-gutter;
    right: $half-spacing-unit;
    overflow: visible;
  }
}

.profile-basic.media--small {
  .media__body {
    left: 10px + 30px + $inuit-media-gutter;
  }
}

.listing .profile-basic .media__body {
  right: 0;
}

.profile-basic__connect-label {
  color: $light-link-color;
}

.profile-basic__remove {
  position: absolute;
  right: 0;
  bottom: 1px;
  span {
    color: #ef4b4b;
  }
  &:hover span {
    color: #f00 !important;
  }
}

.group-member-status-admin {
  font-size: 90%;
  color: #999;
  margin-right: 28px;
}

.profile-basic__name {
  display: flex;
  align-items: center;

  @include line-clamp(2, $base-font-size, $base-font-size + 4);

  .profile-basic__name-text {
    display: block;
    flex: 0 2 auto;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.profile-badges {
  display: inline-flex;
  align-items: center;
  vertical-align: bottom;
}

.profile-badges > * {
  background-color: transparent;
  margin-left: 5px;
}

.profile-basic {
  .icon-editor-badge {
    position: relative;
    top: 2px;
  }

  .icon-award-badge {
    position: relative;
    top: 3px;
  }

  .icon-editor-badge,
  .icon-award-badge {
    display: inline-block;
    margin-left: 5px;
  }

  .icon-editor-badge__bg {
    stroke: #1b6ebf;
    stroke-width: 1.5;
  }

  .icon-editor-badge__fg {
    fill: #1b6ebf;
  }
}

.profile-basic__info {
  clear: left;
}

.listing {
  .profile-basic__name {
    font-size: $large-font-size;
    @include line-clamp(2, $large-font-size, $large-font-size + 2);
  }

  .profile-basic__job-title {
    margin-bottom: $job-title-margin-bottom;
  }
}
