
.notification {
  position   : fixed;
  top        : -$page__header-height;
  right      : 0;
  left       : 0;
  height     : $page__header-height;
  z-index    : $notification-z-index;
  transition : top .26s ease-in-out;

  .page__container {
    position : relative;
    height   : $page__header-height;
  }
}

.notification__message {
  font-size : $large-font-size;
  margin    : 0;
  position  : absolute;
  right     : 2 * $base-spacing-unit;
  left      : $base-spacing-unit;
  @include center-vertically;
}

.notification__close {
  position   : absolute;
  padding    : 0;
  right      : $base-spacing-unit;
  background : transparent none;
  border     : 0;
  @include center-vertically;
}

@include media-query(lap-and-up) {

  .notification__message {
    right : 0;
    left  : 0;
  }

  .notification__close {
    right : 0;
  }

}

.notification--info {
  background-color : $brand-color;
  color            : #fff;
}

.notification--error {
  background-color : $danger-color;
  color            : #fff;
}

