@use "sass:math";
@import '../../../style/variables';
@import '../../../style/tools';

// variables
$global-padding: 1rem;

// HEADER
$header-bg: $dark-background-color;
$header-height: 52px;
$header-color: #fff;

// BODY
$body-bg: $content-background-color;
$body-padding: 1rem;

// FOOTER
$footer-padding: 1rem;

// H1 header
$header-1-space: 30px;
//____________________________________________

.medshrModal {
  display: block;
}

// Header block
.header {
  min-height: $header-height;
  display: block;
  background-color: $header-bg;
  color: $header-color;
  padding: $global-padding 100px 0 $global-padding;
  position: relative;

  @include media-query(palm) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    margin-bottom: 0.5rem;
  }
}

.title {
  color: inherit;
  margin: 0 0 $global-padding;
  padding: 0;
  font-weight: 200;
  line-height: 1.1;

  @include media-query(palm) {
    font-size: 1.15rem;
    margin: 0;
    padding: $global-padding;
  }
}

.sideKickBtn {
  position: absolute;
  right: 1rem;
  top: 10px;

  @include media-query(palm) {
    position: relative;
    right: 0;
    top: 0;
    padding: $global-padding math.div($global-padding, 1.2) $global-padding 0;
  }
}

// Body block
.body {
  padding: math.div($body-padding, 3) $body-padding 0;
  background-color: $body-bg;
}

// Footer block
.footer {
  padding: 0 3px 0 $footer-padding;
  a {
    text-decoration: underline;
  }
  p {
    margin-bottom: $global-padding;
    color: $low-text-color;
  }
}

// Contents
.message {
  display: block;
  padding: 0;
  font-weight: 400;
  margin-bottom: $header-1-space;
  line-height: 1.3;

  @include media-query(palm) {
    font-size: 1.25rem;
    margin-bottom: math.div($header-1-space, 2);
  }
}

.warning {
  color: $danger-color;
}

.email {
  color: $link-color;
}

.link {
  color: $light-link-color;

  &:hover,
  &:focus {
    color: $brand-color;
  }
}
