@use 'sass:math';
/*------------------------------------*\
    #PAGE
\*------------------------------------*/

/**
 * High-, page-level styling.
 *
 * 1. Set the default `font-size` and `line-height` for the entire project,
 *    sourced from our default variables. The `font-size` is calculated to exist
 *    in ems, the `line-height` is calculated to exist unitlessly.
 * 2. Force scrollbars to always be visible to prevent awkward ‘jumps’ when
 *    navigating between pages that do/do not have enough content to produce
 *    scrollbars naturally.
 * 3. Ensure the page always fills at least the entire height of the viewport.
 * 4. Prevent certain mobile browsers from automatically zooming fonts.
 */
html {
  font-size: math.div($inuit-base-font-size, 16px) * 1em; /* [1] */
  line-height: math.div(
    $inuit-base-line-height,
    $inuit-base-font-size
  ); /* [1] */
  color: $inuit-base-text-color;
  //overflow-y: scroll; /* [2] */
  overflow: hidden;
  min-height: 100%; /* [3] */
  -webkit-text-size-adjust: 100%; /* [4] */
  -ms-text-size-adjust: 100%; /* [4] */
  -moz-osx-font-smoothing: grayscale; /* [5] */
  -webkit-font-smoothing: subpixel-antialiased; /* [5] */
  font-weight: 300;
  height: 100%;
  -webkit-overflow-scrolling: touch;
}

body {
  height: 100%;
  position: relative;
  background-color: $base-background-color;
  -webkit-overflow-scrolling: touch;
}

.dark-background-body,
.dark-background-body .page__body {
  background-color: $dark-background-color;
}

.native-embed {
  background-color: $dark-background-color;

  .fullH {
    min-height: 100vh;
  }
}
