$form-field-border-color: $off-white;
$form-border-color: #fff;
$form-border-color-hover: #fff;
$form-border-color-focus: #fff;
$form-border-color-error: #f97171;
$form-border-radius: 1px;
$form-box-shadow: 1px 1px 0 rgba(0, 0, 0, 0.1);
$form-box-shadow-focus: 1px 1px 0 rgba(0, 0, 0, 0.2);
$form-font-size: $base-font-size;
$form-font-family: $brand-face;
