// loading-spinner css style
.video-react .video-react-loading-spinner {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -25px;
  opacity: 0.85;

  // Need to fix centered page layouts
  text-align: left;

  border: 6px solid rgba($video-react-primary-background-color, $video-react-primary-background-transparency);
  // border: 6px solid rgba(43, 51, 63, 0.5);

  box-sizing: border-box;
  background-clip: padding-box;
  width: 50px;
  height: 50px;
  border-radius: 25px;

  &:before,
  &:after {
    content: "";
    position: absolute;
    margin: -6px;
    box-sizing: inherit;
    width: inherit;
    height: inherit;
    border-radius: inherit;
    opacity: 1;
    border: inherit;
    border-color: transparent;
    border-top-color: white;
    -webkit-animation: video-react-spinner-spin 1.1s cubic-bezier(0.6, 0.2, 0, 0.8) infinite, video-react-spinner-fade 1.1s linear infinite;
    animation: video-react-spinner-spin 1.1s cubic-bezier(0.6, 0.2, 0, 0.8) infinite, video-react-spinner-fade 1.1s linear infinite;
  }
}

.video-react-seeking .video-react-loading-spinner,
.video-react-waiting .video-react-loading-spinner {
  display: block;
}

.video-react-seeking .video-react-loading-spinner:before,
.video-react-waiting .video-react-loading-spinner:before {
  border-top-color: rgb(255,255,255);
}

.video-react-seeking .video-react-loading-spinner:after,
.video-react-waiting .video-react-loading-spinner:after {
  border-top-color: rgb(255,255,255);
  -webkit-animation-delay: 0.44s;
  animation-delay: 0.44s;
}

@keyframes :global(video-react-spinner-spin) {
  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes :global(video-react-spinner-spin) {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes :global(video-react-spinner-fade) {
  0% {
    border-top-color: $video-react-secondary-background-color;
  }
  20% {
    border-top-color: $video-react-secondary-background-color;
  }
  35% {
    border-top-color: white;
  }
  60% {
    border-top-color: $video-react-secondary-background-color;
  }
  100% {
    border-top-color: $video-react-secondary-background-color;
  }
}

@-webkit-keyframes :global(video-react-spinner-fade) {
  0% {
    border-top-color: $video-react-secondary-background-color;
  }
  20% {
    border-top-color: $video-react-secondary-background-color;
  }
  35% {
    border-top-color: white;
  }
  60% {
    border-top-color: $video-react-secondary-background-color;
  }
  100% {
    border-top-color: $video-react-secondary-background-color;
  }
}


