.resource__text-only-content {
  max-width: 100%;

  .resource-stats {
    float: left;
  }
}

.profile__founders .module__body {
  padding-top: 0;
}

.profile__about {
  font-size: $large-font-size;
  padding-bottom: $half-spacing-unit;
  border-bottom: 1px dashed rgba(0, 0, 0, 0.1);
  font-weight: 400;
}

.profile__description {
  p:last-child {
    margin-bottom: 5px;
  }

  margin-bottom: $base-spacing-unit;
}

.resource__header-consent-status {
  .btn {
    margin-right: 1em;
  }
}

.resource__body {
  padding-bottom: 30px;
}

@include media-query(palm) {
  .resource__header {
    .page__container {
      width: 100%;
    }
  }

  .resource__header-consent-status__text {
    display: block;
    padding-top: $half-spacing-unit;
  }

  .resource__body {
    margin: 0;
    width: 100%;

    .listing-section h1,
    .listing-section h2 {
      padding-left: $base-spacing-unit;
      padding-right: $base-spacing-unit;
    }
    .listing-section h2.card__title {
      padding-left: 0;
      padding-right: 0;
    }

    .activity-indicator {
      margin-left: $base-spacing-unit;
    }
  }
}

.resource {
  .module__header > h3 {
    font-weight: 300;
    font-size: $inuit-heading-size-5;
  }

  section > header > h2,
  section > h2 {
    font-weight: 100;
    font-size: $inuit-heading-size-1;
  }

  section > h2.intro__title {
    font-size: $large-font-size;
  }

  .action-link {
    font-weight: 200;
  }
}

@include media-query(palm) {
  .resource {
    section > header > h2,
    section > h2 {
      font-weight: 200;
    }
  }
}

.resource__blocked {
  color: $mid-text-color;
  h1 {
    margin-bottom: 2px;
  }
  p {
    margin-bottom: $base-spacing-unit;
  }
}

.owner-tools,
.admin-tools {
  text-align: right;
  font-size: $small-font-size;
}

.poll {
  margin-bottom: $base-spacing-unit;
}

.case__description {
  color: #000;
  margin-bottom: $base-spacing-unit;
  @include media-query(palm) {
    margin-bottom: 2 * $base-spacing-unit;
  }
}

.case__description,
.comment {
  p {
    margin-bottom: 13px;
  }

  li p {
    margin: 0;
  }

  hr {
    color: #999;
  }

  blockquote {
    padding: 15px 2.5em;
    margin-bottom: 16px;
    position: relative;
    &:before {
      content: '“';
      top: -0.25em;
      left: -10px;
    }
    &:after {
      content: '”';
      bottom: -0.4em;
      right: -10px;
    }
    &:before,
    &:after {
      font-size: 100px;
      color: rgba(150, 150, 150, 0.4);
      position: absolute;
      font-family: Georgia, serif;
    }
  }

  blockquote footer {
    text-align: right;
    margin-top: 10px;
    font-size: 14px;
  }
}
