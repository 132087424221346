@use 'sass:math';
$card-background-color: #fff;
$card-width: 220px;
$card-width-in-learning: 180px;
$card-h-spacing-unit: 50px;
$card-v-spacing-unit: math.div($card-h-spacing-unit, 2);
$card-title-z-index: 9;

$mobile-card-width: 300px;

.cards-container {
  margin-bottom: $twice-spacing-unit;
}

.cards {
  @extend .clearfix;
}

.card {
  background-color: $card-background-color;
  margin-bottom: $card-v-spacing-unit;
  margin-right: $card-h-spacing-unit;
  width: $card-width;
  float: left;
  box-shadow: 1px 1px 0 rgba(0, 0, 0, 0.1);
}

.card__profile {
  padding: 7px 10px;

  .media__img {
    margin-top: 2px;
  }

  .media__title {
    display: flex;
    width: 100%;

    &:hover {
      text-decoration: none;
    }
  }

  .profile-basic__job-title {
    color: $mid-text-color;
  }
}

.card__resource {
  position: relative;
}

.card__title {
  @include inuit-font-size($inuit-heading-size-5, 1.2);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: $card-title-z-index;
  height: 100%;
  overflow: hidden;

  .card__title-background {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 100%;
    overflow: hidden;
  }

  .card__text--link-text {
    color: #fff;
    display: block;
    padding: $half-spacing-unit;
    height: 100%;
    position: relative;
    background-image: linear-gradient(
      rgba(0, 0, 0, 0.8),
      transparent,
      transparent
    );

    span {
      max-height: 100%;
      @include line-clamp(5, $large-font-size, $inuit-base-line-height * 1.25);
      color: #fff;
      text-overflow: ellipsis;
    }
  }

  &.ads {
    @media screen and (max-width: 340px) and (orientation: portrait) {
      width: 100% !important;
      //height : 44px !important;
    }
  }
}

.card__title--hidden {
  a {
    background: none;
    span {
      visibility: hidden;
    }
  }
}

.card__description {
  font-size: $small-font-size;
  margin: 5px 0 0;
  @include line-clamp(4, $small-font-size, 1.2 * $small-font-size);
}

.card__text-link:hover {
  text-decoration: none;
}

.card__resource,
.card__image {
  width: $card-width;
  height: $card-width;
  background-color: $dark-background-color;
}

.card__type-icon {
  position: absolute;
  bottom: 10px;
  left: 10px;
  z-index: 10;
}

@include media-query(palm-portrait) {
  .card {
    width: 100%;
    margin-right: 0;
  }

  .card__resource {
    width: 100%;
    height: $card-width;
    overflow: hidden;
  }

  .card__image {
    width: 100%;
    height: $mobile-card-width;
  }
}

@include media-query(palm-landscape) {
  .u-1-of-2-palm-landscape,
  .u-1-of-1 {
    .card {
      width: 100%;
    }

    .card__resource,
    .card__image {
      width: 100%;
    }
  }
}

@include media-query(lap) {
  .u-1-of-1 {
    .card {
      width: $mobile-card-width;
      margin-right: 0;
    }

    .card__resource {
      width: $mobile-card-width;
      height: $card-width;
      overflow: hidden;
    }

    .card__image {
      width: $mobile-card-width;
      height: $mobile-card-width;
      margin-top: math.div(($mobile-card-width - $card-width), 2);
    }
  }
}

.card .badge {
  right: $half-spacing-unit;
  bottom: $half-spacing-unit;
  z-index: ($card-title-z-index + 1);
  @include inuit-font-size($small-font-size);
  height: auto;
  pointer-events: none;
}

.card__row {
  margin: -5px 10px;
}

.card__profile {
  height: 48px; // same height even if some child elements are empty
}

.card__footer {
  padding: $half-spacing-unit;
  height: 125px; // same height even if some child elements are empty
  position: relative;

  .btn--wide {
    padding-left: 0;
    padding-right: 0;
    width: 100%;
  }
}

.card--case {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.card--case .card__footer {
  height: 141px; // same height even if some child elements are empty
}

.card--without-actions .card__footer {
  height: 95px;
}

.card--without-followers .card__footer {
  height: 100px;
}

.card--my-case .card__footer {
  height: 50px;
}

.card--with-stats .card__footer {
  height: 75px;
}

.card__links,
.card__reason,
.card__stats {
  color: $mid-text-color;
  @include inuit-font-size($small-font-size);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card__reason {
  font-weight: 400;
  word-break: break-word;
}

.card__reason .editor-paragraph {
  font-weight: 400;
  margin-bottom: 0;
  word-break: break-word;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card__stats {
  margin-bottom: 7px;
  font-weight: 200;
}

.card__stats .profile-list {
  float: left;
}

.activity-panel__footer .card__stats {
  margin-bottom: 0;
}

.card__stats__numbers {
  padding-top: 6px;
  display: block;
  float: left;
}

.card__action {
  margin-top: $half-spacing-unit;
  padding-top: $half-spacing-unit;
  border-top: 1px solid $off-white;
  text-align: center;
  position: absolute;
  left: 20px;
  bottom: $half-spacing-unit;
  right: 20px;
}

.card__visibility {
  padding: $half-spacing-unit;

  .action-link {
    font-size: $small-font-size;
  }
}

.card__visibility--consent-needed {
  color: $danger-color;
}

.card__visibility-label {
  color: $mid-text-color;
}

.card__visibility--consent-needed {
  .card__visibility-label {
    color: $danger-color;
    font-weight: 400;
  }
}

.card__consent-countdown {
  border: 0;
  background-color: $danger-color;
  color: #fff;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
}

.u-1-of-1 > .media,
.u-1-of-1 > .card-row > .media {
  position: relative;
  min-height: 60px;
}

.u-1-of-1 > .media > .media__body,
.u-1-of-1 > .card-row > .media > .media__body {
  position: absolute;
  left: 60px + 12px;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  min-height: 32px;
  overflow: visible;
}

@include media-query(palm) {
  .u-1-of-1 > .media > .media__body,
  .u-1-of-1 > .card-row > .media > .media__body {
    left: 60px + $half-spacing-unit;
  }
}

.u-1-of-1 > .listing__item > .media__body {
  padding-bottom: $base-spacing-unit;
}

.card-row {
  .btn--connect {
    margin-top: 3px;
  }
  .media {
    border-bottom: 1px solid #f2f2f2;
    padding: $base-spacing-unit 0;
  }

  .media__title {
    display: block;
    float: none;
    font-size: $large-font-size;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 8em;
    word-break: break-all;
    white-space: nowrap;
  }

  .media__title > a,
  .fake-link.media__title,
  a.media__title {
    text-align: left;
  }

  .media__subtitle,
  .media__info {
    font-size: $small-font-size;
    margin: 0;
    font-weight: 400;
    color: $mid-text-color;
  }

  .profile-basic__job-title,
  .media__subtitle {
    margin-bottom: $job-title-margin-bottom;
  }
}

.card-row__title {
  @include line-clamp(1, $large-font-size, $large-font-size * 1.3);
}

.card-row--category {
  .category-link {
    stroke: $light-link-color;
    stroke-width: 2px;
    padding-top: $base-spacing-unit;
    padding-bottom: $base-spacing-unit;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  .category-link__icon {
    display: block;
    float: left;
    margin-right: $base-spacing-unit;
  }

  .category-link__a {
    color: $light-link-color;
    display: block;
  }
}

.card-row--user {
  a:hover {
    text-decoration: none;
  }
}

.card-manage.card-row,
.module__body .card-row {
  padding: 0 0;
}

.card-row__description p {
  margin-bottom: 0.5rem;
}

.card-row__stats {
  color: $mid-text-color;
  font-size: $small-font-size;
  font-weight: 200;
}

.card-manage .card-row__stats {
  font-weight: 300;
}

.card-row__stats-update-count {
  color: $urgent-button-background-color;
  font-weight: 400;
}

.card-manage__description {
  color: $mid-text-color;
  font-weight: 400;
  @include line-clamp(1, $small-font-size, $small-font-size * 1.3);
}

.card-manage__footer {
  padding: $half-spacing-unit 0 0;
  clear: left;
}

.card-manage__action {
  float: right;
}

.search__body,
.member-search__body {
  .load-more {
    padding: $base-spacing-unit;
  }
}

.modal .card-row {
  padding: 0 $base-spacing-unit;
}

.simple-results__row .group-member-permissions,
.card-row .group-member-permissions {
  width: 40%;
  position: absolute;
  top: 10px;
  right: 50px;
  text-align: left;
  z-index: 10;
}

.simple-results__row .group-member-permissions {
  top: 6px;
  right: 60px;
  font-size: 90%;
}

@include media-query(palm) {
  .modal .card-row {
    padding: 0 $base-spacing-unit;
  }

  .card-row {
    .media__title {
      padding-right: 5em;
    }
  }

  .simple-results__row .group-member-permissions,
  .card-row .group-member-permissions {
    width: auto;
    position: static;
    margin-top: 5px;
    margin-bottom: 1em;
    border-bottom: 1px solid $off-white;
  }
}

.card-row--admin {
  .media__title {
    margin-right: 11em;
    padding-right: 0;
  }

  .feature-to {
    position: absolute;
    right: 0;
    top: 32px;
    color: $mid-text-color;
    label {
      text-align: right;
      font-size: 90%;
      display: block;
    }
    input {
      font-size: 90%;
      padding: 2px 5px;
      border: 1px solid $light-grey3;
      border-radius: 2px;
    }
    .react-autosuggest__suggestions-container {
      top: 22px;
    }
    .react-autosuggest__suggestions-list {
      margin: 0;
      list-style-type: none;
      font-size: 90%;
    }
    .react-autosuggest__suggestion {
      padding: 5px;
      &:hover {
        background-color: $brand-color;
        color: #fff;
      }
    }
  }
}

.pageNum_feed-with-contents .layout__item .card {
  width: 100%;

  .card__resource {
    width: 100%;
  }
}

.card__rating_and_sub {
  display: flex;
  margin-right: 0;
  padding-right: 0;
}

.card-transition {
  position: relative;
  z-index: 100;
}

.card-transition-enter {
  opacity: 0;
}
.card-transition-enter-active {
  opacity: 1;
  transition: opacity 50ms;
}
.card-transition-exit {
  opacity: 1;
}
.card-transition-exit-active {
  opacity: 0;
  transition: opacity 50ms;
}

.price-label-container {
  position: absolute;
  z-index: 100;
  bottom: 0.5rem;
  right: 0;
  min-width: 47px;
  height: 29px;
  background-color: gold;
  border: 2px solid white;
  border-right: 0;
  border-bottom-left-radius: 15px;
  border-top-left-radius: 15px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  box-sizing: border-box;
  white-space: nowrap;
}

.price-label-text {
  color: black;
  font-size: 15px;
  font-weight: 600;
  font-family: 'Inter', sans-serif;
}
