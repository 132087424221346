
@include media-query(palm) {
  .not-palm {
    display: none;
  }
}

@include media-query(lap-and-up) {
  .not-lap-and-up {
    display: none;
  }
}
