@use 'sass:math';
$mediumSpace: 11px;
$largeSpace: $base-spacing-unit;

.user-menu {
  float: right;
}

.user-menu__link,
.user-menu__item {
  margin-right: $largeSpace;

  @media (min-width: 784px) and (max-width: 1024px) {
    margin-right: $mediumSpace;
  }
}

.user-menu li:first-child .user-menu__link--text {
  border-left: 0;
}

.user-menu__link--text {
  padding-left: $largeSpace;
  border-left: 1px solid $light-link-color;
  color: $light-link-color;
  display: inline-block;

  @media (min-width: 784px) and (max-width: 1024px) {
    padding-left: $mediumSpace;
  }
}

.user-menu__item--icon {
  position: relative;
  display: block;
  width: 24px;
  height: 20px;

  svg {
    position: absolute;
    top: 5px;
    cursor: pointer;
  }

  &:last-child {
    margin-right: 0;
  }
}

.user-menu__hover-menu {
  @include block-menu--light();
  position: absolute;
  top: 180%;
  right: 0;
  width: 16rem;
  padding: $half-spacing-unit 0;
  border-radius: 4px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
}

.user-menu__hover-menu:not(:hover):not(.user-menu__hover-menu--show) {
  display: none;
}

$hover-menu-text-inset: $base-spacing-unit;

.user-menu__hover__menu__item--group-starter {
  @include block-menu__item--group-starter(math.div($base-spacing-unit, 2));
}

.user-menu__hover-menu__util-border {
  @include block-menu__util-border(
    $hover-menu-text-inset,
    $hover-menu-text-inset
  );
}

.user-menu__hover-menu__link {
  @include block-menu--light__item();
  padding: math.div($base-spacing-unit, 2) $hover-menu-text-inset;
}

.user-menu__hover-menu__link:hover {
  @include block-menu--light__item--hover();
}
