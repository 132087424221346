@use 'sass:math';
@include media-query(palm) {
  $off-canvas-offset: -75%;

  //body {
  //  overflow-x : hidden;
  //}

  .page__rest {
    // todo test this
    //position : relative;
    z-index: $site-header-z-index - 1;
    transition: transform 0.24s ease-in-out;
  }

  .page--reveal-off-canvas {
    transform: translateX($off-canvas-offset);
    transition: transform 0.24s ease-in-out;
  }

  $off-canvas-menu-text-inset: $base-spacing-unit * 2;

  .off-canvas-navigation {
    @include block-menu();
    position: fixed;
    height: 100%;
    width: 75%;
    right: 0;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: $start-gradient-color;
    background-image: linear-gradient(
      $start-gradient-color,
      $end-gradient-color
    );
    padding: 71px $base-spacing-unit $base-spacing-unit;
    transition: all 0.26s ease-in-out 0ms;

    .off-canvas-navigation__nav-link {
      @include block-menu__item();
      padding-top: math.div($base-spacing-unit, 2);
      padding-bottom: math.div($base-spacing-unit, 2);
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);

      .badge {
        top: 3px;
        right: 105% - $off-canvas-offset;
        min-width: 22px;
        height: 22px;
        padding: 1px 4px;
        border: 1px solid transparent;
        font-size: 14px;
      }
    }
    .off-canvas-navigation__nav-link--active-route,
    .off-canvas-navigation__nav-link:hover {
    }
  }

  .with-main-menu .off-canvas-navigation {
    animation: open-left 0.26s ease-in-out normal;
    animation-fill-mode: forwards;
  }

  .off-canvas-navigation-toggle {
    padding: 6px $base-spacing-unit;
  }

  .search-toggle {
    padding: 4px 0 5px $half-spacing-unit;
    stroke: #fff;
  }
}

@-webkit-keyframes :global(open-left) {
  from {
    right: -520px;
  }
  to {
    right: 0;
  }
}

@keyframes :global(open-left) {
  from {
    right: -520px;
  }
  to {
    right: 0;
  }
}
