.activity__title,
.activity-panel .profile-basic__name {
  font-size: $large-font-size;
  @include line-clamp(1, $large-font-size);
  max-width: 100%;
}

.activity__heading {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.media__body {
  a.activity__title {
    @include line-clamp(2, $large-font-size, 19px);
  }
}

.media__body--title-lines-2 {
  .activity__title {
    @include line-clamp(2, $large-font-size);
  }

  .activity__description {
    @include line-clamp(1, $base-font-size);
  }
}

.media__body--title-lines-3 {
  .activity__title {
    @include line-clamp(3, $base-font-size);
  }

  .activity__description {
    display: none;
  }
}

.activity__actor {
  font-size: $small-font-size;
  white-space: nowrap;
}

.activity__description {
  font-size: $small-font-size;
  color: $mid-text-color;
}

.activity--spacious {
  .activity__title {
    white-space: normal;
    @include line-clamp(2, $large-font-size);
  }
}

.activity {
  position: relative;
}

.activity .profile-basic__job-title,
.activity .profile-basic__institution,
.activity .activity__actor,
.activity-panel .profile-basic__job-title,
.activity__reason-prefix {
  margin-bottom: $job-title-margin-bottom;
}

.activity__reason-prefix,
.activity__reason {
  color: $mid-text-color;
  font-weight: 400;
  @include inuit-font-size($small-font-size, 15px);
}

@include media-query(desk-and-up) {
  .activity__reason-prefix,
  .activity__reason {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .activity {
    .btn--iconic {
      position: absolute;
      top: -4px;
      right: 0;
    }
  }

  .activity {
    .media__body {
      overflow: visible;
      > .activity__title,
      > .profile-basic__job-title {
        padding-right: 150px;
        padding-left: 0.65px;
      }
    }
  }

  .activity--group-accepted {
    .activity__reason {
      white-space: normal;
    }
  }

  .activity--group-rejected {
    .media__body {
      padding-right: $base-spacing-unit;
    }
  }
}

@include media-query(portable) {
  .activity__reason-prefix,
  .activity__reason {
    max-height: 2.5em;
    overflow: hidden;
    &:last-child {
      padding-right: 80px;
    }
  }

  .activity {
    .media__body {
      left: 60px + $half-spacing-unit;

      > .activity__title,
      > .profile-basic__job-title {
        padding-right: 75px;
        // Non standard for webkit
        word-break: break-word;
        -ms-word-break: break-word;
        -webkit-hyphens: auto;
        -moz-hyphens: auto;
        hyphens: auto;
      }
    }

    .btn--iconic {
      //position    : absolute;
      //bottom      : $base-spacing-unit;
      //right       : 0;
      line-height: 1.1;
      text-align: right;
      position: absolute;
      top: 1px;
      right: 0;
      .top,
      .down {
        display: block;
      }
    }
  }
}

.activity-panel {
  box-shadow: 1px 1px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.activity-panel.listing__item {
  padding-bottom: 0; // panel footer has padding
}

.activity-panel__header {
  color: #fff;
  background-color: $bright-brand-color;
  padding: 11px $half-spacing-unit;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.activity-panel__title {
  font-size: $large-font-size;
  margin: 0;
  line-height: 1.2;
  @include line-clamp(2, $large-font-size, $large-font-size * 1.2);
  padding-right: 10px;
}

.activity-panel__title--fullwidth {
  padding-right: 0;
}

.activity-panel--promo .activity-panel__title {
  padding-right: 2em;
}

.activity-panel__body {
  color: $mid-text-color;
  padding: 15px $half-spacing-unit 0;

  .activity-panel__body-inner {
    border-bottom: 1px solid $off-white;
    padding-bottom: 15px;
  }

  .activity__description {
    @include line-clamp(2, $small-font-size, $small-font-size * 1.2);
  }
}

.activity-panel__body-section {
  border-bottom: 1px solid $off-white;
  padding: 20px 0;

  .card-row--user .media__title {
    padding-right: 8em;
    white-space: nowrap;
  }
}

.activity-panel__footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: $half-spacing-unit;
  min-height: 30px + (2 * $half-spacing-unit);
}

.activity-panel__header,
.activity-panel__footer {
  opacity: 1;
  transition: all 0.26s ease-in-out;
}

.activity-panel--ignored {
  opacity: 0;
  min-height: 0;
  height: 0;
  padding: 0;
}

.activity-panel--group .card-row__description {
  font-size: $small-font-size;
  margin-top: 5px;
}

.activity-panel--sidebar-resource-image {
  border: 1px solid rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.sidebar-resource-info--link {
  border-top: 1px solid #f2f2f2;
  padding: 10px 15px;
  text-align: right;
  color: #4b9bef;
}
.sidebar-resource-info--link a {
  text-decoration: underline;
}
