
.admin-alert {
  background-color: #09336A;
  color: #fff;
  border-radius: 3px;
  padding: 5px 10px;
  margin-bottom: 20px;

  a {
    color: $light-link-color;
  }
}

.admin-alert--high {
  background-color: red;
  color: white;

  a {
    color: yellow;
  }
}

.admin-alert--low {
  background-color: #e5e5e5;
  color: #555;

  a {
    color: $brand-color;
  }
}

