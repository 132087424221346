@import '../../../style/variables';
@import '../../../style/foundation/mixins';

.flexContainer {
  composes: flexContainer from './invite-colleagues.module.scss';
}

.linkText {
  composes: linkText from './invite-colleagues.module.scss';
}

.btn {
  composes: btn from './invite-colleagues.module.scss';

  > svg {
    margin-top: 6px;
    margin-left: 0 !important;
  }
}

.icon {
  composes: icon from './invite-colleagues.module.scss';
}
