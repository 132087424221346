
.intro__title {
  font-size: $large-font-size;
  padding-bottom: $half-spacing-unit;
  border-bottom: 1px dashed rgba(0, 0, 0, 0.1);
}

.member--editable .intro__title {
  padding-bottom: 0;
  border-bottom: 0;
}

.member__edit {
  position: absolute;
  top: 12px;
  right: $half-spacing-unit;
}

.section-header {
  position: relative;
}

.section-header > h2 {
  font-weight: 100;
  font-size: $inuit-heading-size-1;
}

@include media-query(palm) {
  .section-header > h2 {
    font-weight: 200;
  }
}

.section-header__more {
  position: absolute;
  right: 0;
  top: 0;
  line-height: 40px;
}

.member__intro .intro__title {
  font-weight: 300;
}

@include media-query(palm) {

  .member--editable {
    margin-top: $half-spacing-unit;
  }

  .member__intro {
    padding: $half-spacing-unit $base-spacing-unit;

    h2 {
      font-size: $large-font-size;
      font-weight: 400;
    }
  }

  .member__edit {
    top: 12px;
  }

}

