// Color // --------------------------------------------------
// Brand Colors
$medshr-primary    : #1B6EBF;
$medshr-secondary  : #4b9bef;
$medshr-white      : #efefef;
$medshr-green      : #1DBF97;
$medshr-yellow     : #f90;
$medshr-red        : #D0011B;
$medshr-red-active : #A60116;
$medshr-solid      : #09336A;
$medshr-solid-dark : #021740;

// Gray
$medshr-gray        : #1a1a1d;
$medshr-gray-dark   : #080811;
$medshr-gray-medium : #2c313f;
$medshr-gray-light-2        : #DBDEE0;
$medshr-gray-light  : #c0ccda;

// Opacity
$opa-80   : rgba(26, 26, 26, .8);
$opa-60   : rgba(26, 26, 26, .6);
$opa-40   : rgba(26, 26, 26, .4);
$opa-20   : rgba(26, 26, 26, .2);
$opa-10   : rgba(26, 26, 26, .1);

$opa-w-10 : rgba(226, 226, 226, .1);
$opa-w-20 : rgba(226, 226, 226, .2);
$opa-w-40 : rgba(226, 226, 226, .4);
$opa-w-60 : rgba(226, 226, 226, .6);
$opa-w-80 : rgba(226, 226, 226, .8);

// global space
$tiny-space   : 10px;
$small-space  : 20px;
$medium-space : 30px;
$large-space  : 40px;
$xlarge-space : 50px;

$global-space : (
  'tiny-space'   : $tiny-space,
  'small-space'  : $small-space,
  'medium-space' : $medium-space,
  'large-space'  : $large-space,
  'xlarge-space' : $xlarge-space,
);

// global variables
$global-font-size  : 100%;
$global-lineheight : 1.5;
$global-margin     : 1rem;
$global-padding    : 1rem;
$global-radius     : 6px;
$global-font-size  : 1rem;
