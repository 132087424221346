$hexagon-background: #d4e5ff;
$border-color: solid 1px rgba(255, 255, 255, 0.2);
$interstitial-mobile-breakpoint: 650px;

.interstitial-landing__wrapper {
  background: #0f0c29; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #24243e,
    #302b63,
    #0f0c29
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #24243e,
    #302b63,
    #0f0c29
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  min-height: 100vh;
}

.interstitial-landing__wrapper--brand-fill {
  background: $dark-background-color;
}

.interstitial-landing__case-and-group-header {
  background: $dark-background-color;
  color: white;
  position: fixed;
  width: 100%;
  border-bottom: solid 1px rgba(255, 255, 255, 0.1);
  z-index: 2;
}

@media screen and (min-width: $interstitial-mobile-breakpoint) {
  .interstitial-landing__case-and-group-header-outer {
    display: none;
  }
  .interstitial-landing__case-and-group-footer-outer {
    display: none;
  }
}

.interstitial-landing__case-or-group {
  &.interstitial-landing__case-or-group--desktop {
    background: #03142e;
    @media screen and (max-width: $interstitial-mobile-breakpoint) {
      display: none;
    }
  }

  &.interstitial-landing__case-or-group--mobile {
    display: none;
    @media screen and (max-width: $interstitial-mobile-breakpoint) {
      display: block;
    }
  }
}

// TEMPORARY: until app-download page is desktop responsified
.temp-interstitial-app-download-container-selector {
  .interstitial-landing__mobile-container {
    max-width: 600px;
  }
}

.interstitial-landing__mobile-container {
  max-width: 1200px;
  margin: 0 auto;
}

.interstitial-landing__footer {
  position: fixed;
  background: $dark-background-color;
  width: 100%;
  bottom: 0px;
  display: flex;
  align-items: center;
  border-top: $border-color;

  .interstitial-landing__mobile-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
  }
}

.interstitial-landing__store-badges-text {
  color: white;
  @media screen and (max-width: 450px) {
    max-width: 70px;
    font-size: 12px;
    padding-left: 10px;
  }
}

.interstitial-landing__store-badges {
  height: 40px;
  background: none;
  @media screen and (max-width: 450px) {
    height: 35px;
  }
}

.interstitial-landing__as-seen-on-heading {
  padding: 10px;
  text-align: center;
  border-bottom: $border-color;
  display: none;
  @media screen and (max-width: $interstitial-mobile-breakpoint) {
    display: block;
  }
}

.interstitial-landing__as-seen-on-subheading {
  font-size: 12px;
  font-weight: 700;
  display: none;
  justify-content: center;
  margin-top: 15px;
  @media screen and (max-width: $interstitial-mobile-breakpoint) {
    display: flex;
  }
}

.interstitial-landing__as-seen-on-logos {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  padding: 5px 0px;

  @media screen and (min-width: $interstitial-mobile-breakpoint) {
    padding: 15px 10px;
  }
}

.interstitial-landing__as-seen-on-logo {
  height: 45px;
  margin: 10px;
  background: none;
  flex-shrink: 0;
  flex-grow: 0;
}

.interstitial-landing__spinner {
  animation-name: spin;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  display: inline-block;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

.interstitial-landing__spinner-wrapper {
  width: 100%;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.4);
}

.interstitial-landing__app-store-preview-heading {
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 10px;
  color: white;
}

.interstitial-landing__app-store-preview-subheading {
  color: white;
  margin-bottom: 20px;
  font-size: 16px;
}

.interstitial-landing__app-store-preview-img {
  width: 32%;
  margin-left: 1%;
}

.interstitial-landing__default-image__wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  min-height: 100%;
}

.interstitial-landing__default-image__background {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  filter: blur(5px);
  background-size: cover !important;
  background-position: center center !important;
  background-repeat: no-repeat !important;
}

.interstitial-landing__default-image__info {
  width: 100%;
  min-height: 10vh;
  overflow: auto;
  color: white;
  padding: 70px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  h1 {
    z-index: 1;
    font-size: 20px;
    max-width: 95%;
  }

  h2 {
    z-index: 1;
    font-size: 16px;
    max-width: 95%;
  }

  img {
    z-index: 1;
    width: 100px;
    margin-bottom: 10px;
  }
}

.interstitial-landing__default-image__grey-overlay {
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  z-index: 0;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
}

.interstitial-landing__case-info-avatar {
  width: 32px;
  height: 32px;
  border-radius: 32px;
  font-size: 16px;
  background: $brand-color;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.interstitial-landing__case-info-user {
  text-align: left;
  margin-left: 12px;
}

.interstitial-landing__case-info-row {
  padding: 7px 12px;
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
  &.interstitial-landing__case-info-row--top {
    padding-top: 25px;
  }
}

.interstitial-landing__case-info-case-title {
  font-size: 18px;
}

.interstitial-landing__case-info-categories {
  margin-bottom: 10px;
  color: $brand-color;
}

.interstitial-landing__case-info-blurred-text {
  filter: blur(5px);
  margin-bottom: 10px;
  user-select: none;
}

.interstitial-landing__footer-full-width-btn {
  width: 100%;
  padding: 14px 10px;
  font-size: 16px;
  background: #0081ff;
  color: white;
  border-radius: 5px;
  border: solid 1px rgba(255, 255, 255, -1.9);
  font-weight: 700;
  margin: 0px 2%;
}

.interstitial-landing__desktop-logo-wrapper {
  padding: 20px 10px;
  color: white;
  font-size: 28px;
}

.interstitial-landing__desktop-logo-icon {
  width: 50px;
  height: 50px;
  border-radius: 12px;
  border: solid 1px rgba(255, 255, 255, 0.1);
  margin-right: 7px;
}

.interstitial-landing__benefits {
  display: flex;
  margin-bottom: 20px;
  @media screen and (max-width: 800px) {
    flex-direction: column;
  }
}

.interstitial-landing__benefit {
  width: 33%;
  padding: 6px;
  font-size: 14px;
  display: flex;
  @media screen and (max-width: 800px) {
    width: 100%;
    max-width: 300px;
    position: relative;
    margin: 0 auto;
    padding: 8px;
    align-items: center;
  }
}

.interstitial-landing__hexagon {
  position: relative;
  width: 50px;
  height: 28.87px;
  background-color: $hexagon-background;
  margin: 14.43px 0;
}

.interstitial-landing__hexagon:before,
.interstitial-landing__hexagon:after {
  content: '';
  position: absolute;
  width: 0;
  border-left: 25px solid transparent;
  border-right: 25px solid transparent;
}

.interstitial-landing__hexagon:before {
  bottom: 100%;
  border-bottom: 14.43px solid $hexagon-background;
}

.interstitial-landing__hexagon:after {
  top: 100%;
  width: 0;
  border-top: 14.43px solid $hexagon-background;
}

.interstitial-landing__hexagon-child {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
}

.interstitial-landing__desktop-footer {
  padding: 5px;
  color: white;
  text-align: center;
  @media screen and (max-width: $interstitial-mobile-breakpoint) {
    display: none;
  }
}
