.custom-checkbox {
  label {
    display: block;
    margin: 5px 0;
    color: $light-link-color;
    padding: 15px 0 13px 0;
    cursor: pointer;
  }
  input[type='radio'],
  input[type='checkbox'] {
    float: right;
    visibility: hidden;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px 1px 1px 1px);
    margin: 0;
  }
}

.custom-checkbox--right {
  label {
    padding-right: 40px;
  }
}

.list-inline {
  .custom-checkbox {
    margin-left: 0;
    margin-right: $base-spacing-unit;
    label {
      display: inline-block;
      padding-top: 5px;
      padding-bottom: 5px;
      padding-right: 0;
    }
  }
  .custom-checkbox__icon,
  label {
    margin: 0;
  }

  .custom-checkbox--right {
    margin-left: $base-spacing-unit;
    margin-right: 0;
  }
}

.custom-radio__icon,
.custom-checkbox__icon {
  display: block;
  width: 30px;
  height: 30px;
  cursor: pointer;
  margin-top: 5px;
  color: #fff;
  .icon__content {
    stroke: #fff;
    fill: #fff;
  }
  background-color: $base-text-color;
  border-color: $base-text-color;
  border-radius: 15px;
}

.custom-radio__icon {
  //background      : transparent url(../static/images/radio-button-off.svg) no-repeat center right;
  background: transparent url(/static/media/images/radio-button-off.svg)
    no-repeat center right;
  background-size: 30px 30px;
}

input[type='radio']:checked + .custom-radio__icon {
  //background      : transparent url(../static/images/radio-button-on.svg) no-repeat center left;
  background: transparent url(/static/media/images/radio-button-on.svg)
    no-repeat center left;
  background-size: 30px 30px;
}

input[type='radio']:checked + .custom-radio__icon + label,
input[type='checkbox']:checked + .custom-checkbox__icon + label {
  color: $base-text-color;
}

.custom-radio--disabled .custom-radio__icon,
.custom-checkbox--disabled .custom-checkbox__icon {
  opacity: 0.5;
}

.custom-radio--left .custom-radio__icon,
.custom-checkbox--left .custom-checkbox__icon {
  float: left;
  margin-right: $half-spacing-unit;
  padding-left: 1px;
  padding-bottom: 1px;
}

.custom-radio--right .custom-radio__icon,
.custom-checkbox--right .custom-checkbox__icon {
  float: right;
  margin-left: $half-spacing-unit;
  padding-left: 4px;
  padding-bottom: 1px;
  padding-top: 2px;
}

.notification-settings .btn--custom-checkbox {
  margin-top: 9px;
}

.notification-settings__section {
  padding-bottom: $base-spacing-unit;

  h2 {
    margin-bottom: 0;
  }
}

.custom-checkbox--small {
  font-size: 90%;

  .btn--custom-checkbox {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }

  svg.icon {
    width: 12px;
    margin-bottom: 1px;
    margin-left: 0;
  }

  label {
    padding: 2px 0 0 0;
  }
}

.group-member-permissions {
  .custom-checkbox label {
    width: 6em;
  }
}

.toggle-container,
.toggle {
  display: flex;
  align-items: center;
}

.toggle-label {
  margin-right: 5px;
}

.toggle-slider {
  display: inline-block;
  width: 35px;
  height: 19px;
  background-color: #ccc;
  border-radius: 15px;
  position: relative;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.toggle-slider.checked {
  background-color: #4b9bef;
}

.toggle-slider:before {
  content: '';
  position: absolute;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: white;
  top: 2px;
  left: 2px;
  transition: transform 0.2s ease;
}

#toggle:checked + .toggle-slider:before {
  transform: translateX(15px);
}
