
.get-app__header {
  background-color: $start-gradient-color;
  background-image: linear-gradient($start-gradient-color, $mid-gradient-color);
  color: #fff;
  padding: $base-spacing-unit;
  position: relative;

  .dialog__title {
    padding-right: 75px;
  }
}

.get-app__later {
  position: absolute;
  right: $base-spacing-unit;
  top: 21px;
}

@include media-query(lap-and-up) {
  .get-app__download-buttons {
    text-align: center;
    margin-left: 80px;
  }
}

.get-app__download--ios img {
  background-color: transparent;
}

.get-app__download--android img {
  background-color: transparent;
  border: 1px solid rgba(255,255,255,.4);
  border-radius: 4px;
  margin-left: 8px;
}

.get-app__features {
  text-align: center;
  padding: $twice-spacing-unit $base-spacing-unit $base-spacing-unit $base-spacing-unit;
  width: 820px;
}

@include media-query(palm) {
  .get-app__features {
    width: auto;
  }
}

.get-app__feature {
  color: $brand-color;
  margin: 0;
  padding: 80px $base-spacing-unit $base-spacing-unit;
}

.get-app__feature--consent {
  //background: url(../static/images/feature-icon-check@3x.png) no-repeat 50% 5px;
  background: url(/static/media/images/feature-icon-check@3x.png) no-repeat 50% 5px;
  background-size: 60px 60px;
}

.get-app__feature--secure {
  //background: url(../static/images/feature-icon-phone@3x.png) no-repeat top center;
  background: url(/static/media/images/feature-icon-phone@3x.png) no-repeat top center;
  background-size: 39px 69px;
}

.get-app__feature--discuss {
  background: url(/static/media/images/feature-icon-comments@3x.png) no-repeat 50% 5px;
  background-size: 67px 60px;
}

