
.tag-buttons {

  .btn {
    margin: 0 $half-spacing-unit $half-spacing-unit 0;
    .icon-x-circle {
      transform: translateY(-50%) rotateZ(0);
      transition: transform .26s ease-in-out;
    }
  }

  .btn--off .icon-x-circle {
    transform: translateY(-50%) rotateZ(45deg);
  }

}

