@use "sass:math";
///*------------------------------------*\
//    #WIDTHS
//\*------------------------------------*/

// A mixin to spit out our width classes. Pass in the columns we want the widths
// to have, and an optional suffix for responsive widths. E.g. to create thirds
// and quarters for a small breakpoint:
//
// @include inuit-widths(3 4, -sm);

// Predefine the variables below in order to alter and enable specific features.
$inuit-widths-namespace: $inuit-namespace !default;

// Do we want to use classes like `<div class="u-1/4">` instead of
// `<div class="u-1-of-4">`?
$inuit-use-fractions: true !default;
$inuit-widths-delimiter: \/ !default;

// Depending on what we chose for `$inuit-use-fractions`, create the relevant
// delimiter.
@if ($inuit-use-fractions == true) {
  $inuit-widths-delimiter: \/;
} @else {
  $inuit-widths-delimiter: -of-;
}

@mixin inuit-widths($inuit-widths-columns, $inuit-widths-breakpoint: null) {
  // Loop through the number of columns for each denominator of our fractions.
  @each $inuit-widths-denominator in $inuit-widths-columns {
    // If we’re trying to make wholes, just spit a 100% width utility out
    // one time only.
    @if ($inuit-widths-denominator == 1) {
      .#{$inuit-widths-namespace}u-1#{$inuit-widths-delimiter}1#{$inuit-widths-breakpoint} {
        width: 100% !important;
      }
    } @else {
      // Begin creating a numberator for our fraction up until we hit the
      // denominator.
      @for $inuit-widths-numerator from 1 to $inuit-widths-denominator {
        // Build a class in the format `.u-3/4` or `.u-3-of-4`.
        .#{$inuit-widths-namespace}u-#{$inuit-widths-numerator}#{$inuit-widths-delimiter}#{$inuit-widths-denominator}#{$inuit-widths-breakpoint} {
          width: math.div($inuit-widths-numerator, $inuit-widths-denominator) *
            100% !important;
        }
      }
    }
  }
}
