
$brand-round: 3px;

$inuit-enable-media--small:     true;
$inuit-enable-media--rev:       true;

// whether to use u-1/3 or u-1-of-3 size classes
$inuit-use-fractions:   false;

$inuit-layout-gutter:           50px;
$inuit-enable-layout--flush:    true;

$inuit-media-gutter: 10px;
$inuit-enable-media--large:     true;

$inuit-widths-columns: (
    1,
    2,
    3,
    4,
    5,
    6,
    12,
) !default;

$inuit-widths-columns-responsive: (
    1,
    2,
    3,
    4,
    5,
    6,
    12,
) !default;

