html {
  font-family: $brand-face;
  word-wrap: break-word;
}

.large,
p.large {
  font-size: $large-font-size;
}

.small,
p.small {
  font-size: $small-font-size;
}

.mini,
p.mini {
  font-size: $mini-font-size;
}

@include headings() {
  font-weight: 300;
}

h1.mega {
  @include inuit-font-size($mega-heading-size);
}

h1 {
  @include inuit-font-size($inuit-heading-size-1);
}

h2 {
  @include inuit-font-size($inuit-heading-size-2);
}

h3 {
  @include inuit-font-size($inuit-heading-size-3);
}

h4 {
  @include inuit-font-size($inuit-heading-size-4);
}

h5 {
  @include inuit-font-size($inuit-heading-size-5);
}

h6 {
  @include inuit-font-size($inuit-heading-size-6);
}

@include media-query(palm) {
  h1 {
    @include inuit-font-size($inuit-heading-size-1--palm);
  }
  h2 {
    @include inuit-font-size($inuit-heading-size-2--palm);
  }
  h3 {
    @include inuit-font-size($inuit-heading-size-3--palm);
  }
  h4 {
    @include inuit-font-size($inuit-heading-size-4--palm);
  }
  h5 {
    @include inuit-font-size($inuit-heading-size-5--palm);
  }
  h6 {
    @include inuit-font-size($inuit-heading-size-6--palm);
  }
}

hr {
  margin: $base-spacing-unit 0;
  border: 0;
  border-top: 1px solid #ccc;
}
