@use 'sass:math';
@import '../../../style/variables';
@import '../../../style/tools';
@import '../../../style/foundation/medshrsvariables';

.container {
  position: relative;
  width: 80px;
  height: 80px;
  padding: 0;
  margin: 0;
  &.withImg {
    box-shadow: 0 0 0 1px $opa-20;
  }
}

.input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.labelWrapper {
  cursor: pointer;
  display: block;
  width: 100%;
  height: 100%;
  border: 0;
  background-color: $bright-brand-color;
  background-position: center;
  background-size: cover;
  appearance: none;
  -webkit-appearance: none;
  //remove because that creates an issue on iPhone5 (the fade stops in the middle of the transition)
  //transition          : background 300ms ease-in-out, opacity 0.26s ease 0ms;
  text-align: center;

  &:hover {
    background-color: $base-accent-color;
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: $opa-40;
    z-index: 1;
    margin-top: -33px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.26s ease 0ms;
  }

  &.withImage {
    &:hover {
      &:before {
        opacity: 1;
        visibility: visible;
        top: 33px;
      }
    }
  }
}

.plusIcon {
  top: 25px;
  left: 26px;
  position: absolute;
}

.replace {
  position: absolute;
  top: 0;
  width: 100%;
  height: 23px;
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), transparent);
  color: $content-background-color;
  text-decoration: underline;
  z-index: 2;
  text-align: left;
  padding-left: 0;
  padding-top: 0;

  span {
    padding-left: 5px;
  }
}

.tapArea {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  background: rgba(0, 0, 0, 0);
}
