/*
 * See also styles in _components.cms-page.scss
 */

.posts,
.post {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
}

.post__content img {
  width: 100%;
  margin: 10px 0;

  // wordpress blog compatibility:
  &.alignone,
  &.size-full,
  &.aligncenter {
    object-fit: contain;
  }
}

.floated::before {
  content: '';
  clear: both;
  display: block;
}

.cms-image-block--fill-x-crop-y img {
  max-height: 300px;
  object-fit: cover;
  object-position: 100% 50%;
}

.cms-image-block--float-right {
  float: right;
  margin: 0 0 1rem 1.5rem;

  figcaption {
    max-width: 300px;
    text-align: center;
  }
}

.cms-image-block--float-left {
  float: left;
  margin: 0 1.5rem 1rem 0;

  figcaption {
    max-width: 300px;
    text-align: center;
  }
}

.cms-image-block--float-right img {
  max-width: 300px;
}

.cms-image-block--float-left img {
  max-width: 300px;
}

.posts__banner {
  background: #021740;
  color: white;
}

.posts__content {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
}

.post__content {
  font-size: 16px;
  line-height: 1.4;
}

.post-entry {
  width: 33%;
  padding: 5px;
  margin-bottom: $base-spacing-unit;

  h2 {
    font-size: 20px;
    font-weight: 400;
    line-height: 1.3;
    margin: 5px 0 0;
    color: black;
  }
}

@media screen and (max-width: 1023px) {
  .post-entry {
    width: 50%;
  }
}

@media screen and (max-width: 580px) {
  .post-entry {
    width: 100%;
    padding: 0;
  }

  .post-entry__content-wrapper {
    padding: 0;
  }
}

.post-entry__category {
  font-weight: 900;
  color: #9f9f9f;
  margin: 10px 0 0;
}

.post-entry__image-wrapper {
  position: relative;
  overflow: hidden;
  border: solid 1px rgba(0, 0, 0, 0.1);
}

.post-entry__image-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 200%;
  height: 200%;
  transform: translate(-25%, -25%);
  background-size: cover;
  filter: blur(30px) brightness(1.5);
  opacity: 0.5;
}

.post-entry__image {
  position: relative;
  border-radius: 3px;
  width: 100%;
  height: 200px;
  object-fit: contain;
  filter: drop-shadow(0 0 1px rgba(0, 0, 0, 0.3));
  background-color: transparent;
}

.post-entry__image--cover {
  object-fit: cover;
}

.post-entry__content-wrapper {
  padding: 5px;
}

.post-entry__publisher-and-date {
  display: flex;
  margin: 12px 0 12px;
}

.post-entry__excerpt {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
}

// Singular post styles

.post-entry__date-published {
  color: #9f9f9f;
}

.post__separator-heading {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.post__separator-heading-text {
  margin-right: 10px;
  font-weight: 500;
  color: #727272;
  letter-spacing: 3px;
  text-transform: uppercase;
}

.post__separator-heading-hr {
  flex-grow: 1;
}

.post__category {
  margin: 0 0 5px;
}

.post__category a {
  color: #727272;
  margin-right: 1em;
}

.post__title {
  margin: 0 0 15px;
}

.post__meta-name,
.post__meta-date,
.post__meta-comments {
  margin-right: 20px;
}

.post__meta {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 25px;
  white-space: nowrap;
}

.post__meta-name {
  color: #4440ff;
}

.post__meta .posted-on,
.post__meta-date {
  color: #727272;
  font-weight: 500;
}

.post__meta-date .icon-clock,
.post__meta-comments-icon {
  margin-right: 0.25em;
}

.post__meta-comments {
  color: #727272;
  font-weight: 500;
}

.carousel.carousel-slider {
  width: 100%;
  margin: 0 auto 2em;
}

.post h1,
.post h2,
.post h3,
.post h4,
.post h5,
.post h6 {
  font-weight: 400;
}

.post__tags-social {
  display: flex;
  flex-wrap: wrap;
}

.post__tags {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 2;
  align-items: center;
  margin-bottom: 15px;
}

.post__tags-label {
  margin: 5px 10px 10px;
}

@include media-query(palm-portrait) {
  .post__tags-label {
    display: none;
  }
}

.post__tag {
  background: #0500ff;
  padding: 6px 8px;
  display: inline-block;
  color: white;
  margin: 0 5px 3px 0;
  white-space: nowrap;
}

.post__header {
  position: relative;
}

.post .social-share {
  margin: 0 0 20px;
}

.post__layout-row {
  display: flex;
}

.post__layout-row-sidebar {
  padding: 0 20px 20px;
  width: 260px;
  flex-shrink: 0;

  .sidebar-ad {
    max-width: 100%;
    display: block;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 1023px) {
  .post__layout-row {
    flex-direction: column;
  }
  .post__layout-row-sidebar {
    flex-basis: 100%;
    padding: 0;
    width: auto;

    .sidebar-ad {
      max-width: 280px;
      margin: 0 auto;
    }
  }
}
