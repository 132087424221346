
.fx-fade-appear,
.fx-fade-enter {
  opacity : 0;
}

.fx-fade-appear-active,
.fx-fade-enter-active {
  opacity    : 1;
  transition : opacity .26s ease-in-out;
}

.fx-fade-appear-done,
.fx-fade-enter-done {
  opacity    : 1;
  transition : opacity .26s ease-in-out;
}

.fx-fade-exit {
  opacity : 1;
}

.fx-fade-exit-active {
  opacity    : 0.01;
  transition : opacity .26s ease-in-out;
}

