$docsearch-base-font-weight: 400;
$docsearch-text-size: math.div(19px, $inuit-base-font-size) * 1rem;
$docsearch-text-size--small: math.div(15px, $inuit-base-font-size) * 1rem;

.docsearch-landing {
  font-weight: $docsearch-base-font-weight;
}

.docsearch__section {
  margin: 0 0 4rem;
  display: flex;
  flex-direction: column;
  padding: 0;
}

.docsearch__section_fwcarousel {
  margin: 0 0 15rem;
  display: flex;
  flex-direction: column;
  padding: 0;

  @media (max-width: 1024px) {
    margin-bottom: 8rem;
  }

  @media (max-width: 780px) {
    margin-bottom: 6rem;
  }

  @media (max-width: 565px) {
    margin-bottom: 0rem;
  }
}

.docsearch__title {
  font-weight: bold;
  font-size: 2.2rem;
  line-height: 2rem;
}

.docsearch__subtitle {
  font-weight: bold;
  font-size: $docsearch-text-size;
}

.docsearch_landing-text {
  font-size: $docsearch-text-size;
  line-height: 1.4;
}

.docsearch_body-list {
  font-size: $docsearch-text-size;
  p {
    margin: 0 0 0.5em;
  }
}
.docsearch_body-list:last-child {
  margin-bottom: 0;
}

.docsearch__big-text {
  line-height: 1.4;
  font-size: $docsearch-text-size;
  font-weight: bold;
  margin: 0.5rem 0 0.5rem 0;
}

.docsearch__small-text {
  font-size: $docsearch-text-size--small;
  color: #707070;
  margin-bottom: 0.5rem;
}

.docsearch__pricing-item .docsearch__small-text {
  flex-grow: 2;
  margin-bottom: 2rem;
}

.docsearch__landing-main {
  display: flex;
  flex-direction: row;

  align-items: center;

  @media (max-width: 1024px) {
    flex-direction: column;
  }
}

.docsearch__landing-content {
  width: 45%;
  margin-right: 0.5rem;

  @media (max-width: 1024px) {
    width: 100%;
    padding-bottom: 1rem;
  }
}

.docsearch__landing-content-alt {
  width: 50%;
  margin-right: 0.5rem;

  @media (max-width: 1024px) {
    width: 90%;
    padding-bottom: 1rem;
  }
}

.docsearch__landing-slideshow {
  width: 55%;
  margin-left: 3rem;
  position: relative;
  overflow: hidden;

  @media (max-width: 1024px) {
    width: 90%;
    height: 300px;
    order: 0;
    margin: 2rem 0;
  }
}

.docsearch__big-button {
  background: $bright-brand-color;
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.12);
  border-radius: 6px;
  padding: 1rem;
  white-space: normal;

  font-size: $docsearch-text-size;
  font-weight: bold;
  white-space: normal;
  line-height: 2rem;
  padding: 1rem;

  @media (max-width: 452px) {
    font-size: $docsearch-text-size;
    line-height: 2rem;
  }
}

.docsearch__benefits-container {
  align-self: center;
  list-style: none;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-items: center;
}

.docsearch__benefits-item {
  width: 250px;
  height: 200px;
  margin: 10px;

  line-height: 65px;
  color: white;
  background: #0f213f;

  display: flex;
  flex-direction: column;
  padding: 18px 18.8245px 20px;
  border-radius: 2px;
}

.docsearch__benefits-icon {
  align-self: center;
  background: none;
  max-height: 100px;
  margin-bottom: 1rem;
}

.docsearch__benefits-text {
  font-style: normal;
  font-weight: bold;
  font-size: $docsearch-text-size;
  line-height: 2rem;
  text-align: center;
  color: #fff;
  padding: 0 1rem;
}

.docsearch__pricing-container {
  display: flex;
  //flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 1024px) {
    justify-content: space-around;
  }
}

.docsearch__section--plans {
  margin: 0;
}

.docsearch__pricing-item {
  display: flex;
  flex-direction: column;
  min-height: 300px;
  width: 300px;
  margin: 0 0 4rem;
  padding: 18px 18.8245px 20px;

  @media (max-width: 640px) {
    max-width: 30em;
    width: 100%;
  }
}

.docsearch__pricing-trial-text {
  font-size: $docsearch-text-size--small;
  margin-bottom: 0;
  margin-left: 0.5rem;
}

.docsearch__cta-divider {
  border-top: 2px dashed;
  margin: 1rem 0;
  color: #a9a9aa;
  letter-spacing: 0.25em;
}

.docsearch__card {
  background: #fff;
  border-radius: 7px;
  padding: 1rem 2rem;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2);

  transition: 0.3s;
  opacity: 1;
}

.docsearch__card--disabled {
  opacity: 0.4;
}

.docsearch__card:not(.docsearch__card--disabled):hover {
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
}

.docsearch__login {
  background: #fff;
  border-radius: 7px;
  padding: 1rem 2rem;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
  margin: 2rem auto;
}

.docsearch__login-signup {
  margin-bottom: 1rem;
}

.docsearch__partner-container {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
}

.docsearch__partner-item {
  background: #fff;

  padding: 5px;
  width: 125px;
  height: 75px;
  margin: 10px;

  line-height: 65px;
  color: white;
}

.docsearch__partner-logo {
  width: 100px;
  height: 50px;
}

.docsearch__try-it-now-button {
  width: 100%;
  margin-bottom: 4rem;
}

@media (max-width: 1024px) {
  .docsearch__section_fwcarousel,
  .docsearch__landing-slideshow {
    display: none !important;
  }
}
