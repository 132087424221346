@use 'sass:math';

@include media-query(lap-and-up) {
  .comments {
    margin-bottom: 40px;
  }
}

.comment-count {
  margin: 0;
  text-align: right;
}

.comment-count__amount {
  color: $low-text-color;
}

.comment-count__new {
  color: $danger-color;
}

.comments__list {
  display: block;
  clear: left;

  @include media-query(palm) {
    > div {
      position: relative !important;
    }
  }
}

.comment {
  background-color: $content-background-color;
  color: $mid-text-color;
  box-shadow: 1px 1px 0 rgba(0, 0, 0, 0);

  &.desc {
    background-color: #f2f2f2;
  }
  //&:first-child {
  //  background-color : #f2f2f2;
  //}
  &:last-child {
    background-color: $content-background-color !important;
  }
}

.comment__header {
  display: flex;
  flex-direction: row-reverse;
  padding: 15px math.div($base-spacing-unit, 1.3) 0;
  margin-bottom: 12px;

  .profile-basic {
    flex-grow: 1;
  }
}

.comment__tools {
  .dropdown__toggle {
    border: 0;
    background: transparent none;
    padding: 0 0 10px;
    display: block;
    outline: 0;
  }
  .icon {
    display: block;
  }
}

.comment__time {
  color: $low-text-color;
  font-size: $small-font-size;

  &.comment {
    &:first-child {
      background-color: #f2f2f2;
    }
  }
}

.comment--with-tools .comment__time {
  top: 17px;
}

.comment__text {
  @extend %clearfix;
  padding: 0 math.div($base-spacing-unit, 1.3) 0;
  border-bottom: 1px solid $off-white;
  white-space: pre-line;

  &.noChild {
    border-bottom: 1px solid transparent;
  }

  p {
    margin-bottom: 13px;
  }
}

.comment__post-body {
  display: block;
  background: $content-background-color;
  border: 0;
  color: $link-color;
  padding: $half-spacing-unit $base-spacing-unit;
  box-shadow: 1px 1px 0 rgba(0, 0, 0, 0.1);
  width: 100%;
  min-height: 120px;
  outline: none;
  resize: vertical;
}

.comments__post {
  @extend %clearfix;
  margin-top: 20px;
}

.comments__post-header {
  padding: 10px;
}

.comments__post-footer {
  padding: $half-spacing-unit 0;
  text-align: right;

  .icon-spinner {
    margin: 5px $base-spacing-unit 0 0;
    fill: $low-text-color;
  }

  .btn {
    float: right; // allows spinner top margin to be used to align with this button
  }
}

@include media-query(palm) {
  .comments__post-footer {
    padding: $half-spacing-unit $base-spacing-unit;
  }
}

.comment__status {
  font-size: 90%;
  font-weight: bold;
  text-align: center;
}

.comment--hidden {
  border-top: 2px dashed #ccc;
  border-bottom: 2px dashed #ccc;
  opacity: 0.5;
}
