.contents__header {
  padding: 20px;
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  justify-content: left;

  @media (max-width: 1024px) {
    white-space: normal;
    padding: 15px;
  }

  h2 {
    margin-left: 0.5rem;
    font-weight: 900;
    margin-bottom: 0;
    margin-top: 0;
    line-height: 1;
  }
}

.contents__mobile-closed {
  display: none;
}

.contents__nav {
  list-style: none;
  position: relative;
  padding-top: 8px;
  padding-bottom: 8px;

  @media screen and (max-width: 768px) {
    overflow: hidden;
    padding: 20px 20px 20px 0;
  }
}

.contents__item-collapsed {
  width: 100%;
  display: flex;
  position: relative;
  box-sizing: border-box;
  text-align: left;
  align-items: center;
  justify-content: flex-start;
  text-decoration: none;

  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  cursor: pointer;

  @media screen and (max-width: 786px) {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
}

.contents__item-inner {
  flex: 1 1 auto;
  min-width: 0;
  margin-top: 4px;
  margin-bottom: 4px;
  white-space: normal;

  margin-left: 0.5rem;
  margin-right: 0.5rem;

  @media (max-width: 1024px) {
    margin: 0;
  }

  span {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    display: block;
  }

  button {
    background: transparent;
    border: none;
    width: 100%;
    text-align: left;

    :hover {
      text-decoration: none;
      background-color: rgba(129, 128, 128, 0.05);
    }
  }
}

.contents__item-nested {
  svg {
    margin-left: 1rem;

    @media (max-width: 1024px) {
      margin: 0.5rem;
    }
  }
}

.contents__divider:not(:last-child) {
  border: none;
  height: 1px;
  margin: 0.5rem 0;
  flex-shrink: 0;
  background-color: rgba(0, 0, 0, 0.1);
}

.contents__header-icon .feather-book-open,
.feather-book,
.feather-arrow-down,
.feather-arrow-right {
  color: #09336a;
  position: sticky;
  top: 24px;
  margin-left: -2px;
}

.contents__header-button {
  display: inline-flex;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 1.2rem;
  background: transparent;
  border-radius: 25%;
  outline: 0;
  border: 0;
  box-shadow: 0 0 0 1.2px #09336a;
  transition: all 0.3s ease 100ms;
  margin-right: 0.75rem;

  &:hover {
    box-shadow: 0 0 0 2px #09336a;
  }
}
