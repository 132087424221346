.image-browser {
  max-width: 500px;
  margin: 0 auto;
  overflow: hidden;
}

@include media-query(palm) {
  .image-browser--no-images {
    margin: 0 $base-spacing-unit;
  }
}

.image-browser__thumbs-container {
  position: relative;
  margin-top: 2px;
}

.image-browser__thumbs {
  overflow-x: auto;
  &.owner--view {
    //background      : transparent url(../static/images/image-browser-empty-tile@3x.png) repeat-x;
    background: transparent
      url(/static/media/images/image-browser-empty-tile@3x.png) repeat-x;
    background-size: 82px 80px;
  }
  &::-webkit-scrollbar {
    background: transparent; /* Optional: just make scrollbar invisible */
  }
}

@include media-query(palm) {
  .image-browser__thumbs {
    overflow-x: auto;
  }
}

.image-browser__thumbs-inner {
  width: 50000px;
  height: 82px;
  white-space: nowrap;
}

.image-browser__thumb {
  margin-right: 2px;
  float: left;
  cursor: pointer;
  width: 80px;
  height: 80px;
  position: relative;

  .icon-blocked,
  .icon-play-large {
    @extend .center-both;
  }
}

.image-browser__thumb--transcoding {
  background-color: #bbc2c9;
}

.image-browser__resource {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
}

.image-browser__resource--transcoding {
  background-color: #bbc2c9;
  padding-top: 100%;
}

.image-browser__video video {
  width: 100%;
  height: 100%;
  background: #000;
  object-fit: contain;
}

.image-browser__resource-caption {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 100%;
  overflow: hidden;
  background-image: linear-gradient(
    rgba(0, 0, 0, 0.3),
    transparent,
    transparent
  );
}

.image-browser__resource-title {
  color: #fff;
  display: block;
  padding: $half-spacing-unit 60px $half-spacing-unit $base-spacing-unit;
  height: 100%;
  line-height: 1.4;
  word-wrap: break-word;
  font-size: $large-font-size;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 1);
}

.image-browser__fullscreen {
  cursor: pointer;
  position: absolute;
  top: 7px;
  right: 7px;
  width: 30px;
  height: 30px;
  border-radius: 30px;
  background: rgba(0, 0, 0, 0.3);
}

.image-browser__scroll {
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.5);
  width: 40px;
  text-align: center;
  svg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.image-browser__scroll--left {
  position: absolute;
  top: 0;
  height: 80px;
  left: 0;
  z-index: 1;
}

.image-browser__scroll--right {
  position: absolute;
  top: 0;
  height: 80px;
  right: 0;
  z-index: 1;
}

.videoWrapper {
  align-self: center;
  width: 100%;
  height: 100%;
  .video-react.video-react-fluid,
  .video-react.video-react-16-9,
  .video-react.video-react-4-3 {
    width: 100%;
    max-width: 100%;
    height: 100%;
  }
}

.flex-container {
  display: flex !important;
  justify-content: center;
}
