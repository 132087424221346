.form-renderer {
  margin: 2rem 0;
}

.form-renderer .form-group {
  margin-bottom: 1rem;
}

.form-renderer label {
  display: block;
  margin-bottom: 0.5rem;
}

.form-renderer input[type='text'],
.form-renderer select {
  display: block;
  width: 100%;
  padding: 0.67857143em 1em;
  font-size: 1em;
  line-height: 1.2857;
  color: rgba(0, 0, 0, 0.87);
  background: #fff;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.28571429rem;
  box-shadow: none;
  transition: color 0.1s ease, border-color 0.1s ease;
}

.form-renderer input[type='text']:focus,
.form-renderer select:focus {
  color: rgba(0, 0, 0, 0.95);
  border-color: #85b7d9;
  box-shadow: none;
}

.form-renderer .form-check-inline {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.form-renderer .form-check-label {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}

.form-renderer input[type='checkbox'],
.form-renderer input[type='radio'] {
  margin-right: 0.5rem;
}

.form-renderer .required-marker {
  color: red;
}
