@import '../../../style/variables';
@import '../../../style/tools';

$base-spacing-unit: 20px;

.modalPrompt {
  background: $off-white;
  padding: $base-spacing-unit - 5 $base-spacing-unit - 5;
  position: relative;
  z-index: 10;

  .span {
    align-self: center;
  }

  // Give space for sidekick node
  @include media-query(lap-and-up) {
    padding-right: 190px;
  }

  // For mobile use flex to position layout
  @include media-query(palm) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  // For Tiny Screen Layout become column
  @include media-query(palm-portrait) {
    flex-direction: column;
    margin-bottom: $base-spacing-unit;

    span {
      text-align: center;
    }
  }
}

.sideKick {
  // Media Queries
  @include media-query(lap-and-up) {
    position: absolute;
    right: $base-spacing-unit;
    top: 50%;
    transform: translateY(-50%);
  }

  @include media-query(palm) {
    position: relative;
    max-width: 160px;
  }

  @include media-query(palm-portrait) {
    margin: 10px 0 0;
  }
}
