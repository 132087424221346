@use "sass:math";
$base-line-height: 24px;
$brand: rgb(79, 157, 236);
$off-white: rgba($brand, 0.2);
$spin-duration: 1s;
$pulse-duration: 750ms;

$brand_dark: rgb(255, 255, 255);
$off-white_dark: rgba($brand_dark, 0.3);

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes pulse {
  50% {
    background: $brand;
  }
}

@keyframes pulse_dark {
  50% {
    background: $brand_dark;
  }
}

.wrapper {
  width: 100%;
  height: 100%;
  padding: 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.loadingPulse {
  position: relative;
  width: math.div($base-line-height, 4);
  height: $base-line-height;
  background: $off-white;
  animation: pulse $pulse-duration infinite;
  animation-delay: math.div($pulse-duration, 3);

  &:before,
  &:after {
    content: '';
    position: absolute;
    display: block;
    height: math.div($base-line-height, 1.5);
    width: math.div($base-line-height, 4);
    background: $off-white;
    top: 50%;
    transform: translateY(-50%);
    animation: pulse $pulse-duration infinite;
  }
  &:before {
    left: math.div(-$base-line-height, 2);
  }
  &:after {
    left: math.div($base-line-height, 2);
    animation-delay: math.div($pulse-duration, 1.5);
  }

  &.dark {
    background: $off-white_dark;
    &:before,
    &:after {
      background: $off-white_dark;
      animation: pulse_dark $pulse-duration infinite;
    }
  }
}
