
.contacts-list {

  dt {
    float: left;
    width: 6em;
  }
  dd {
    margin-left: 6em;
    margin-bottom: 5px;
  }

}

