$video-react-primary-background-color: $light-link-color;
$video-react-primary-background-transparency: 1;
$video-react-secondary-background-color: $link-color;
$video-react-secondary-background-transparency: 1;
$video-react-big-play-button-width: 68px;
$video-react-big-play-button-height: 68px;

// Video-react styles
@import './video-react/video-react';
@import '~react-image-gallery/styles/scss/image-gallery.scss';

$slideshow-modal-header-height: 60px;
$slideshow-thumbnails-height: 90px;
$slideshow-tools-height: $slideshow-modal-header-height +
  $slideshow-thumbnails-height;

.slideshow__resource {
  // non-fullscreen: full height minus modal header bar height
  height: calc(100vh - #{$slideshow-modal-header-height});
  background: #000;
  outline: none;
}

.fullscreen .slideshow__resource {
  // fullscreen: full height
  height: 100vh;
}

.has-thumbnails .slideshow__resource {
  // non-fullscreen with thumbnails: full height minus (modal header bar height plus thumbnails height)
  height: calc(
    100vh - #{($slideshow-modal-header-height + $slideshow-thumbnails-height)}
  );
}

.has-thumbnails .fullscreen .slideshow__resource {
  // fullscreen with thumbnails: full height minus thumbnails height
  height: calc(100vh - #{$slideshow-thumbnails-height});
}

.slideshow__video {
  height: 100%;
  position: relative;
}

.slideshow__video .video-react {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  max-height: 100%;
  background-color: transparent;
}

.slideshow__video .video-react-fluid {
  padding-top: 0 !important;
  height: 100%;
}

.video-react .video__play {
  background: transparent;
  border: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  outline: none;
  opacity: 1;
  transition: opacity 0.26s ease-in-out;
}

.video-react-playing .video__play {
  opacity: 0;
  cursor: default;
  pointer-events: none;
}

.video-container .progress-bar {
  margin: -4px auto 0;
  background: transparent;
  position: relative;
  z-index: 10;
  transition: opacity 0.26s ease-in-out;
}

.video-container .video-react-playing .progress-bar {
  opacity: 0;
}

.video-container:hover .video-react-playing .progress-bar {
  opacity: 1;
}

.slideshow__caption {
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  left: 50%;
  transform: translateX(-50%);
  bottom: 20px;
  display: inline-flex;
  align-items: center;
  padding: 5px 8px;
  border-radius: 3px;
  border: solid 1px rgba(255, 255, 255, 0.2);
  color: white;
  line-height: 1.1rem;
  white-space: normal;
  font-size: 12px;
  max-width: 450px;
}

// Slideshow library css overrides
.image-gallery-content,
.image-gallery-slide,
.image-gallery-image {
  background: none;
  height: auto;
}

.image-gallery-content.fullscreen,
.image-gallery-content.fullscreen .image-gallery-slide,
.image-gallery-content.fullscreen .image-gallery-image {
  max-height: 100vh !important;
  height: auto;
}

.has-thumbnails {
  .image-gallery-content.fullscreen,
  .image-gallery-content.fullscreen .image-gallery-slide,
  .image-gallery-content.fullscreen .image-gallery-image {
    max-height: calc(100vh - #{$slideshow-thumbnails-height}) !important;
    height: auto;
  }
}

.image-gallery-thumbnails-container {
  height: 80px !important;
  display: flex;
  justify-content: center;
}

.image-gallery-thumbnail {
  height: 100%;
  overflow: hidden;
}

// Slideshow library css overrides for MedShrOpen
.image-gallery--medshr-open .image-gallery-content,
.image-gallery-slide,
.image-gallery-image {
  background: none;
}

.image-gallery--medshr-open .image-gallery-content.bottom {
  margin-bottom: 110px;
}

.caption-container {
  height: 60px;
  padding: 0 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  //flex-direction: column;
}

.slideshow-caption,
.slideshow-close {
  display: flex;
  margin: 0 1rem;

  @media (max-width: 498px) {
    font-size: 1.2rem;
  }

  h1 {
    font-weight: bold;
    color: white;
    margin: 0;
    font-size: 1.4rem;

    @media (max-width: 498px) {
      font-size: 1.2rem;
    }
    @media (max-width: 420px) {
      font-size: 1rem;
    }
    @media (max-width: 360px) {
      font-size: 0.9rem;
    }
    @media (max-width: 260px) {
      font-size: 0.8rem;
    }
    @media (max-width: 215px) {
      font-size: 0.7rem;
    }
  }

  svg {
    margin-right: 1.5rem;
  }
}
