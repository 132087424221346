
.report-a-problem {
  margin: 3em 0 0;
  padding: 0;
  font-size: 90%;
  color: $low-text-color;
  text-align: right;
}

.resource__text-only-content .report-a-problem {
  text-align: left;
}

@include media-query(palm) {
  .report-a-problem {
    text-align: left;
  }
}

