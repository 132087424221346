@use 'sass:math';
fieldset {
  margin: 0 0 $base-spacing-unit 0;
  border: 0;
}

input,
.field__label,
select {
  display: block;
  font-family: $form-font-family;
  font-size: $form-font-size;
}

.field__label {
  cursor: pointer;
  font-weight: 300;
  font-size: $large-font-size;
  margin-bottom: math.div($base-spacing-unit, 4);

  &.required:after {
    content: '*';
  }

  abbr {
    display: none;
  }
}

.field--inline {
  .field__label,
  .field__control {
    display: inline-block;
  }
}

.form-section-title {
  @include inuit-font-size($inuit-heading-size-1);
}

.field {
  margin-bottom: $twice-spacing-unit;

  textarea,
  #{$all-text-inputs},
  select,
  select[multiple='multiple'] {
    box-sizing: border-box;
    transition: border-color;
    background-color: white;
    border-radius: $form-border-radius;
    border: 1px solid $form-border-color;
    box-shadow: $form-box-shadow;
    font-family: $form-font-family;
    font-size: $form-font-size;
    margin-bottom: math.div($base-spacing-unit, 2);
    padding: 10px;
    width: 100%;
    max-width: 100%;
    color: $light-link-color;

    &[disabled] {
      color: #999;
    }

    &:hover {
      border-color: $form-border-color-hover;
    }

    &:focus {
      border-color: $form-border-color-focus;
      box-shadow: $form-box-shadow-focus;
      outline: 1px solid rgba(#999, 0.5);
    }

    @include placeholder {
      color: $low-text-color !important;
    }
  }

  select {
    max-width: 100%;
    width: auto;
    //font-size: 90%;
    -webkit-appearance: none;
    -moz-appearance: none;
    padding: 10px;
    border-radius: $form-border-radius;
    cursor: pointer;
    background: #fff url(/static/media/images/caret@3x.png) no-repeat center
      right;
    background-size: 30px 11px;
  }

  &.wide select,
  select.wide {
    width: 100%;
  }

  textarea {
    resize: vertical;
    height: 5em;
  }

  input[type='search'] {
    appearance: none;
  }
}

.field__control p {
  margin-bottom: math.div($base-spacing-unit, 2);
}

p.field__value {
  color: $light-link-color;
}

.dark-background .field {
  margin-bottom: $base-spacing-unit;
}

.dark-background .field select {
  display: inline;
  margin: 0;
  padding: 0 24px 5px 0;
  margin-left: 10px;
  //background      : transparent url(../static/images/caret--flush@3x.png) no-repeat center right;
  background: transparent url(/static/media/images/caret--flush@3x.png)
    no-repeat center right;
  background-size: 20px 11px;
  border: 0;
  border-bottom: 1px solid $light-link-color;
  color: $light-link-color;
}

.radio label,
.checkbox label {
  display: inline;
  font-weight: normal;
  font-size: 90%;
  margin-right: 1em;
}

input[type='checkbox'],
input[type='radio'] {
  cursor: pointer;
  display: inline;
  margin-right: 0.25em;
}
input[type='file'] {
  padding-bottom: $half-spacing-unit;
  width: 100%;
}

.field__description,
.fm-description {
  color: $mid-text-color;
}

.editor-errors {
  color: #f30;
  a {
    color: #f30;
  }
}

.field--error,
.fm-error {
  textarea,
  #{$all-text-inputs},
  select {
    border-color: $form-border-color-error;
    border-width: 1px;
    border-radius: 1px;

    &:hover {
      border-color: lighten($form-border-color-error, 15);
    }
    &:focus {
      border-color: $form-border-color-focus;
    }
  }
}

.field {
  transition: opacity 0.16s ease-in;
}

.form--disabled .field,
.form--disabled .footer,
.field--disabled {
  opacity: 0.5;
  label,
  select,
  input,
  button {
    cursor: default !important;
  }
}

.action-link,
.form-section-title {
  transition: opacity 0.16s ease-in;
}

.form--disabled .action-link,
.form--disabled .form-section-title {
  cursor: default !important;
  opacity: 0.5;
}

.form__error {
  color: #f30;
  margin: 5px 2px 0;
  font-size: 12px;
}

.field--category .form__error {
  margin-left: 0;
}

.field--contact .form__error {
  margin: 5px 0;
}

.case-image-edit__form .form__error,
.field--upload .form__error {
  margin: 5px 0 15px;
}

.dark-background .form__error {
  color: #f50;
  margin: 0 0 10px;
  font-size: 14px;
}

.field--other-institutions__item {
  position: relative;
  .action-link {
    font-size: $small-font-size;
    position: absolute;
    top: 1em;
    right: -5em;
  }
}

.form__field--sharable_externally {
  padding: 20px;
}

.form__field--live_status {
  padding: 0 20px;
  label {
    color: #4b9bef;
    margin-right: 1em;
  }
  label,
  select,
  input {
    display: inline-block;
  }
  p {
    margin: 0.5em 0;
  }
}

.form-radio input,
.form__checkbox-html-form-input {
  opacity: 0;
  display: flex;
  align-items: center;
}

.form-radio,
.form__checkbox-html-form {
  display: flex;
}

.form__checkbox-html-form label::before {
  content: '';
  display: inline-block;

  height: 16px;
  width: 16px;

  border: 1px solid rgba(0, 0, 0, 0.3);
  background: rgba(0, 0, 0, 0.05);
  border-radius: 2px;
}

.field--error .form__checkbox-html-form label::before {
  border: 1px solid rgba(255, 0, 0, 0.5);
}

.form-radio,
.form__checkbox-html-form {
  input:focus + label::before {
    outline: 1px solid rgba(#333, 0.5);
  }
}

.form-radio label::before {
  content: '';
  display: inline-block;

  height: 16px;
  width: 16px;

  border: 1px solid rgba(0, 0, 0, 0.3);
  background: rgba(0, 0, 0, 0.05);
  border-radius: 20px;
}

.form-radio label::after {
  content: '';
  display: inline-block;
  height: 8px;
  width: 8px;
  background-color: #666;
  border-radius: 10px;
}

.form__checkbox-html-form label::after {
  content: '';
  display: inline-block;
  height: 6px;
  width: 9px;
  border-left: 2px solid;
  border-bottom: 2px solid;

  transform: rotate(-45deg);
}

.form-radio label,
.form__checkbox-html-form label {
  position: relative;
  cursor: pointer;
  span {
    margin-left: 10px;
  }
}
.form-radio label::before,
.form-radio label::after,
.form__checkbox-html-form label::before,
.form__checkbox-html-form label::after {
  position: absolute;
  margin-left: -15px;
  margin-top: -2px;
}

.form-radio label::before,
.form__checkbox-html-form label::before {
  top: 3px;
}

.form-radio label::after,
.form__checkbox-html-form label::after {
  left: 4px;
  top: 7px;
}

/*Hide the checkmark by default*/
.form-radio input + label::after,
.form__checkbox-html-form-input + label::after {
  content: none;
}
/*Unhide the checkmark on the checked state*/
.form-radio input:checked + label::after,
.form__checkbox-html-form-input:checked + label::after {
  content: '';
}
