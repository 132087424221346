@use "sass:math";
@import '../../../../style/variables';
@import '../../../../style/tools';

// variables
$global-padding: 1rem;

// HEADER
$header-bg: $dark-background-color;

// BODY
$body-bg: $dark-background-color;

.modal {
  display: block;
  background-color: $body-bg;
  height: 100%;
  width: 100%;
  color: $off-white;
  font-size: $base-font-size;
  overflow: hidden;
  padding: 40px $base-spacing-unit * 1.5 20px $base-spacing-unit * 1.5;

  a {
    color: $off-white;
    text-decoration: underline;
  }
}

.closeButton {
  position: absolute;
  top: $base-spacing-unit - 18;
  left: 1px;
}

.closeShape {
  position: relative;
  display: block;
  width: 35px;
  height: 35px;

  &:after,
  &:before {
    content: '';
    background: #fff;
    height: 2px;
    border-radius: 22px;
    position: absolute;
    top: 50%;
    left: 25%;
    width: 18px;
    transform: rotate(45deg);
  }

  &:before {
    transform: rotate(-45deg);
  }
}

.body {
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.item {
}

.title {
  font-size: 1.285714286rem;
  font-weight: 400;
  padding-bottom: $base-spacing-unit * 1.5;
  padding-top: math.div($base-spacing-unit, 2);
}

.subtitle {
  font-size: $base-font-size;
  font-weight: 100;
  padding-top: math.div($base-spacing-unit, 4);
}

.instructions {
  padding-bottom: $base-spacing-unit;
  font-weight: 400;
}

.instructionsSmallPadding {
  @extend .instructions;
  padding-bottom: math.div($base-spacing-unit, 4);
}

.email {
  color: $light-link-color;
  padding-bottom: $base-spacing-unit;
}

.action {
  font-weight: 200;
  //padding-bottom : $base-spacing-unit * 2;
}

.footer {
  bottom: $base-spacing-unit;
  font-size: $mini-font-size;
  font-weight: 200;
  position: initial;
}

.linkBtn {
  text-decoration: underline;
  color: $off-white;
  display: inline-block;
  margin-bottom: 10px;
}

.m_t_10 {
  margin-top: 10px;
}

.m_b_10 {
  margin-bottom: 10px;
}

.spam {
  padding-bottom: math.div($base-spacing-unit, 2);
}

.disclaimer {
  padding-bottom: math.div($base-spacing-unit, 2);
}
