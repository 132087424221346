.card {
  .savedsearch__wrapper {
    height: 340px;
  }
}

.cms-block--saved-search--results {
  background: #fff;
  box-shadow: 1px 1px 0 rgb(0 0 0 / 10%);
}

.cms-block--saved-search {
  .savedsearch__wrapper {
    padding: 10px;
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    white-space: pre-wrap;
    overflow-wrap: break-word;
  }

  .savedsearch__card {
    max-width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 0;
    background: #fff;
    padding: 0;
    border: none;
    border-radius: 0.28571429rem;
    box-shadow: 0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5;
    transition: box-shadow 0.1s ease, transform 0.1s ease,
      -webkit-box-shadow 0.1s ease, -webkit-transform 0.1s ease;
    margin: 1em 0;
    width: 280px;
    height: 450px;
  }

  .savedsearch__card:first-child {
    margin-top: 0;
  }

  .savedsearch__card:last-child {
    margin-bottom: 0;
  }

  .savedsearch__card > .image {
    position: relative;
    display: block;
    flex: 0 0 auto;
    padding: 0;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 0.28571429rem 0.28571429rem 0 0 !important;
    border-top: none !important;
    object-fit: cover;
    overflow: hidden;
    object-position: center;
    max-height: 225px;
  }

  .savedsearch__card > .image img {
    border: none;
    display: block;
    width: 100%;
    height: auto;
    border-radius: inherit;
  }

  .savedsearch__card > .content {
    flex-grow: 1;
    border: none;
    border-top: 1px solid rgba(34, 36, 38, 0.1);
    background: 0 0;
    margin: 0;
    padding: 1em 1em;
    box-shadow: none;
    font-size: 1em;
    border-radius: 0;
  }

  .savedsearch__card > .content:after {
    display: block;
    content: ' ';
    height: 0;
    clear: both;
    overflow: hidden;
    visibility: hidden;
  }

  .savedsearch__card > .content > svg {
    display: inline-block;
    opacity: 1;
    margin: 0 0.25rem 0 0;
    width: 1.18em;
    height: 1em;
    font-style: normal;
    font-weight: 400;
    text-decoration: inherit;
    text-align: center;
    speak: none;
    backface-visibility: hidden;
    font-size: 1em;

    path {
      fill: rgba(0, 0, 0, 0.4);
    }

    :before {
      background: 0 0 !important;
    }
  }

  .savedsearch__card > .content > .header {
    display: block;
    color: rgba(0, 0, 0, 0.85);

    font-weight: 700;
    font-size: 1.28571429em;
    margin-top: -0.21425em;
    line-height: 1.28571429em;
  }

  .savedsearch__card > .content > .meta {
    font-size: 1em;
    color: rgba(0, 0, 0, 0.4);
  }

  .savedsearch__card > .extra {
    max-width: 100%;
    min-height: 0 !important;
    flex-grow: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.05) !important;
    position: static;
    background: 0 0;
    width: auto;
    margin: 0 0;
    padding: 0.75em 1em;
    top: 0;
    left: 0;
    color: rgba(0, 0, 0, 0.4);
    box-shadow: none;
    transition: color 0.1s ease;
  }

  .savedsearch__card > .details {
    align-items: center;
    display: flex;
  }
}

.savedsearch__buttons {
  box-shadow: none;
  display: inline-flex;
  flex-direction: column;
  font-size: 0;
  vertical-align: baseline;
  margin: 0 0.25em 0 0;

  width: 100%;
}

.savedsearch__buttons > .button {
  cursor: pointer;
  display: inline-block;
  min-height: 1em;
  outline: 0;
  border: none;
  vertical-align: baseline;
  background: #e0e1e2 none;
  color: rgba(0, 0, 0, 0.6);
  padding: 0.78571429em 1.5em 0.78571429em;
  text-transform: none;
  text-shadow: none;
  font-style: normal;
  text-align: center;
  text-decoration: none;
  user-select: none;
  transition: opacity 0.1s ease, background-color 0.1s ease, color 0.1s ease,
    box-shadow 0.1s ease, background 0.1s ease, -webkit-box-shadow 0.1s ease;
  flex: 1 0 auto;
  margin: 0 0 5px 0;
  border-radius: 0;
  font-size: 1rem;
  box-shadow: 0 0 0 1px transparent inset, 0 0 0 0 rgb(34 36 38 / 15%) inset;
  font-weight: bold;
  line-height: 20px;
  white-space: normal;
  word-wrap: break-word;
  width: 100%;
}

.savedsearch__buttons > .button:first-child {
  border-radius: 3px;
}

.savedsearch__buttons > .button:last-child {
  border-radius: 3px;
  margin-bottom: 0;
}

.savedsearch__buttons > .green {
  background-color: #21ba45;
  color: #fff;
  text-shadow: none;
  background-image: none;
}

.savedsearch__buttons > .blue {
  background-color: $light-link-color;
  color: #fff;
  text-shadow: none;
  background-image: none;
}

.saved-search__modal {
  display: none;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
}

.saved-search__modal.show {
  display: flex;
  align-items: center;
  justify-content: center;
}

.saved-search__modal-content {
  background-color: #fff;
  padding: 20px;
  max-width: 80%;
  max-height: 80%;
  overflow-y: auto;
}
