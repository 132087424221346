$modal-container__header-height: 66px;

.modal-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: $modal-backdrop-color;
  z-index: $modal-backdrop-z-index;
}

.modal-container__content {
  position: relative;
  height: 100%;
}

@include media-query(palm) {
  .showing-modal .page__header div {
    display: none;
  }

  .showing-modal .page__rest {
    opacity: 0;
  }

  .modal-container {
    background-color: #fff;
    overflow-y: auto;
  }

  .modal-container__content {
    width: 100%;
  }
}

.modal-container__close {
  color: #fff;
  // positioned to appear over the hamburger icon
  position: absolute;
  top: 23px;
  right: 3px;
}

.modal {
  background: #fff;
}

.modal--listing {
  min-height: 280px;
  height: 450px;
  width: 750px;
  max-width: 100%;
}

@include media-query(lap-and-up) {
  .modal--centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .modal--dialog,
  .modal--close-prompt {
    min-height: 280px;
    width: 400px;
    max-width: 90%;
  }
}

.modal--fullscreen {
  background-color: $base-background-color;
  position: fixed !important;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.modal--fullscreen.black {
  background-color: black;
}

.modal--dialog {
  padding: $base-spacing-unit;
}

.modal__header {
  background: $modal-header-background-color;
  color: #fff;
  padding: 13px $base-spacing-unit;
  @extend .clearfix;

  h1,
  h2 {
    margin: 0;
    font-weight: 100;
  }

  .search-field {
    float: left;
  }

  .btn {
    float: right;
  }
}

@include media-query(palm) {
  .modal-container__close {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 1500;
  }

  .modal__header {
    h1,
    h2 {
      font-weight: 200;
    }
  }
}

.modal__prompt {
  background: $off-white;
  padding: 15px $base-spacing-unit;
  position: relative;
  min-height: 50px;

  .prompt-text {
    max-width: 35em;
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .btn--action {
    position: absolute;
    right: $base-spacing-unit;
    top: 50%;
    transform: translateY(-50%);
  }
}

.tag-picker {
  .modal__prompt {
    border-bottom: 4px solid $modal-header-background-color;
  }
}

@include media-query(palm) {
  .modal-container--tag-picker .modal-container__close {
    display: none;
  }

  .modal__header {
    padding: 13px $base-spacing-unit;

    .icon-search {
      display: none;
    }

    .search-field {
      width: 80%;
    }

    .search-field__input {
      padding: 8px 15px 9px;
      width: 100%;
    }

    .btn--action {
      background: transparent;
      border: 0;
      border-radius: 0;
      padding: 0;
      text-align: left;
      margin-top: 4px;
      color: #fff;
      text-decoration: underline;
      &:active,
      &:hover {
        background: transparent none !important;
      }
    }
  }

  .modal__prompt {
    padding: 15px $base-spacing-unit;
    .btn--action {
      right: $base-spacing-unit;
    }
  }
}

.modal__section {
  border-bottom: 4px solid $modal-header-background-color;
}

.modal__section:last-child {
  border-bottom: 0;
}

.tag-picker__category {
  border-bottom: 1px solid $modal-header-background-color;
}

.tag-picker__category:last-child {
  border-bottom: 0;
}

.modal-container--fullpage {
  background-color: $base-background-color;
  .modal {
    background-color: $base-background-color;
  }
}

.modal-container__header {
  background: $modal-header-background-color;
  color: #fff;
  height: $modal-container__header-height;
  h1 {
    margin: 0;
  }
  .page__container {
    padding: 14px 0 $base-spacing-unit;
    position: relative;
    height: 100%;
  }
}

@include media-query(palm) {
  .modal-container__header {
    h1 {
      margin: 0 $base-spacing-unit;
    }
  }
}

.modal-container__footer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 60px;
  background-color: $brand-color;
  color: #fff;
}

.modal-container--fullpage .scroll-trap {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: auto;
}

@include media-query(palm) {
  .page__rest .modal-container--fullpage .scroll-trap {
    top: $page__header-height;
  }
}

/*

  ** z-index for the search modal **
  
  - The search modal has to have a lower z-index than the generic modal, because the position of the search 
    modal means the dropdown navigation is still in play (unlike with a generic modal) and needs to go above it. 
    Due to the structure of the markup we cannot satisfy both of these following conditions (UNLESS we have a separate
    search-z-index):

      1. the dropdown (part of the main nav) goes above the search modal
      2. generic modal component goes above the nav 

  - On mobile, the navigation dropdown is no longer in play, and the modal container must go above
    the navigation to ensure the search input is visible (the search input is in the modal component).
    Thus the search modal reverts back to the generic modal's z-index
*/

.modal-container--search {
  z-index: $search-z-index;
  @media screen and (max-width: 783px) {
    z-index: $modal-backdrop-z-index;
  }

  .modal {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: auto;
    transform: none;
  }
}

@include media-query(lap-and-up) {
  .modal-container--search {
    top: 128px;

    .modal {
      left: auto;
    }
  }

  .modal {
    position: absolute;
  }

  .modal-container--fullpage .modal {
    top: $modal-container__header-height;
    right: 0;
    bottom: 0;
    left: 0;
    overflow-y: auto;
  }
}

@include media-query(desk) {
  .modal-container--search .modal {
    width: 80%;
  }
}

.modal__prompt .search__filters {
  display: block;
  white-space: nowrap;
  overflow-x: auto;
  width: auto;
  li {
    display: inline-block;
  }
  li:first-child .custom-checkbox--right {
    margin-left: 0;
  }
}

.search__body {
  overflow-y: auto;
  position: absolute;
  top: 50px;
  right: 0;
  bottom: 0;
  left: 0;
}

.member-search__body {
  overflow-y: auto;
  position: absolute;
  top: 60px + 48px;
  right: 0;
  bottom: 0;
  left: 0;

  @include media-query(palm-landscape) {
    top: 60px + 48px + 20px;
  }
  @include media-query(palm-portrait) {
    top: 60px + 48px + 50px;
  }
}

.connection-requests__body {
  overflow-y: auto;
  position: absolute;
  top: 60px;
  right: 0;
  bottom: 0;
  left: 0;
  padding: $base-spacing-unit 0;

  .activity-indicator {
    padding: 0 $base-spacing-unit;
  }

  .activity-panel {
    box-shadow: none;
    padding: 0 $base-spacing-unit $half-spacing-unit;
    border-bottom: 2px solid $dark-background-color;
  }

  .activity-panel__header {
    background-color: transparent;
    padding: 0;
    .btn {
      color: $light-link-color;
      border-color: $light-link-color;
      .icon__content {
        stroke: $light-link-color;
        fill: $light-link-color;
      }
      top: $base-spacing-unit;
      right: 0;
      z-index: $site-header-z-index - 1;
    }
    .btn--fill.btn--dark {
      color: #fff;
      border-color: #fff;
      .icon__content {
        stroke: #fff;
        fill: #fff;
      }
    }
  }
  .activity-panel__title {
    display: none;
  }

  .activity-panel__body {
    padding: 0;
  }

  .media {
    position: relative;
    min-height: 60px;
  }

  .media__body {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 70px;
    right: 10em;
    overflow: visible;
  }

  .activity-panel__footer {
    padding-left: 0;
  }

  .card-row {
    padding: 0;
  }
}

.connection-requests {
  .activity-panel__footer {
    position: relative;
    .btn--fill.btn--dark {
      position: absolute;
      right: $half-spacing-unit;
    }
  }
}

.modal--connection-requests {
  .activity-panel__footer {
    .btn--fill.btn--dark {
      right: 0;
    }
  }
}

@include media-query(palm) {
  .connection-requests__header {
    .btn {
      float: right;
      margin-top: 5px;
    }
  }

  .connection-requests__body {
    .media__body {
      right: 0;
    }
  }

  .modal__prompt .search__filters {
    right: $base-spacing-unit - 3;
  }

  .search__body {
    top: 60px + 50px;
  }
}

.search__body,
.member-search__body {
  .no-results,
  .activity-indicator {
    opacity: 0.6;
    margin-left: $base-spacing-unit;
    margin-top: $base-spacing-unit;
  }
}

.modal-container--save-prompt,
.modal-container--confirm-delete,
.modal-container--consent-edit {
  .modal--dialog {
    height: 450px;
  }
}

.modal-container--delete-account {
  .modal--dialog {
    height: 330px;
    .dialog {
      left: 30px;
      right: 30px;
    }
  }

  .dialog__button-list {
    width: 85%;
  }
}

.modal-container--consent-edit .modal--dialog {
  .dialog__title {
    margin-bottom: 1.5 * $base-spacing-unit;
    text-align: left;
  }
}

.edit-name__button-row,
.save-prompt__button-row {
  margin-bottom: $base-spacing-unit;
}

.save-prompt__text {
  font-size: $large-font-size;
  color: $mid-text-color;
  max-width: 20em;
  margin: 0 auto 1.5 * $base-spacing-unit;
}

.modal--email-invite,
.modal--report-a-problem,
.edit-name {
  .field {
    text-align: left;

    textarea,
    input.input--email,
    input.input--text {
      border: 1px solid $light-grey;
      box-shadow: none;
    }
  }
}

.modal--email-invite {
  padding: 0;

  textarea {
    height: 12em;
  }

  .email-invite__content {
    @extend .clearfix;
    padding: $base-spacing-unit;
  }
}

@include media-query(palm) {
  .modal-container--edit-name .modal-container__content {
    padding-top: $modal-container__header-height;
  }
}

@include media-query(lap-and-up) {
  .modal--edit-name {
    min-height: 450px;
  }
}

.modal--movie {
  width: 100%;
  padding: $base-spacing-unit;
  background-color: transparent;

  video {
    height: 80%;
    width: 100%;
  }
}

.modal--verifier {
  width: 850px;
  max-width: 98%;

  h1 {
    float: left;
    margin: 0;
  }

  .modal__header .btn {
    margin-top: 2px;
  }

  .modal__body {
    padding: $base-spacing-unit;
    height: 390px;
    overflow: auto;
  }

  .verifier__email,
  .verifier__image {
    margin-bottom: $base-spacing-unit;
  }
}

.modal-container--case-edit,
.modal-container--profile-edit {
  .modal {
    position: absolute;
    width: 100%;
    top: $modal-container__header-height;
    bottom: 0;
  }

  .modal-container__header {
    .icon-arrow-left {
      @include center-vertically();
      left: $base-spacing-unit;
    }

    .btn--save {
      @include center-vertically();
      right: $base-spacing-unit;
    }
  }
}

// Close icon is rendered for palm size and below (in the css above)
// These two css styles handle showing close icon for when modal-fullscreen is used (e.g. for slideshow)
.modal--fullscreen .modal-container__close {
  display: none;
}

@media (min-width: 784px) {
  .modal--fullscreen .modal-container__close {
    display: block;
  }
}

.modal--terms {
  max-width: 420px;
  line-height: 1.4;
  width: 100%;
}
.modal--terms__body {
  padding: $half-spacing-unit 0;
}
