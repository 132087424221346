@import '../../../style/variables';
@import '../../../style/tools';
// Error Text

.container {
  position: relative;
}

.textError {
  composes: textError from './InputField.module.scss';
}

.counterCont {
  position: absolute;
  right: -3px;
  bottom: -10px;
}

.counter {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 10;
}

.counterText {
  margin-right: 3px;
  font-size: 0.9em;

  &.dark {
    color: $mid-text-color;
  }

  &.light {
    color: $off-white;
  }

  &.reachLimit {
    color: $danger-color !important;
  }
}
