@import '../../../style/variables';
@import '../../../style/tools';

// Variables
$message-font-size: $base-font-size;

// We can add any color name and color for notification kind
$notificationKind: (
  'info': $bright-brand-color,
  'error': $danger-color,
);

.notification {
  position: fixed;
  top: -$page__header-height;
  left: 0;
  width: 100%;
  min-height: $page__header-height;
  z-index: $notification-z-index;
  transition: top 0.26s ease-in-out;

  .container {
    display: flex !important;
    align-items: center;
    justify-content: space-between;
    min-height: $page__header-height;
    padding: 5px;
  }

  // Message Block
  &__message {
    font-size: $message-font-size;
    margin: 0;
    right: 2 * $base-spacing-unit;
    left: $half-spacing-unit;

    strong {
      text-decoration: dotted;
      color: #fff;
      display: inline-block;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 1px;
        background-color: #fff;
        display: block;
        bottom: -3px;
        left: 0;
      }
    }
  }

  // Close Block
  &__close {
    padding: 0;
    background: transparent none;
    border: 0;
  }

  // Notification Kind colors modifier
  @each $notificationName, $color in $notificationKind {
    &--#{$notificationName} {
      background-color: $color;
      color: #f5f5f5;
    }
  }
}

.item {
  margin: 0 10px;
}

//.notification {
//  position   : fixed;
//  top        : -$page__header-height;
//  left       : 0;
//  width      : 100%;
//  height     : $page__header-height;
//  z-index    : $notification-z-index;
//  transition : top 0.26s ease-in-out;
//
//  .container {
//    position : relative;
//    height   : $page__header-height;
//  }
//
//  .verticalCenter {
//    @include center-vertically;
//  }
//
//  // Message Block
//  &__message {
//    @extend .verticalCenter;
//    font-size : $message-font-size;
//    margin    : 0;
//    right     : 2 * $base-spacing-unit;
//    left      : $half-spacing-unit;
//
//    &__extended {
//      right : $half-spacing-unit;
//    }
//
//    strong {
//      text-decoration : dotted;
//      color           : #fff;
//      display         : inline-block;
//      position        : relative;
//
//      &:before {
//        content          : '';
//        position         : absolute;
//        width            : 100%;
//        height           : 1px;
//        background-color : #fff;
//        display          : block;
//        bottom           : -3px;
//        left             : 0;
//      }
//    }
//  }
//
//  // Close Block
//  &__close {
//    @extend .verticalCenter;
//    padding    : 0;
//    right      : $base-spacing-unit - 5;
//    background : transparent none;
//    border     : 0;
//  }
//
//  // Notification Kind colors modifier
//  @each $notificationName, $color in $notificationKind {
//    &--#{$notificationName} {
//      background-color : $color;
//      color            : #f5f5f5;
//    }
//  }
//
//  @include media-query(lap-and-up) {
//    &__message {
//      right : 0;
//      left  : 0;
//    }
//
//    &__close {
//      right : 0;
//    }
//  }
//}
//
//.info {
//}
