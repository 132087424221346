.icon {
  cursor: pointer;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  width: 23px;
  height: 20px;
}

.count {
  display: inline-block;
  vertical-align: bottom;
  position: relative;
  z-index: 2;
  color: #4b9bef;
  box-sizing: content-box;
  padding: 3px 3px 0;
  cursor: pointer;
  margin-left: 4px;
  margin-bottom: 0;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

.noHover {
  cursor: default !important;
  color: #314559;
  &:hover,
  &:focus {
    text-decoration: none !important;
  }
}

.likeList {
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    padding: 0;
    margin: 0;
  }
}
