
.tag-picker__field-header,
.tag-picker__specialty-header {
  background: $off-white;
  padding: 5px 28px;
  position: relative;
}

.tag-picker__just-added-header {
  background: $off-white;
  padding: 15px 28px;
  position: relative;
}

.tag-picker__field-label,
.tag-picker__specialty-label,
.tag-picker__just-added-label {
  font-weight: 400;
  margin: 0;
}

.tag-picker__field-label__inner {
  display: block;
  margin: 5px 0;
  //color: #4b9bef;
  padding: 15px 0 13px 0;
}

.tag-picker__just-added-tools {
  position: absolute;
  right: 28px;
  top: 15px;
}

.tag-picker__just-added-tools,
.tag-picker__field-tools,
.tag-picker__specialty-tools {
  .disabled-link,
  .action-link {
    margin-left: $base-spacing-unit;
  }

  .action-link {
    color: $light-link-color;
  }
}

.tag-picker__specialty-tools {
  position: absolute;
}

.tag-picker__subspecialty {
  padding: 0 28px;

  .custom-checkbox {
    display: block;
    border-bottom: 1px solid #f2f2f2;
    padding: 5px 0;
  }
}

.tag-picker__subspecialty:last-child {
  .custom-checkbox {
    border-bottom: 0;
  }
}

.tag-picker__field .tag-picker__specialty {
  border-bottom: 0;
}

@include media-query(palm) {

  .tag-picker__content {
    position: absolute;
    top: 60px;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: auto;
  }

  .tag-picker__field-header,
  .tag-picker__specialty-header {
    padding: 15px $base-spacing-unit;
  }

  .tag-picker__subspecialty {
    padding: 5px $base-spacing-unit;
    min-height: 60px;
    position: relative;

    label {
      padding: 0;
      margin: 0;
      left: $base-spacing-unit;
      right: 50px;
      @include center-vertically;
    }

    .custom-checkbox {
      border: 0;
    }
  }

  .tag-picker {

    .custom-checkbox__icon {
      margin-top: 0!important;
      right: $base-spacing-unit;
      @include center-vertically;
    }

  }

  .tag-picker__specialty-tools {
    display: none;
  }

}

@include media-query(palm) {
  .tag-picker__field-tools,
  .tag-picker__specialty-tools {
    float: none;
    left: $base-spacing-unit;
    bottom: $half-spacing-unit;

    .disabled-link,
    .action-link {
      margin-left: 0;
      margin-right: $base-spacing-unit;
    }
  }
}

@include media-query(lap-and-up) {
  .tag-picker__field-tools,
  .tag-picker__specialty-tools {
    @include center-vertically;
  }
  .tag-picker__field-tools {
    right: 80px;
  }
  .tag-picker__specialty-tools {
    right: 80px;
  }
}

.tag-picker__body {
  overflow: auto;
}

@include media-query(lap-and-up) {
  .specialties-modal .tag-picker__body {
    // new modal
    height: 375px;
  }
  .tag-picker__body {
    height: 339px;
  }
}

.tag-picker__subspecialty {
  font-size: $large-font-size;
}

.tag-picker {
  .custom-radio__icon,
  .custom-checkbox__icon {
    margin-top: 15px;
  }

}

.tag-picker__specialty-header .custom-radio__icon,
.tag-picker__specialty-header .custom-checkbox__icon {
  margin-top: 7px;
}

.tag-picker__search-cancel {
  float: right;
  color: #fff;
  margin-top: 8px;
}

@include media-query(palm) {
  .tag-picker__status {
    padding         : 0 !important;
    min-height      : 40px;
    display         : flex;
    align-items     : center;
    justify-content : center;
    font-size       : 0.95em;
  }
}
