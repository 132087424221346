.case-edit {
  .page__content {
    padding-bottom: 80px;
  }

  .field {
    position: relative;
    margin-bottom: $base-spacing-unit;

    @include media-query(palm) {
      margin-bottom: 15px;
    }
  }

  .field__label {
    @include inuit-font-size($inuit-heading-size-1);
    font-weight: 100;
    margin-bottom: 12px;
  }

  .field--title {
    &.input--text {
      width: 70% !important;
    }
  }

  .field--category {
    margin-bottom: $half-spacing-unit;
  }

  .image-browser__thumbs-container {
    margin-bottom: $half-spacing-unit;
  }

  textarea {
    line-height: 1.5;
    font-weight: 200;
  }

  .case-tips {
    margin-top: 26px;
  }

  .field--poll-question,
  .field--poll-answers {
    .field__label {
      font-size: 120%;
      margin-bottom: 5px;
    }
  }

  .poll-add,
  .poll-remove {
    position: absolute;
    right: 0;
    top: 10px;
  }
}

.post-scheduling .react-datepicker__input-container input[type='text'] {
  min-width: 15em;
}

@include media-query(palm) {
  .case-edit .field__label {
    font-weight: 200;
  }
}

.case-edit,
.modal-container--case-image-edit {
  ::-webkit-input-placeholder {
    /* Chrome */
    color: #6f6f6f;
    font-weight: 200;
  }

  :-ms-input-placeholder {
    /* IE 10+ */
    color: #6f6f6f;
    font-weight: 200;
  }

  ::-moz-placeholder {
    /* Firefox 19+ */
    color: #6f6f6f;
    opacity: 1;
    font-weight: 200;
  }

  :-moz-placeholder {
    /* Firefox 4 - 18 */
    color: #6f6f6f;
    opacity: 1;
    font-weight: 200;
  }
}

.case-edit__set_consent_status {
  margin-right: $half-spacing-unit;
}

.modal-container--case-edit {
  .scroll-trap {
    bottom: 60px;
  }

  .modal-container__footer {
    padding-top: 15px;
  }
}

.case-edit__delete {
  display: block;
  float: left;
  padding-top: 6px;
  //font-weight             : 400 !important;
  //-webkit-font-smoothing  : antialiased !important;
  //-moz-osx-font-smoothing : grayscale !important;
}

.case-edit__draft {
  //font-weight             : 400 !important;
  //-webkit-font-smoothing  : antialiased !important;
  //-moz-osx-font-smoothing : grayscale !important;
}

.case-edit__publish {
  margin-left: $half-spacing-unit;
  //font-weight             : 400 !important;
  //-webkit-font-smoothing  : antialiased !important;
  //-moz-osx-font-smoothing : grayscale !important;
}

.case-image-upload {
  display: block;
  width: 80px;
  height: 80px;
  border: 0;
  padding: 0;
  background: $bright-brand-color;
  appearance: none;
  -webkit-appearance: none;
  transition: background 300ms ease-in-out;
  text-align: center;

  &.ui-state-hover {
    background-color: $base-accent-color !important;

    span {
      transform: scale(1.15);
    }
  }

  &.space {
    margin-bottom: 1rem;
  }
}

.case-image-edit__form {
  .form__field {
    padding: $base-spacing-unit;
  }

  .field__label {
    @include inuit-font-size($inuit-heading-size-1);
  }

  input.input--text {
    border: 1px solid #e2e2e2;
    box-shadow: none;
  }

  input.input--text:focus,
  input.input--text:hover {
    border: 1px solid #ccc;
    box-shadow: none;
  }
}

.case-image-edit__preview {
  background-color: $off-white;
  transition: background-color 0.26s ease-in-out;
  overflow: hidden;
  // position         : absolute;
  // top              : 0;
  // left             : 50%;
  // right            : 0;
  // bottom           : 0;
  position: relative;
  min-height: 450px;
}

.case-image-edit__preview--has-image {
  background-color: #111;
}

.case-image-edit__preview-text {
  text-align: center;
  @include center-vertically;
  left: 0;
  right: 0;
}

.case-image-edit__preview-image {
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 1;
  transition: opacity 0.26s ease-in-out;
}

.case-image-edit__preview-processing-text {
  padding: 0 20px;
  margin-top: -130px;
  display: block;
}

.case-image-edit__preview-loading {
  opacity: 0;
  color: #fff;
  transition: opacity 0.26s ease-in-out;
  pointer-events: none;
}

.crop-image-edit__preview {
  transition: opacity 0.24s ease-in-out 0ms;
}

.case-image-edit__preview-content--loading {
  transition: opacity 0.24s ease-in-out 0ms;
  .case-image-edit__preview-image,
  .crop-image-edit__preview {
    transition: opacity 0.24s ease-in-out 0ms;
    opacity: 0;
  }

  .case-image-edit__preview-loading {
    transition: opacity 0.24s ease-in-out 0ms;
    opacity: 1;
  }
}

.case-image-edit__preview-tools {
  position: absolute;
  bottom: 12px;
  right: $base-spacing-unit;
  left: $base-spacing-unit;

  .btn--save-image {
    margin-left: $half-spacing-unit;
  }

  .btn--delete-image {
    margin-top: 7px;
    display: inline-block;
  }
}

.case-settings {
  padding: 18px $base-spacing-unit $base-spacing-unit 28px;

  select {
    width: 250px;
    font-size: $large-font-size;
    background: transparent url(/static/media/images/caret--flush@3x.png)
      no-repeat center right;
    background-size: 20px 11px;
    box-shadow: none;
    border-bottom: 1px solid $light-link-color;
    padding: 0 24px 5px 0;

    &:hover {
      border-bottom: 1px solid $light-link-color;
    }

    &:focus {
      border-bottom: 1px solid $light-link-color;
      box-shadow: none;
    }
  }

  .field__label {
    font-weight: 400;
    margin-right: $half-spacing-unit;
  }
}

.field--recommends {
  margin: 0;
  padding: 1px 0 0;

  ul {
    margin-top: 15px;
  }

  li {
    margin-top: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  .custom-checkbox label {
    font-size: $large-font-size;
    padding: 5px 0;
    margin: 0;
  }
}

@include media-query(palm) {
  .modal-container--case-image-edit {
    .modal-container__close {
      z-index: 200;
      path {
        stroke: $base-accent-color;
      }
    }
  }

  .case-image-edit__form .form__field {
    padding: 20px 40px 20px 20px;
    margin-bottom: 0;
  }
}
