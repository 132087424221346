
.icon {
  fill: none;
}

svg.icon {
  overflow: visible;
}

.icon-menu {
  fill: #fff;
}

.icon--clickable {
  cursor: pointer;
}

.icon-blocked {
  stroke: #fff;
  stroke-width: 2px;
}
.icon-fullscreen {
  stroke: #fff;
  fill: #fff;
  .icon__border {
    stroke: #f8f8f8;
    fill: #000;
    fill-opacity: 0.05;
  }
}

.dark-background {
  .icon-spinner {
    fill: rgba(255, 255, 255, .6);
  }
}

.icon-pencil {
  fill: none;
  stroke: $link-color;
}

.icon-pdf {
  fill: #fff;
  stroke: none;
}

.icon-more {
  fill: $bright-brand-color;
  stroke: none;
}

