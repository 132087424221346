.social-share {
  margin: 0;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.social-share__icon {
  width: 30px;
  height: 30px;
  border: 2px solid $bright-brand-color;
  border-radius: 200px;
  cursor: pointer;
  margin-right: 10px;
  position: relative;

  .icon-linkedin,
  .icon-fb,
  .icon-twitter,
  .icon-email {
    fill: $bright-brand-color;
    @extend .center-both;
  }
}

.dark-background .social-share__icon {
  border: 2px solid #fff;

  .icon-linkedin,
  .icon-fb,
  .icon-twitter,
  .icon-email {
    fill: #fff;
  }
}

.movie-page {
  .social-share {
    @extend .center-horizontally;
    top: 25px;
    z-index: $modal-backdrop-z-index + 10;
  }
}

.social-icon-list {
  margin: 0.5rem !important;
}
