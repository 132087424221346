// Contains styles for the list of learning modules AND a singular learning module (i.e. the blocks)

@import './variables';

$score-bar-height: 30px;
$learning-page-default-font-size: 18px;

.learning-card__wrapper {
  background: white;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 100ms;
  height: 409px;
  //max-width: 225px;
  min-width: 200px;
  border: 0;
  box-shadow: 1px 1px 0 rgb(0 0 0 / 10%);
  margin-bottom: 10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  margin-left: -50px;

  &hover {
    box-shadow: 0 0 #0000, 0 0 #0000, 0 1px 2px 0 rgb(0 0 0 / 0.05);
  }

  @media (max-width: 782px) {
    margin-left: -25px;
  }
}

.learning-card__resource {
  position: relative;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  width: 200px;
  //height: 150px;npm run dev
  height: 132px;
}

.learning-card__title-section {
  padding: 5px 10px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 2px;
  display: flex;
  width: 100%;
  background: $mid-background-color;
  transition: 0.5s;
}

.learning-card__title-section > .learning-card__title {
  color: #fff;
  height: 60px;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  word-wrap: break-word;
  text-align: left;

  display: flex;

  span {
    max-height: 100%;
    @include line-clamp(5, 16px, 19.2px);
    color: #fff;
    text-overflow: ellipsis;
  }
}

.learning-card__label {
  color: white;
  font-weight: 600;
  font-size: 12px;
  line-height: 1.75rem;
  padding: 0.25rem 0.75rem;
  background-color: $accessible-bright-brand-color;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  top: 0.5rem;
  left: 0;
  position: absolute;
}

.learning-card__heading {
  font-weight: 700;
  font-size: 16px;
  line-height: 1.5rem;
  padding: 10px 10px 0 10px;
  height: 55px;
  overflow: hidden;
}

.learning-card__library-text {
  position: absolute;
  bottom: 0;
  color: white;
  left: 10px;
  width: 150px;
  background-image: linear-gradient(
    rgba(9, 51, 106, 0),
    rgba(3, 32, 78, 0.3),
    rgba(2, 23, 64, 1)
  );
}

.card__footer-learning {
  padding: 10px;
  height: 4rem;
  position: relative;
}

.card__action-learning {
  display: flex;
}

.learning-card__meta-info-wrapper {
  background: $mid-background-color;
  color: white;
  padding: 10px;
}
$learning-card-row-padding: 1px;

.learning-card__meta-info-module-title {
  @include line-clamp(2, 14px);
  min-height: 34px;
  margin-bottom: 5px;
  a,
  a:hover {
    text-decoration: none;
  }
}

.cards .learning-card__meta-info-module-title {
  // extra title row and taller min-height inside .cards (main posts feeds)
  // so that it matches post card size.
  @include line-clamp(3, 14px);
  min-height: 50px;
}

.learning-card__meta-info-label {
  font-weight: 700;
}

.learning-card__meta-info-series-title {
  padding: $learning-card-row-padding;
  @include line-clamp(1, 12px);
  .learning-card__meta-info-label {
    color: $bright-brand-color;
  }
}

.learning-card__meta-info-group-title {
  // the group is the publisher
  padding: $learning-card-row-padding;
  @include line-clamp(1);
}

.learning-card__author {
  @include line-clamp(1);
  margin: $learning-card-row-padding;
  font-weight: 700;
  font-size: 12px;
}

.learning-card__publisher-wrapper {
  color: white;
  height: 100%;
  padding: 10px;
  background-image: linear-gradient(
    rgba($end-gradient-color, 0),
    rgba($mid-gradient-color, 0.3),
    rgba($start-gradient-color, 0.8)
  );
  display: flex;
  align-items: flex-end;
  margin-right: 0;
  padding-right: 0;
}

.learning-card__sub-footer {
  font-size: 11.5px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  align-items: center;
}

.learning-card__body {
  height: 10px;
}

.learning-card__footer {
  max-width: 100%;
  min-height: 0 !important;
  flex-grow: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.05) !important;
  position: static;
  background: 0 0;
  width: auto;
  margin: 0 0;
  padding: 0.75em 1em;
  top: 0;
  left: 0;
  color: rgba(0, 0, 0, 0.4);
  box-shadow: none;
  transition: color 0.1s ease;
  align-self: end;
  font-size: 16px;
}

.learning-card__meta-info-star-rating {
  height: 17px;
  margin-top: 5px;
}

.learning-tbl__tr {
  &.learning-tbl__tr--even {
    background: rgba(0, 0, 0, 0.04);
  }

  td {
    padding: 6px 5px;
    @include media-query(palm) {
      &.learning-tbl__td--hide-mobile {
        display: none;
      }
    }

    &:nth-child(2) {
      width: 100%;
    }
  }
}

.learning-page {
  position: relative;
  padding: 20px 0px;
  min-height: calc(100vh);
}

.learning-page__responses-hidden-wrapper {
  padding: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.learning-page__breadcrumbs {
  padding: 10px 20px 0px;
  margin-bottom: -8px;
}

.learning-page__material-card {
  border-radius: 5px;
  margin: 20px;
  background: white;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.08), 0 10px 10px rgba(0, 0, 0, 0.11);
  @include media-query(palm) {
    margin: 0px;
    background: none;
    box-shadow: none;
  }
  p {
    font-size: $learning-page-default-font-size;
  }
}

.learning-page__material-card-header {
  padding: 20px;
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
}

.learning-page__material-card-heading {
  font-size: 22px;
}

.learning-page__material-card-body {
  overflow: hidden;
  padding: 20px;
  p,
  li {
    font-size: $large-font-size;
    font-weight: 400;
  }
  @extend .clearfix;

  @media screen and (max-width: 786px) {
    padding: 12px;
  }
}

.learning-page__material-card-footer {
  padding: 20px;
  border-top: solid 1px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  > .btn {
    margin: 0 0.5em;
  }
}

.learning-page__material-card-header-arrow-button {
  background: black;
  padding: 20px;
  align-self: stretch;
  display: flex;
  align-items: center;
  border: none;
}

.learning-page__material-card-header-arrow-button:disabled {
  background: grey;
}

.learning-page__pagination-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0px 10px;
}

$mobile-pagination-button-size: 25px;
$mobile-pagination-button-margin: 1px;
$desktop-pagination-button-size: 35px;
$desktop-pagination-button-margin: 3px;

.learning-page__pagination-button {
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $brand-color;
  color: white;
  flex-shrink: 0;

  width: $desktop-pagination-button-size;
  height: $desktop-pagination-button-size;
  margin: $desktop-pagination-button-margin;
  border-radius: 5px;
  @include media-query(palm) {
    width: $mobile-pagination-button-size;
    height: $mobile-pagination-button-size;
    margin: $mobile-pagination-button-margin;
  }
}

.learning-page__pagination-page-buttons {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  max-width: 500px;
}

.learning-page__pagination-button:focus {
  outline: 0;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.3);
}

.learning-page__pagination-button:disabled {
  background: rgba(0, 0, 0, 0.2);
}

.learning-page__pagination-button.arrow-button {
  width: $desktop-pagination-button-size;
  height: $desktop-pagination-button-size;
  margin: $desktop-pagination-button-margin;
  border-radius: 5px;
  @include media-query(palm) {
    width: $mobile-pagination-button-size;
    height: $mobile-pagination-button-size;
    margin: $mobile-pagination-button-margin;
  }
  margin: 1px;
  background: $dark-background-color !important;
  border: none !important;
}

.learning-page__pagination-button.arrow-button:disabled {
  background: rgb(124, 145, 158) !important;
}

.learning-page__pagination-button.active {
  background: none;
  border: solid 3px $brand-color;
  color: $brand-color;
}

.learning-page__pagination-ellipsis {
  background: rgba(0, 0, 0, 0.1);
  padding: 0px 1px;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.5);
  border: solid 1px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  margin: 0px 2px;
  line-height: 0.9rem;
}

.learning__fade-in {
  animation: fadeIn ease 0.5s;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-moz-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-o-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-ms-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}

.learning-page__module-info-header {
  background: $bright-brand-color;
  color: white;

  display: flex;
  align-items: center;

  padding: 5px;

  .page__container {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    min-height: $base-spacing-unit * 2;
  }

  .learning-page__module-info-header__back {
    display: flex;
    align-items: center;
    margin-right: 1em;
  }

  .learning-page__module-info-header__title-and-author {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .learning-page__module-info-header__title {
      margin-right: 10px;
      font-weight: 700;
    }

    .learning-page__module-info-header__author {
      padding: 2px;
      white-space: nowrap;
    }
  }
}

// Learning page > Feedback page
.learning-page__feedback-wrapper {
  display: flex;
  flex-wrap: nowrap;
  @include media-query(palm) {
    flex-wrap: wrap;
  }
}

.learning-page__feedback-aside {
  width: 240px;
  flex-shrink: 0;
  border-right: solid 1px rgba(0, 0, 0, 0.1);
  @include media-query(palm) {
    width: 100%;
    border-right: none;
  }
}

.learning-page__feedback-aside-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

$feedback-subcomponent-padding: 15px 18px;
.learning-page__feedback-mcqs-wrapper {
  padding: $feedback-subcomponent-padding;
}

.learning-page__feedback-aside-row {
  padding: $feedback-subcomponent-padding;
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
  font-size: 14px;

  &:last-child {
    border-bottom: none;
    @include media-query(palm) {
      border-bottom: solid 1px rgba(0, 0, 0, 0.1);
    }
  }

  @include media-query(palm) {
    width: 100%;
  }
}

.learning-page__feedback-score-bar-outer {
  height: $score-bar-height;
  margin-top: 8px;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  position: relative;
  overflow: hidden;
  @include media-query(palm) {
    display: none;
  }
}

.learning-page__feedback-score-bar-pass-mark {
  width: 2px;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  position: absolute;
}

.learning-page__feedback-score-bar-inner {
  height: $score-bar-height;
  background: $success-color;
}

.learning-page__material-card-feedback-subheading {
  color: $success-color;
  font-size: 12px;
  margin-top: 4px;
  font-weight: 700;
}

.learning-page__feedback__fixed-score-bar {
  position: fixed;
  background: white;
  top: 68px;
  width: 100%;
  z-index: 1;
  border-bottom: solid 1px rgba(0, 0, 0, 0.15);
}

.learning-page__feedback__fixed-score-bar__item-wrapper {
  padding: 8px 20px;
  @include media-query(palm) {
    &.learning-page__feedback__fixed-score-bar__item-wrapper--hide-palm {
      display: none;
    }
  }
}

// Learning blocks
.learning-block__block_quote {
  padding: 15px 2.5em;
  font-size: $learning-page-default-font-size;
  margin-bottom: 16px;
  position: relative;
  &:before {
    content: '“';
    top: -0.25em;
    left: -10px;
  }
  &:after {
    content: '”';
    bottom: -0.4em;
    right: -10px;
  }
  &:before,
  &:after {
    font-size: 100px;
    color: rgba(150, 150, 150, 0.4);
    position: absolute;
    font-family: Georgia, serif;
  }
}

.learning-block__block_quote__source {
  text-align: right;
  margin-top: 10px;
  font-size: 14px;
}

.learning-block__image {
  width: 100%;
}

.learning-block__code {
  display: inline-block;
  background: rgba(0, 0, 0, 0.1);
  color: #5a5a5a;
  border-radius: 3px;
  padding: 0px 6px;
}

$mcq-checkbox-wrapper-padding: 5px;
$mcq-checkbox-label-padding: 6px;
$mcq-checkbox-fill: #0c3569;
$mcq-checkbox-unhovered-bg: rgba(0, 0, 0, 0.1);
$mcq-checkbox-hovered-bg: rgba(0, 0, 0, 0.2);

.learning-block__mcq-wrapper {
  overflow: hidden; // this stops content from wrapping under a floated image
  margin-bottom: 15px;
  &:last-child {
    margin-bottom: 0px;
  }
}

.learning-block__mcq-question-text {
  margin-top: 0px;
  font-size: $learning-page-default-font-size;
  font-weight: bold;

  p {
    font-size: $learning-page-default-font-size !important;
    margin-bottom: 0;
  }
}

.learning-block__mcq-question-number {
  font-weight: 900;
}

.learning-block__mcq-wrapper--readonly {
  .mcq-custom-checkbox__label {
    cursor: default;
    &:hover input ~ .mcq-custom-checkbox__checkmark {
      background: $mcq-checkbox-unhovered-bg;
    }
  }
}

.mcq-results__table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  margin-bottom: 0;
  //margin-left: 3rem;
}

.mcq-results__table-responsive > .mcq-results__marks {
  font-size: 1rem;
  font-weight: 700;
  margin: 0.5rem 0;
  margin-left: 3.4rem;
}

.mcq-results__table {
  //border-collapse: collapse;
  width: 100%;
  color: #272c33;
}

.mcq-results__table-th {
  font-size: 1.2rem;
}

.mcq-results__table-row {
  margin: 0 2rem;

  td {
    padding: 0.75rem 0;
  }
}

.mcq-results__table-results {
  margin-bottom: 1rem;
}

.mcq-results__table-q-label {
  width: 38px;
  min-height: 32px;
  font-size: 1.2rem;
  color: #192a3e;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mcq-results__table-q-body {
  width: 100%;
  flex: 1 1 0%;
  margin: 0 1rem;
  position: relative;
}

.mcq-results__table-q-body-text {
  margin: 0;
  p {
    font-size: 1.2rem;
    line-height: 28px;
    margin-bottom: 0;
  }
}

.text-bold {
  p {
    font-weight: bold;
  }
}

.table-spacer {
  td {
    padding: 0.25rem 0;
  }
}

.mcq__hr {
  border: 0;
  height: 1px;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0)
  );
}

.mcq-custom-checkbox__label {
  display: block;
  position: relative;
  width: 25px;
  height: 25px;
  cursor: pointer;
  font-size: 22px;
  user-select: none;
  margin-left: 3.4rem;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .mcq-custom-checkbox__checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background: $mcq-checkbox-unhovered-bg;
    border-radius: 4px;
    border: solid 1px $mcq-checkbox-fill;
  }
  &:hover input ~ .mcq-custom-checkbox__checkmark {
    background: $mcq-checkbox-hovered-bg;
  }

  input:checked
    ~ .mcq-custom-checkbox__checkmark
    > .mcq-custom-checkbox__checkmark__inner {
    background-color: $mcq-checkbox-fill;
    width: 90%;
    height: 90%;
    border-radius: 4px;
    margin-left: 5%;
    margin-top: 5%;
  }
  .mcq-custom-checkbox__checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }
}

.learning-block__mcq-option-checkbox-wrapper {
  padding: $mcq-checkbox-wrapper-padding;
}

.learning-block__mcq-option-correct-status-wrapper {
  padding: 10px 5px;
}

.learning-block__mcq-option-wrapper {
  display: flex;
  cursor: pointer;
  flex-grow: 1;
  font-size: $learning-page-default-font-size;
}

.learning-block__mcq-option-first-child {
  padding: $mcq-checkbox-label-padding;
  font-weight: 700;
}

.learning-block__mcq-option-second-child {
  padding: $mcq-checkbox-label-padding;
  flex-grow: 1;
}

.learning-block__mcq-option-feedback {
  background: none;
}

.learning-block__mcq-option-feedback__status-wrapper {
  font-size: 14px;
  padding: 7px 10px;
  background: rgba(0, 0, 0, 0.1);
  margin: -5px 0px 0px;
  border-radius: 5px;
  color: white;
}

.learning-block__mcq-option-feedback__guess {
  font-size: 14px;
}

.learning-block__mcq-option-feedback__text {
  margin-top: 8px;
}

ul.learning-block__list,
ol {
  counter-reset: list-counter;
}

ul.learning-block__list li,
ol.learning-block__list li {
  list-style: none;
  counter-increment: list-counter;
}

ul.learning-block__list li::before {
  content: '\2022'; // 'bullet' unicode
  text-align: right;
  padding-right: 0.7em;
  vertical-align: top;
}

ol.learning-block__list li::before {
  content: counter(list-counter) '.';
  text-align: right;
  padding-right: 0.3em;
  vertical-align: top;
}

.learning-block__list p {
  margin: 0;
  display: inline;
}

.learning-block__document-gallery-wrapper {
  clear: both;
  border: solid 1px rgba(0, 0, 0, 0.1);
  padding: 10px;
  margin-bottom: 20px;
  background: rgba(0, 0, 0, 0.03);
  border-radius: 5px;
}

.learning-block__document__image-gallery-wrapper {
  border: solid 1px rgba(0, 0, 0, 0.1);
  background: white;
}

.learning-block__document__image-gallery-controls-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
  width: 100%;
  // padding: 10px;
}

$image-gallery-breakpoint: 560px;

.learning-block__document__image-gallery-page-controls-wrapper {
  display: inline-flex;
  background: rgba(0, 0, 0, 0.04);
  padding: 5px 10px;
  align-items: center;
  border-radius: 5px;
  border: solid 1px rgba(0, 0, 0, 0.1);
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;

  @media screen and (max-width: $image-gallery-breakpoint) {
    justify-content: center;
  }
}

.learning-block__document__image-gallery-arrow-controls-wrapper {
  display: flex;
  align-items: center;
  @media screen and (max-width: $image-gallery-breakpoint) {
    width: 100%;
    justify-content: center;
    padding: 5px;
  }
}

.learning-block__document__image-gallery-goto-page-wrapper {
  display: flex;
  align-items: center;
  @media screen and (max-width: $image-gallery-breakpoint) {
    width: 100%;
    justify-content: center;
    padding: 5px;
  }

  input {
    width: 50px;
    height: 30px;
    margin-left: 6px;
  }
}

.learning-block__document__image-gallery-page-controls-text {
  margin: 0px 15px;
  white-space: nowrap;
}

.learning-block__document__image-gallery-goto-page-text {
  white-space: nowrap;
}

.learning-block__document__image-gallery-button {
  border-radius: 3px;
  background: #3489db;
}

.learning-block__video-wrapper {
  clear: both;
  margin-bottom: 1em;
}

.learning-block__video-youtube__aspect-ratio-wrapper {
  clear: both;
  margin-bottom: 1em;
  position: relative;
  padding-bottom: calc(var(--aspect-ratio, 0.5625) * 100%); // 16:9 aspect-ratio
  height: 0px;
}

.learning-block__video-youtube__iframe {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}

.learning-cover__meta-info-wrapper {
  text-align: center;
  color: white;
  padding: 30px 40px 40px;
  max-width: 40em;
  background: rgba(15, 33, 63, 0.85);
  border-radius: 20px;
  box-shadow: 8px 8px 10px rgba(0, 0, 0, 0.5);
  margin: 40px 10px 40px 10px;
}

.learning-cover__meta-info-title {
  margin: 0 auto;
  font-size: 38px;
  font-weight: 700;
  max-width: 1200px;

  @include media-query(palm) {
    font-size: 27px;
  }
}

.learning-cover__background-image {
  transition: background-image 1s ease-in-out;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.learning-cover__author {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px 0px 20px;

  font-size: 20px;
  font-weight: normal;
  font-style: normal;

  a {
    color: #fff;
  }
}

.learning-cover__author-thumbnail {
  width: 30px;
  height: 30px;
  border-radius: 30px;
  margin-right: 15px;
}

.learning-cover__meta-credits {
  font-size: $learning-page-default-font-size;
  font-weight: normal;
  font-style: normal;
}

.learning-cover__mcq-info {
  margin: 0 auto;
  margin-bottom: 20px;
  max-width: 350px;
}

.learning-cover__subscription-icon {
  position: absolute;
  top: 80%;
  right: 1%;
  //margin: 1em -99.5%; // old browser fallback
  //margin: 1em calc(50% - 49.5vw);
}

.learning-page__history-heading {
  padding: 10px;
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;

  .learning-page__history-heading__text {
    font-size: 20px;
  }
}

.learning-page__history-row {
  padding: 5px 12px;
}

.learning-page__history-row-info-wrapper {
  display: flex;
  flex-wrap: wrap;

  > div {
    flex-basis: 50%;
    padding: 1px;
  }

  .learning-page__history-pass-pill {
    &.learning-page__history-pass-pill--pass {
      background: green;
    }

    background: red;
    padding: 0px 10px;
    margin-left: 7px;
    border-radius: 30px;
    display: inline-block;
    color: white;
  }
}

.learning-page__rating-likert-btns__grid-parent {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  @media screen and (max-width: 620px) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.learning-page__rating-likert-btns__grid-child {
  padding: 1px;
}

.learning-page__rating-likert-btn {
  width: 100%;
}

.learning-page__reflection-textarea-and-guidance-flex-row {
  // display: flex;
  // align-items: flex-start;
  margin-top: 20px;
}

.learning-page__reflection-guidance {
  width: 400px;
  margin-left: 10px;
  background: $off-white;

  .learning-page__reflection-guidance__heading {
    background: $bright-brand-color;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.learning-page__reflection-guidance__heading--mobile {
      cursor: pointer;
    }
  }

  div {
    padding: 10px;
  }

  &.learning-page__reflection-guidance--mobile {
    margin-left: 0px;
    width: 100%;
    margin-top: 10px;
  }
}

.learning-page__reflection-textarea {
  resize: none;
  width: 100%;
  height: 120px;
  padding: 5px;
  outline: none;
  border: solid 1px rgba(0, 0, 0, 0.2);
  &:read-only {
    outline: none;
    cursor: default;
  }
}

table.learning-page__ratings-table {
  table-layout: fixed;
  width: 100%;

  thead {
    border-bottom: solid 1px rgba(0, 0, 0, 0.1);
  }

  th:first-child,
  td:first-child {
    width: 300px;
    text-align: left;
  }

  td,
  th {
    text-align: center;
    vertical-align: middle;
    padding: 8px 3px;
  }
}

.learning-page__likert-desktop {
  display: none;
  @media screen and (min-width: 791px) {
    display: block;
  }
}

.learning-page__likert-mobile {
  display: none;
  @media screen and (max-width: 790px) {
    display: block;
  }
}

.cms-page,
.learning-page {
  .cms-document-block {
    clear: both;
    float: none;
    margin: 0 auto 1em;
    max-width: 100%;
  }

  .cms-document-block__caption {
    background-color: #f0f0f0;
  }

  @include media-query(lap-and-up) {
    .document--fill {
      float: none !important;
      img {
        display: block;
        margin: 0 auto 1em;
        max-width: 100%;
      }
    }

    .document--center {
      float: none !important;
      margin: 0 auto 1em;
      max-width: 100%;
      width: 38%;
    }

    .document--float-left {
      float: left !important;
      margin: 0 1em 1em 0;
      max-width: 38%;
    }

    .document--float-right {
      float: right !important;
      margin: 0 0 1em 1em;
      max-width: 38%;
    }
  }
}

@include media-query(lap-and-up) {
  .document--fill {
    float: none;
    img {
      display: block;
      margin: 0 auto 1em;
      max-width: 100%;
    }
  }

  .document--center {
    float: none;
    margin: 0 auto 1em;
    max-width: 100%;
    width: 38%;
  }

  .document--float-left {
    float: left;
    margin: 0 1em 1em 0;
    max-width: 38%;
  }

  .document--float-right {
    float: right;
    margin: 0 0 1em 1em;
    max-width: 38%;
  }
}

.learning-home-page__sub-nav {
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
  padding: 15px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;

  .item-right {
    flex-grow: 2;
    text-align: right;
  }

  li {
    margin-bottom: 0.5rem;
  }
}

.learning-series__header .btn--print-certificate {
  margin: 0 auto 1rem auto;
}

.learning-list__series-subscribe-cta {
  padding: 0.8rem;
  background-color: #09336a;
  transition: all 0.5s ease;
  text-align: center;
  border-top: 2px solid #4b9bef;
  position: -webkit-sticky;
  position: sticky;
  bottom: 0px;
  z-index: 100;
}

.learning-list__series-subscribe-title {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
  color: #fff;
}

.learning-list__controls {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 15px;
  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
}

.learning-list__controls__additional-buttons {
  // conditionally rendered buttons which can be passed in as prop
  margin-bottom: 15px;
}

.learning-list__controls__input-and-btn-group-wrapper {
  display: flex;
  align-self: top;
  @media screen and (max-width: 600px) {
    width: 100%;
    padding: 15px 0px;
  }
}

.learning-list__search-field {
  margin-right: 5px;
  padding: 10px 20px;
  width: 240px;
  border: solid 1px rgba(0, 0, 0, 0.1);
  @media screen and (max-width: 600px) {
    width: 100%;
  }
}

.learning-tabs__nav-item {
  text-decoration: underline !important;
  margin: 0px 15px 0px 0px;
  &.active {
    color: $inuit-base-text-color;
  }
}

.learning__breadcrumbs {
  display: flex;
  align-items: center;
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  .learning__breadcrumb {
    padding: 15px 40px;
    color: $inuit-base-text-color;
    cursor: default;
    &.learning__breadcrumb--active {
      font-weight: 700;
      color: $brand-color;
    }
    &.learning__breadcrumb--cursor-pointer {
      cursor: pointer;
    }
  }
}

.learning-cards {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
}

.learning-cards__item {
  display: flex;
  padding: 1rem;
  flex-wrap: wrap;
  flex-direction: row;
  @media (min-width: 40rem) {
    width: 33%;
  }
  @media (min-width: 56rem) {
    width: 25%;
  }
}

.learning-card {
  border-radius: 0.25rem;
  box-shadow: 0 20px 40px -14px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  &:hover {
    .card__image {
      filter: contrast(100%);
    }
  }
  height: 350px;
  margin-bottom: 1rem;
}

.learning-card__content {
  display: flex;
  //flex: 1 1 auto;
  flex-direction: column;
  padding: 1rem;
  background-color: #09336a;
  height: 150px;
}

.learning-card__content-footer {
  background-color: rgba(0, 0, 0, 0);
  padding: 1rem;
}

.learning-card__title {
  color: white;
  font-size: 1.25rem;
  font-weight: 300;
  a,
  a:hover {
    text-decoration: none;
  }
}

.learning-card__row {
  margin: 5px 10px;
  overflow: hidden;
  white-space: pre-line;
}

.learning-card__buttons {
  display: flex;
  justify-content: space-around;
  padding: 0 10px;

  @media (max-width: 398px) {
    padding-left: 5%;
    max-width: 95%;
  }

  :only-child {
    flex-grow: 1;
    width: 100%;
  }
}

.learning-card__buttons > button {
  flex: 0 1 auto;
  margin: 0 10px;
}

.learning-card__meta {
  margin: 0.5rem 0 0.5rem 0;
  color: #dededeff;
}

.learning-card__meta-label {
  font-weight: 600;
}

.learning-card__meta-star-rating {
}

.learning-card__image {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  filter: contrast(70%);
  //filter: saturate(180%);
  overflow: hidden;
  position: relative;
  transition: filter 0.5s cubic-bezier(0.43, 0.41, 0.22, 0.91);
  &::before {
    content: '';
    display: block;
    padding-top: 56.25%; // 16:9 aspect ratio
  }
  @media (min-width: 40rem) {
    &::before {
      padding-top: 66.6%; // 3:2 aspect ratio
    }
  }
  height: 200px;
}

.learning-card__publisher {
  color: white;
  background: rgb(13, 13, 13);
  background: linear-gradient(
    180deg,
    rgba(13, 13, 13, 0) 0%,
    rgba(9, 51, 106, 1) 100%
  );
}

.learning-card__footer-row {
  margin-top: 0.5rem;
}

.learning-page__material-card {
  .layout.cards {
    margin-left: -20px;

    .layout__item {
      padding-left: 20px;
    }
  }
}

.learning-item__description {
  white-space: pre-line;
  margin-bottom: 20px;

  p {
    margin-top: 0;
    margin-bottom: 0;
    white-space: pre-line;
  }

  ul li,
  ol li {
    list-style-position: inside;
  }
}

.learning-series-list .learning-item__description,
.learning-series__header .learning-item__description {
  padding: 20px 20px 20px 0;
}

.learning-cover__required-modules-not-completed__text {
  background-color: rgba(0, 0, 0, 0.5);
  padding: 15px;
  border-radius: 20px;

  margin-bottom: 20px;

  b {
    font-weight: 700;
    font-size: 1.5rem;
  }

  a {
    font-weight: 700;
    font-size: 1.5rem;
  }

  p {
    margin-top: 0;
    margin-bottom: 0;
    white-space: pre-line;
  }
}

.learning__cta-banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  min-width: 300px;
  max-width: 500px;
  margin: 20px auto;
  background-color: #09336a;
  color: #fff;
}

.learning__cta-banner h2 {
  font-size: 2rem;
  margin-bottom: 10px;
}

.learning__cta-banner h3 {
  font-size: 1.2rem;
  font-weight: 700;
  margin: 0;
}

.learning__cta-banner p {
  font-size: 1.1rem;
  text-align: center;
  margin-bottom: 20px;
}

.learning__cta-banner {
  .btn {
    display: inline-block;
    //background-color: #3498db;
    //color: #fff;
    font-size: 1rem;
    padding: 10px 20px;
    border-radius: 3px;
    text-decoration: none;
    cursor: pointer;
    border: none;
    transition: background-color 0.3s;
  }
}

.desktop-filters {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  align-items: center;
  gap: 0 1rem;
  width: 100%;
}

.mobile-filters {
  display: flex;
  flex-direction: column;
  justify-content: left;
  gap: 0 1rem;
  width: 100%;
}

.ui.form .field {
  margin-bottom: 0.25rem;
}

.ui.form .field > label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 600;
  font-size: 1rem;
}

.ui.form .field > input,
.ui.form .field > select {
  width: 100%;
  border-radius: 0.3rem;
  border: 1px solid #ddd;
  color: #000;
}

.ui.form .field > div > label {
  display: inline;
  margin-right: 1rem;
  font-size: 0.9rem;
}
