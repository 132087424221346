
.badgeable {
  position: relative;
}

.badge {
  position: absolute;
  border-radius: 100px;
  color: #fff;
  background-color: $bright-brand-color;
  text-align: center;
  transition: all 0.2s ease-out;
  white-space: nowrap;

  min-width: 18px;
  height: 18px;
  padding: 1px 4px;
  font-size: 10px;
}

.badge-scale-transition-enter {
  .badge {
    opacity: 0;
    transform: scale(0);
  }
}

.badge-scale-transition-enter.badge-scale-transition-enter-active {
  .badge {
    opacity: 1;
  }
}

.badge-scale-transition-leave {
  .badge {
    opacity: 1;
  }
}

.badge-scale-transition-leave.badge-scale-transition-leave-active {
  .badge {
    opacity: 0;
    transform: scale(0);
  }
}

.badge--wide {
  padding: 2px $base-spacing-unit;
}

.badge--seen {
  background-color: $active-button-background-color;
}

.badge--new,
.badge--urgent {
  background-color: $urgent-button-background-color;
}

