.profile-list {
  white-space: nowrap;
}

.profile-list__profile {
  margin-right: 6px;
}

.profile-list__plus-more {
  font-size: $small-font-size !important;
}
