.video-react .video-react-bezel {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 52px;
  height: 52px;
  z-index: 17;
  margin-left: -26px;
  margin-top: -26px;
  background: rgba(0,0,0,.5);
  border-radius: 26px;

  &.video-react-bezel-animation {
    -moz-animation: video-react-bezel-fadeout .5s linear 1 normal forwards;
    -webkit-animation: video-react-bezel-fadeout .5s linear 1 normal forwards;
    animation: video-react-bezel-fadeout .5s linear 1 normal forwards;
    pointer-events: none;
  }

  &.video-react-bezel-animation-alt {
    -moz-animation: video-react-bezel-fadeout-alt .5s linear 1 normal forwards;
    -webkit-animation: video-react-bezel-fadeout-alt .5s linear 1 normal forwards;
    animation: video-react-bezel-fadeout-alt .5s linear 1 normal forwards;
    pointer-events: none;
  }

  .video-react-bezel-icon {
    @extend .video-react-icon;
    width: 36px;
    height: 36px;
    margin: 8px;
    font-size: 26px;
    line-height: 36px;
    text-align: center;
  }

  .video-react-bezel-icon-play {
    @extend .video-react-icon-play-arrow;
  }

  .video-react-bezel-icon-pause {
    @extend .video-react-icon-pause;
  }

  .video-react-bezel-icon-pause {
    @extend .video-react-icon-pause;
  }

  .video-react-bezel-icon-pause {
    @extend .video-react-icon-pause;
  }

  .video-react-bezel-icon-replay-5 {
    @extend .video-react-icon-replay-5;
  }

  .video-react-bezel-icon-replay-10 {
    @extend .video-react-icon-replay-10;
  }

  .video-react-bezel-icon-replay-30 {
    @extend .video-react-icon-replay-30;
  }

  .video-react-bezel-icon-forward-5 {
    @extend .video-react-icon-forward-5;
  }

  .video-react-bezel-icon-forward-10 {
    @extend .video-react-icon-forward-10;
  }

  .video-react-bezel-icon-forward-30 {
    @extend .video-react-icon-forward-30;
  }

  .video-react-bezel-icon-volume-off {
    @extend .video-react-icon-volume-off;
  }

  .video-react-bezel-icon-volume-down {
    @extend .video-react-icon-volume-down;
  }

  .video-react-bezel-icon-volume-up {
    @extend .video-react-icon-volume-up;
  }

  .video-react-bezel-icon-fast-rewind {
    @extend .video-react-icon-fast-rewind;
  }

  .video-react-bezel-icon-fast-forward {
    @extend .video-react-icon-fast-forward;
  }

}

@keyframes :global(video-react-bezel-fadeout) {
  0% {
    opacity: 1
  }

  to {
    opacity: 0;
    transform: scale(2)
  }
}

@keyframes :global(video-react-bezel-fadeout-alt) {
  0% {
    opacity: 1
  }

  to {
    opacity: 0;
    transform: scale(2)
  }
}