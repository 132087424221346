
.profile__updates,
.updates-column {

  border-left: 1px dashed #ccc;
  padding-left: 20px;

  .cards {
    .u-1-of-1:last-child .listing__item {
      margin-bottom: 0;
    }
  }

  .load-more {
    margin-top: $base-spacing-unit;
  }
}


.updates-column {

  .load-more {
    float: right;
    text-align: right;
  }

}

.group__updates {
  padding-left: 25px;
}

