@use "sass:math";
@import '../../../style/variables';
@import '../../../style/tools';

$global-padding: $base-spacing-unit;

.listItem {
  display: inline-block;

  &.space {
    margin-bottom: $global-padding;
  }
}

.vertical {
}

.horizontal {
  &.left {
    margin-right: math.div($global-padding, 1.7);
    &:last-child {
      margin-right: 0;
    }
  }

  &.right {
    margin-left: math.div($global-padding, 1.7);
    &:first-child {
      margin-left: 0;
    }
  }

  &.center {
    margin-right: math.div($global-padding, 3);
    margin-left: math.div($global-padding, 3);

    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}
