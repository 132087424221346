@use "sass:math";
@import '../../../style/variables';
@import '../../../style/tools';

$global-padding: $base-spacing-unit;

.list {
  display: flex;
  list-style: none;
  width: 100%;
  padding: 0;
  margin: 0 0 $global-padding;
  background-color: inherit;
  color: inherit;

  // directions
  &.vertical {
    flex-direction: column;
    padding: math.div($global-padding, 2);
    height: 100%;
  }

  &.horizantal {
    flex-wrap: wrap;
  }

  // side
  &.center {
    li {
      text-align: center;
      align-self: center;
    }
  }
  &.left {
    justify-content: flex-start;
    li {
      text-align: left;
      align-self: left;
    }
  }
  &.right {
    justify-content: flex-end;
    li {
      text-align: right;
      align-self: right;
    }
  }

  &.nospace {
    margin-bottom: 0;
    padding: 0;
  }
}
