
.divider {
  display          : block;
  position         : absolute;
  background-color : $light-grey;

  // Text block
  span {
    padding          : 0.3rem 0.4rem;
    background-color : #f8f8f8;
    color            : darken($light-grey, 20%);
    position         : absolute;
    z-index          : 2;
  }
}

// modifiers
.divider--vertical {
  height : 100%;
  width  : 1px;
  span {
    margin-left : -11px;
    top         : 50%;
    transform   : translateY(-50%);
  }
}

.divider--horizontal {
  height : 1px;
  width  : 100%;
  span {
    margin-top : -13px;
    left       : 50%;
    transform  : translateX(-50%);
  }
}

.divider--left {
  left : 0;
}

.divider--right {
  right : 0;
}

.divider--top {
  top : 0;
}

.divider--bottom {
  bottom : 0;
}
