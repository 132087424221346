.auth-register {
  margin-bottom: $base-spacing-unit;
}

.auth-register .field {
  margin-bottom: $base-spacing-unit;
  max-width: 100%;
  width: 35em;
  label {
    display: block;
    margin-bottom: 5px;
  }
}

.auth-register .field--checkbox {
  display: flex;

  input {
    margin-top: 2px;
  }
  label {
    margin-left: 5px;
  }
}

.signup {
}

.signup__logo {
  width: 100%;
  max-width: 700px;
  margin: 0 auto 30px;
}

.signup__intro {
  font-weight: bold;
}

.signup__content {
  width: 100%;
  max-width: 24rem;
  margin: 0 auto;

  .btn--large {
    display: block;
    width: 100%;
  }
}
