
.icon-award-badge {
  .icon-award-badge__fg {
    fill: #fff;
  }
}

.icon-award-badge--bronze {
  .icon-award-badge__bg {
    stroke: #cd7f32;
    fill: #cd7f32;
  }
}

.icon-award-badge--silver {
  .icon-award-badge__bg {
    stroke: #C1C1C1;
    fill: #C1C1C1;
  }
}

.icon-award-badge--gold {
  .icon-award-badge__bg {
    stroke: #EFC300;
    fill: #EFC300;
  }
}

