@import '../../../style/variables';
@import '../../../style/tools';

$weights: (
  'w100': 100,
  'w200': 200,
  'w300': 300,
  'w400': 400,
  'w500': 500,
  'w600': 600,
);

.commonText {
  font-size: 92%;

  // Color modifiers
  &.default {
    color: $light-grey2;
  }

  &.dark {
    color: $mid-text-color;
  }

  &.alert {
    color: $danger-color;
  }

  a {
    color: $bright-brand-color;
    text-decoration: underline;

    &:hover {
      color: $brand-color;
    }
  }
}

@each $weightName, $weight in $weights {
  //@debug $weightName;
  .#{$weightName} {
    font-weight: $weight;
  }
}
