@import '../../../style/variables';

$sizes: (
  'xsm': 10px,
  'sm': 12px,
  'md': 14px,
  'lg': 16px,
  'xlg': 18px,
);

.display {
  display: inline-block;
}

.warning {
  @extend .display;
  color: $apply-color;
}

.error {
  @extend .display;
  color: #d0021b;
}

.dark-background .error {
  @extend .display;
  color: #fff;
}

@each $sizeName, $size in $sizes {
  .#{$sizeName} {
    font-size: $size;
  }
}
