
.module {
  background: $content-background-color;
  box-shadow: 1px 1px 0 rgba(0, 0, 0, 0.1);

  margin-bottom: $base-spacing-unit;
}

.module__header {
  padding: $half-spacing-unit $base-spacing-unit;
  border-bottom: 1px dashed rgba(0, 0, 0, 0.1);
  position: relative;
  h2, h3 {
    margin: 0;
  }
}

.module__body {
  color: $mid-text-color;
  line-height: 22px;
  padding: $base-spacing-unit;
}

.member__intro .module__body {
  padding: $base-spacing-unit $base-spacing-unit 1px;
}

@include media-query(palm) {

  .module__header {
    padding: $half-spacing-unit $base-spacing-unit;
  }

  .module__body {
    padding: $half-spacing-unit $base-spacing-unit;
  }

  .member__intro .module__header {
    padding: $half-spacing-unit 0;
  }
  .member__intro .module__body {
    padding: $half-spacing-unit 0 1px;
  }
}

