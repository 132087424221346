.adaptive {
  width: 100%;
  background-color: transparent;
}

// Shape modifiers
.original {
  border-radius: 0;
}

.square {
  border-radius: 0;
}

.rounded {
  border-radius: 6px;
}

.circle {
  border-radius: 50%;
}

.img {
  image-orientation: from-image;
}
