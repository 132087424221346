.promo-link-container {
  position: relative;
}

//@include media-query(lap-and-up) {
//
//}

.promo-scroll-container {
  //background-color : #fff;
  background-color: transparent;

  @include media-query(palm) {
    > div {
      position: relative !important;
    }
  }
}

.promo-link {
  background: #4b9bef;
  color: #fff;
  padding: 10px;
  display: block;
  position: relative;
  margin-top: 10px;
  margin-bottom: $base-spacing-unit;
  min-height: 40px;
}

.resource--group .promo-link {
  margin-bottom: $base-spacing-unit * 1.27;
}

.promo-link__img {
  min-width: 38px;
}

.promo-link__text {
  position: absolute;
  padding-left: 20px;
  padding-right: 35px;
  // left is set using inline style in template as image width depends on
  // upload width
  top: 50%;
  transform: translateY(-50%);
}

.promo-link__arrow {
  position: absolute;
  right: $base-spacing-unit;
  top: 50%;
  transform: translateY(-50%);
}

@include media-query(palm) {
  .promo-link-container .promo-link {
    transform: translateY(100%);
  }
}

.inner-shadow-effect {
  display: block;
  height: 2px;
  box-shadow: inset 2px 2px 2px 2px black;
}
