.presales__wrapper {
  clear: both;
  padding: 0px;
  margin: 1rem 0;

  display: grid;
  gap: 0px 2.5%;
  grid-template-columns: 80% 25%;
  grid-template-areas: 'presales-content presales-purchase';

  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
    grid-template-areas:
      'presales-purchase'
      'presales-content';
  }
  @media (max-width: 784px) {
    margin: 2rem;
  }
}

.presales__grid-content {
  grid-area: presales-content;
}
.presales__grid-purchase {
  grid-area: presales-purchase;
}

.presale__section {
  margin: 2rem 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 43px 38px;

  background: linear-gradient(
    180.28deg,
    rgba(255, 255, 255, 0) -34.08%,
    rgba(227, 229, 232, 0.6) 100%
  );
  border-radius: 4px;

  position: relative;
  min-width: 0;
  word-wrap: break-word;

  @media (max-width: 784px) {
    padding: 43px 1rem;
  }
}

.presales__subtitle {
  font-style: normal;
  font-weight: bold;
  font-size: 2.5rem;
  line-height: 1.2;
  letter-spacing: -1px;
  padding: 0;
  margin: 0 0 1.5rem;

  @media (max-width: 784px) {
    font-size: 2rem;
  }
}

.presales__content {
  font-style: normal;
  font-size: 1.4rem;
  line-height: 140%;
  margin-top: 0;
  margin-bottom: 1.5rem;
}

.presales__series-box-wrapper {
  display: flex;
  flex-flow: row wrap;

  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.presales__series-box-thumb {
  flex: 0 0 30%;
  overflow: hidden;
  position: relative;
  background-color: transparent;

  img {
    border: none;
    outline: none;
    max-width: 100%;
    display: block;
    width: 100%;
    max-height: 300px;

    @media (max-width: 768px) {
      max-height: 100%;
    }
  }
}

.presales__series-box-details {
  flex: 1 0;
  margin-left: 1.5rem;

  h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 1.8rem;
    line-height: unset;
    margin-bottom: 1rem;
  }

  p {
    font-size: 1.2rem;
    line-height: normal;
  }

  @media (max-width: 768px) {
    margin-top: 1rem;
    margin-left: 0;
  }
}

.presales__series-bottom-info {
  border-top: 1px solid #e7e7e7;
  margin-top: 25px;
  margin-bottom: 2rem;
  flex: 0 0 100%;
}

.presales__series-bottom-info-item {
  border-right: 1px solid #e7e7e7;
  display: inline-block;
  margin-right: 30px;
  padding-right: 30px;
  padding-top: 5px;

  h4 {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 0;
    text-transform: uppercase;
  }

  span {
    font-size: 1.2rem;
  }
}

.presales__modules-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  padding: 0;
  margin: 0;
  list-style: none;
}

.presales__modules-item {
  flex-basis: 25%;
  padding: 5px;
  margin-top: 10px;
  font-weight: bold;
  font-size: 3em;
  text-align: center;

  @media (max-width: 525px) {
    flex-basis: 100%;
  }
}

.presales__modules-card {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 240px;
  word-wrap: break-word;
  margin-bottom: 1.5rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 1rem;
  background-color: #eaebee;

  @media (max-width: 525px) {
    max-width: 400px;
    width: unset;
  }
}

.presales__modules-card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.presales__modules-card-header {
  display: flex;
  padding: 23px 20px;
  height: 125px;
  background-clip: border-box;
  border-radius: 1rem 1rem 0 0;
}

.presales__modules-card-details {
  justify-content: space-between;
  flex-direction: column;
  display: flex;
  height: 125px;
}

.presales__modules-card-title {
  display: block;
  margin: 1rem 1rem 0 1rem;

  h3 {
    font-size: 1.2rem;
    font-weight: bold;
  }
}

.presales__modules-card-price {
  font-size: 1.2rem;
  font-weight: bold;
  margin: 1rem 0;

  button {
    margin: 0 2rem;
  }
}

.presales__cart-title {
  font-size: 1.4rem;
}

.presales__cart-card {
  background: linear-gradient(
    180.28deg,
    rgba(255, 255, 255, 0) -34.08%,
    rgba(227, 229, 232, 0.6) 100%
  );
  border-radius: 20.5585px;
  padding: 1rem 2rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  margin-top: 2rem;
  position: sticky;
  top: 100px;
  max-width: 300px;

  @media (max-width: 1024px) {
    align-self: center;
    margin: 0 auto;
  }
}

.presales__table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  margin-bottom: 0;
}

.presales__table {
  border-collapse: collapse;
  width: 100%;
  color: #272c33;
  margin-bottom: 0;

  td {
    padding: 1rem 0;
  }
}

.presales__table-th {
  font-size: 1.2rem;
}

.border-top-0 {
  border-bottom: 2px solid rgba(75, 155, 239, 0.06);
  padding: 0.5rem 0.75rem;
}

.text-right {
  text-align: right !important;
}
.text-left {
  text-align: left !important;
}

.presales__basket-media {
  display: flex;
  flex-flow: row wrap;
  align-items: center !important;
}

.presales__basket-media-aside {
  display: flex;
  margin-right: 1rem;
  align-self: center !important;
  max-width: 8rem;
}

.presales__basket-media-img {
  vertical-align: middle;
  width: 100%;
  height: 100%;
  max-height: 200px;
  -o-object-fit: cover;
  object-fit: cover;
  border-style: none;
  border-radius: 0.25rem !important;
}

.presales__basket-media-body {
  flex: 1 1 0%;
  margin: 1rem;
}

.presales__basket-price {
  padding: 0.5rem 0.75rem;
}

.presales__basket-remove {
  button {
    margin-left: auto;
    margin-right: auto;
    display: block;
    border: none;

    svg {
      margin-left: auto;
      margin-right: auto;
      display: block;
      stroke: #ff0000;
    }
  }

  button:hover {
    svg {
      stroke: #000000;
    }
  }
}

.presales__discount {
  margin-top: 2rem;
}
