.carousel {
  width: 100%;
  min-height: 300px;
  align-items: center;
}

.carousel__main {
  width: 100%;
  height: 100%;
  z-index: 100;
  border-radius: 0;
  border: 0;
  background: #fff;
}

.carousel__viewport {
  width: 100%;
}

.carousel__container {
  position: relative;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -webkit-tap-highlight-color: transparent;
}

.carousel__slide {
  min-width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 300px;
  opacity: 0;
  transition: opacity 0.46s ease-in-out;
  z-index: 1;
}

.carousel__slide--selected {
  opacity: 1;
  z-index: 10;
}

.carousel__slide__inner {
  min-height: 300px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 0;
  padding: 0;
  background: transparent;
}

.carousel__slide__inner > h1,
.carousel__slide__inner > .carousel__wrap-float > h1 {
  font-size: 2.5rem !important;
  text-align: center;

  @media screen and (min-width: 49em) and (max-width: 63.9375em) {
    font-size: 2.2rem !important;
  }

  @media (max-width: 768px) {
    font-size: 2.5rem !important;
  }

  @media (max-width: 662px) {
    font-size: 2rem !important;
  }

  @media (max-width: 500px) {
    font-size: 1.5rem !important;
  }
}

.carousel__slide__inner > p,
.carousel__slide__inner .carousel__wrap-float > p {
  font-size: 1.35rem !important;
  text-align: left;

  @media screen and (min-width: 49em) and (max-width: 63.9375em) {
    font-size: 1.3rem !important;
  }

  @media (max-width: 768px) {
    font-size: 1.5rem !important;
  }

  @media (max-width: 662px) {
    font-size: 1.3rem !important;
  }

  @media (max-width: 500px) {
    font-size: 1.2rem !important;
  }
}

.carousel__slide__inner > .learning-block__list > li,
.carousel__slide__inner > .learning-block__list > li > p,
.carousel__slide__inner > .carousel__wrap-float > .learning-block__list > li,
.carousel__slide__inner
  > .carousel__wrap-float
  > .learning-block__list
  > li
  > p {
  font-size: 1.35rem !important;
  text-align: left;

  @media screen and (min-width: 49em) and (max-width: 63.9375em) {
    font-size: 1.3rem !important;
  }

  @media (max-width: 768px) {
    font-size: 1.5rem !important;
  }

  @media (max-width: 662px) {
    font-size: 1.3rem !important;
  }

  @media (max-width: 500px) {
    font-size: 1.2rem !important;
  }
}

.carousel__slide__inner > .learning-block__block_quote {
  font-size: 2.5rem;
  margin-bottom: 0;

  @media screen and (min-width: 49em) and (max-width: 63.9375em) {
    font-size: 2.2rem;
  }

  @media (max-width: 768px) {
    font-size: 2.5rem;
  }

  @media (max-width: 662px) {
    font-size: 2rem;
  }

  @media (max-width: 500px) {
    font-size: 1.5rem;
    padding: 5rem 10px;
  }
}

.carousel__slide__inner > .learning-block__block_quote:before {
  font-size: 12rem;
  color: rgba(0, 0, 0, 0.2);

  @media screen and (min-width: 49em) and (max-width: 63.9375em) {
    font-size: 10rem;
  }

  @media (max-width: 768px) {
    font-size: 8rem;
  }
}
.carousel__slide__inner > .learning-block__block_quote:after {
  display: none;
}

.carousel__slide__inner > .cms-document-block {
  margin-bottom: 0;
  max-width: 100%;
  max-height: 100%;

  @media screen and (min-width: 49em) and (max-width: 63.9375em) {
    max-width: 100%;
  }
}

.carousel__slide__inner > .carousel__wrap-float > .cms-document-block {
  max-width: 55%;
  max-height: 95%;

  @media screen and (min-width: 49em) and (max-width: 63.9375em) {
    max-width: 40%;
  }
}

.carousel__slide__inner
  > .learning-block__block_quote
  > .learning-block__block_quote__source
  > cite {
  font-size: 1.5rem;
  color: rgba(0, 0, 0, 0.4);
  font-style: normal;
}

.carousel__slide__inner > .learning-block__block_quote {
  font-style: italic;
}

.carousel__slide__inner
  > .learning-block__block_quote
  > .learning-block__block_quote__source
  > cite
  > a {
  font-size: 1.5rem;
  color: rgba(0, 0, 0, 0.4);
  font-style: normal;
}

.carousel__slide__inner > .cms-document-block > .cms-document-block__img {
  position: absolute;
  display: block;
  width: auto;
  min-height: 100%;
  min-width: 100%;
  max-width: none;
}

.carousel__slide__inner > .cms-document-block > .cms-document-block__img > img,
.carousel__slide__inner
  > .carousel__wrap-float
  > .cms-document-block
  > .cms-document-block__img
  > img {
  //max-width:60%;
  height: auto;
  background-color: transparent;
}

.carousel__slide__inner
  > .cms-document-block.document--fill
  > .cms-document-block__img
  > img {
  max-height: 100%;
  max-width: 100%;
}

.carousel__slide__inner
  > .carousel__wrap-float
  > .cms-document-block.document--float-right,
.carousel__slide__inner
  > .carousel__wrap-float
  > .cms-document-block.document--float-left {
  max-width: 40%;
}

.document--float-left {
  float: left;
}

.document--float-right {
  float: right;
}

.carousel__slide__inner
  > .carousel__wrap-float
  > .cms-document-block.document--float-right
  > .cms-document-block__img {
  max-width: 100%;

  //@media (max-width: 783px) {
  //  max-width: 40%;
  //}
}

.carousel__slide__inner
  > .carousel__wrap-float
  > .cms-document-block.document--float-left {
  //@media (max-width: 783px) {
  //  max-width: 40%;
  //}
}

.carousel__slide__inner
  > .carousel__wrap-float
  > .cms-document-block.document--float-left
  > .cms-document-block__img {
  text-align: left;
}

.carousel__slide__inner > .document--center {
  text-align: center;
  width: 100%;
}

.carousel__wrap-float {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  justify-content: center;
  overflow: hidden;

  p,
  h1,
  h2,
  h3,
  li {
    width: 40%;
  }
}

.carousel__wrap--float-right {
  flex-direction: row-reverse;
}

.carousel__button {
  outline: 0;
  cursor: pointer;
  background-color: transparent;
  touch-action: manipulation;
  z-index: 1;
  border: 0;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  fill: #999999;
  padding: 0;

  :hover {
    fill: #7b61ff;
  }
}

.carousel__button:disabled {
  cursor: default;
  opacity: 0.3;
}

.carousel__button__svg {
  width: 100%;
  height: 100%;
}

.carousel__button--prev {
  left: 27px;
}

.carousel__button--next {
  right: 27px;
}

.carousel__dots {
  display: flex;
  list-style: none;
  justify-content: center;
  padding-top: 10px;
  align-items: center;
  margin-top: 1rem;
}

.carousel__dot {
  background-color: transparent;
  cursor: pointer;
  position: relative;
  padding: 0;
  outline: 0;
  border: 0;
  margin: 1rem 10px;
  display: flex;
  align-items: center;
}

.carousel__dot:after {
  background-color: #999999;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  content: '';
}

.carousel__dot.is-selected:after {
  width: 16px;
  height: 16px;
  background-color: #7b61ff;
  opacity: 1;
}
