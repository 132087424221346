.cases-list {
  position : relative;
}

.cases-list-nav {
  margin: 15px 0;
  padding-left : 0px;

  a {
    text-decoration : underline;
    color           : $light-link-color;
  }
  .active {
    color : $base-text-color;
  }
  a:hover,
  a.active:hover {
    text-decoration : underline;
  }
}

.cases-list-nav-item {
  padding : 5px 15px 5px 0px;
  white-space: nowrap;
  display: inline-block;
}

.cases-list-nav li {
  margin-left : 2em;
}

.listing-page__title {
  margin-bottom : 0px;
}

.listing-page__header {
  position : relative;
}

.listing-page__filter-bar {
  margin-bottom : $base-spacing-unit;
}
