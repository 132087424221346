.resource-stats {
  @extend %clearfix;

  margin-bottom: $twice-spacing-unit;
}

.resource-stats__numbers {
  margin-top: 5px;
  display: block;

  color: $mid-text-color;
}

@include media-query(palm) {
  .resource-stats {
    margin: $base-spacing-unit;
  }
}

@include media-query(lap-and-up) {
  .resource-stats {
    height: 30px;

    .profile-list {
      float: left;
    }
  }

  .resource-stats__numbers {
    float: left;
  }
}

.resource-stats-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 0px 20px;
  @include media-query(lap-and-up) {
    padding-bottom: 30px;
  }
}

.resource-stats-people {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.resource-stats-item {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  position: relative;
  align-self: flex-start;
  height: 30px;
}
