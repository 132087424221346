@use "sass:math";
/*!
 * inuitcss, by @csswizardry
 *
 * github.com/inuitcss | inuitcss.com
 */
///*------------------------------------*\
//    #DEFAULTS
//\*------------------------------------*/

// These variables are inuitcss’ defaults; they should not be modified or
// adjusted directly; you should predefine the variables in your own project.

// High-level base settings.
$inuit-base-font-size: 16px !default;
$inuit-base-line-height: 24px !default;
$inuit-base-text-color: #333 !default;
$inuit-base-background-color: #fff !default;

// Namespace.
//
// Would you like inuitcss’ classes to be prepended with a namespace? If so,
// define it here.
$inuit-namespace: null !default;

// These variables are framework variables, sourced from variables defined
// above. Feel free to use these variables throughout your project, but do not
// modify or reassign them.
$inuit-base-spacing-unit: $inuit-base-line-height;
$inuit-base-spacing-unit--small: round(math.div($inuit-base-spacing-unit, 2));
$inuit-base-spacing-unit--large: round($inuit-base-spacing-unit * 2);
