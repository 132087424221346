
.dropdown {
  position: relative;
}

.dropdown__toggle {
  z-index: 100;
}

.dropdown__content {
  display: none;
  opacity: 0;
  transition: opacity .26s ease-in-out;
  position: absolute;
  top: 50%;
  right: 0;
  z-index: 1000;
  background-color: #fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  min-width: 10em;
  max-width: 100%;
  ul {
    margin: 0; padding: 0;
    list-style-type: none;
    a {
      display: block;
      padding: $half-spacing-unit $base-spacing-unit;
      color: $light-link-color;
      &:hover {
        background-color: $off-white;
        text-decoration: none;
      }
    }
  }
}

.dropdown__content:focus,
.dropdown:focus .dropdown__content,
.dropdown__content:hover,
.dropdown:hover .dropdown__content {
  display: block;
  opacity: 1;
}

