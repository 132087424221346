$page__header-height: 68px;
$page__footer-height: 68px;
$page__footer-height--mobile: 220px;
$search-bar-height: 60px;

$site-header-z-index: 150;
$off-canvas-nav-toggle-z-index: $site-header-z-index + 1;
$search-z-index: $site-header-z-index - 5;
$modal-backdrop-z-index: $site-header-z-index + 10;
$notification-z-index: $site-header-z-index + 20;
$primary-action-z-index: $site-header-z-index + 1;
$global-activity-indicator-z-index: $notification-z-index;

$job-title-margin-bottom: 4px;
