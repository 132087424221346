.body__freeze-scroll {
  overflow: hidden;
}

.page__header,
.page__footer {
  background-color: $mid-background-color;
  color: #fff;

  @extend .clearfix;

  a {
    color: $light-link-color;
  }
}

.page__header {
  width: 100%;
  height: $page__header-height;
  z-index: $site-header-z-index;
  position: fixed;
  top: 0;
  display: flex;
}

.skiptranslate + #__next {
  .page__header {
    top: 40px;
  }
}

.skiptranslate[style*='none'] + #__next {
  .page__header {
    top: 0px;
  }
}

#google_translate_element .goog-te-gadget {
  background-color: #fff;
  display: inline-block;
  padding: 0 5px;
  border-radius: 2px;
}

.page__footer {
  font-size: $small-font-size;

  padding: 17px 0;

  min-height: $page__footer-height;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}

@include media-query(lap-and-up) {
  .footer-nav__contact,
  .footer-nav__copyright {
    float: right;
  }
}

@include media-query(palm) {
  .page__footer .nav {
    max-width: 400px;
    margin: 0 auto;
    a {
      padding: 5px;
    }
  }
}

.page__body {
  min-height: 100%;
  padding: $page__header-height 0 $page__footer-height;
  background-color: $base-background-color;
}

.native-embed .page__body {
  padding: 0;
}

.page__content {
  padding-top: $base-spacing-unit;
  padding-bottom: $base-spacing-unit;
}

.anchor {
  display: block;
  position: relative;
  top: -($page__header-height + $base-spacing-unit);
  visibility: hidden;
}

@include media-query(lap-and-up) {
  .page__content {
    padding-top: 38px; // 44px - 6px to adjust for h1 line height
    padding-bottom: 44px;
  }
}

.page__content,
.page__container {
  margin: 0 auto;
}

@each $breakpoint in $breakpoint_content_sizes {
  // Grab the alias and the size from their respective locations in
  $alias: nth($breakpoint, 1);
  $width: nth($breakpoint, 2);

  @include media-query($alias) {
    .page__content--#{$alias},
    .page__container--#{$alias} {
      width: $width;
      margin: 0 auto;
    }
  }

  @include media-query($alias) {
    .page__content,
    .page__container {
      width: $width;
    }
  }
}

@include media-query(palm) {
  .page__content,
  .page__container {
    width: 100%;
  }

  .page__content {
    padding: $base-spacing-unit;
  }
}

@media print {
  .back-to-top,
  .page__header,
  .page__footer,
  .footer-navigation {
    display: none !important;
  }
  .page__body {
    padding: 0;
  }
}
