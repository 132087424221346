@use 'sass:math';

$footer-height: 74px;
$off-canvas-offset: -75%;

/* Settings */
@import 'variables';

/* Tools */
@import 'tools';

/* Generic */
@import './inuit/inuit-normalize/generic.normalize';
@import './inuit/inuit-reset/generic.reset';
@import './inuit/inuit-box-sizing/generic.box-sizing';
@import './inuit/inuit-shared/generic.shared';
@import 'generic.menu';

/* Base */
@import 'base.page';
@import './inuit/inuit-images/base.images';
@import './inuit/inuit-lists/base.lists';
@import 'base.type';
@import 'base.links';
@import 'base.lists';
@import 'base.responsive';
@import 'base.checkboxes';
@import 'base.template';
@import 'base.icons';
@import 'base.forms';

/* Objects */
@import './inuit/inuit-list-bare/objects.list-bare';
@import './inuit/inuit-list-inline/objects.list-inline';
$inuit-enable-layout--small: true;
$inuit-enable-layout--tiny: true;
@import './inuit/inuit-layout/objects.layout';
@import './inuit/inuit-media/objects.media';
@import './inuit/inuit-pack/objects.pack';
@import 'objects.buttons';
@import 'objects.nav';
@import 'objects.tables';
@import 'objects.images';
@import 'objects.activity-indicator';
@import 'objects.container';
@import 'objects.listing';
@import 'objects.module';
@import 'objects.tabs';
@import 'objects.activity';
@import 'objects.award-badges';
@import 'objects.divider';

/* Components */
@import 'components.auth-register';
@import 'components.site-header';
@import 'components.user-menu';
@import 'components.off-canvas-menu';
@import 'components.badge';
@import 'components.search-bar';
@import 'components.breadcrumb';
@import 'components.mobile-nav';
@import 'components.dropdown';
@import 'components.promo-widget';
@import 'components.social-share';
@import 'components.profile-basic';
@import 'components.card';
@import 'components.updates';
@import 'components.profile-list';
@import 'components.modal';
@import 'components.notifications';
@import 'components.dialog';
@import 'components.tag-picker';
@import 'components.tag-buttons';
@import 'components.invite-picker';
@import 'components.comments';
@import 'components.resource';
@import 'components.resource-header';
@import 'components.resource-stats';
@import 'components.poll';
@import 'components.image-browser';
@import 'components.listing-page';
@import 'components.member-page';
@import 'components.cms-page';
@import 'components.case-edit';
@import 'components.get-app';
@import 'components.react-autosuggest';
@import 'components.promo-link';
@import 'components.account-settings';
@import 'components.slideshow';
@import 'components.contacts-list';
@import 'components.learning-page';
@import 'components.interstitial-landing';
@import 'components.learning-contents';
@import 'components.presales-page';
@import 'components.docsearch-landing';
@import 'components.report-a-problem';
@import 'components.disclaimer';
@import 'components.carousel';
@import 'components.alerts';
@import 'components.posts';
@import 'components.social-signin';
@import 'components.subscriptions-page';
@import 'components.error-page';
@import 'components.saved-search-block';
@import 'components.forms';

/* Transition animations */
@import 'fx.fade';

/* Trumps */
@import './inuit/inuit-clearfix/trumps.clearfix';
@import './inuit/inuit-widths/trumps.widths';
@import './inuit/inuit-widths-responsive/trumps.widths-responsive';
@import 'trumps.align';
@import 'trumps.flush';
@import 'trumps.hide';
@import 'trumps.emphasis';
@import 'trumps.fix-webkit-autofill-color';
@import 'trumps.feed-layout';
@import 'trumps.spacing';

// PLUGINS
//@import 'react-select/scss/default.scss';

.Select--single > .Select-control .Select-value {
  line-height: 37px;
}

.Select-value-label {
  color: $light-link-color !important;
  max-width: 100px;
}

.Select-menu-outer {
  color: $light-link-color !important;
}

.Select-placeholder,
.Select--single > .Select-control .Select-value {
  line-height: 37px;
}

.Select-option {
  color: $light-link-color;

  &.is-selected {
    background-color: #dbdee0 !important;
    color: $light-link-color;
  }

  &.is-focused {
    background-color: #f2f2f2;
    color: $light-link-color;
  }

  &.is-disabled {
    color: $low-text-color;
    cursor: default;
  }
}

.Select-control {
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #c9cacc;
  color: #333;
  cursor: default;
  display: table;
  border-spacing: 0;
  border-collapse: separate;
  height: 32px;
  outline: none;
  overflow: hidden;
  position: relative;
  width: 100%;
  table-layout: fixed;
}

.Select-input > input {
  width: 100%;
  background: none transparent;
  border: 0 none;
  box-shadow: none;
  cursor: default;
  display: inline-block;
  font-family: inherit;
  font-size: inherit;
  margin: 0;
  outline: none;
  line-height: 17px;
  -webkit-appearance: none;
  max-width: 100%;
}

.Select-input {
  height: 30px;
  padding-left: 10px;
  padding-right: 10px;
  vertical-align: middle;
  max-width: 100%;
}

.Select-multi-value-wrapper {
  max-width: 100%;
  display: block;
}

// when React Modal open apply this class to body
// Plugin classname
.ReactModal__Body--open {
  overflow: hidden;
  //transform : translate3d(0, 0, 0);
  -webkit-overflow-scrolling: auto;
  overflow-scrolling: auto;
  position: fixed;
  width: 100%;
  height: 100%;
}

// General Custome styles
.globalWrapper {
  min-height: 100%;
  margin-bottom: -$footer-height;
  //overflow-y    : auto;

  &:after {
    display: block;
    height: $footer-height;
  }

  @include media-query(palm) {
    $footer-height: 88.56px;
    margin-bottom: -$footer-height;
    &:after {
      height: $footer-height;
    }
  }
}

.fullH {
  height: 100%;

  > div {
    height: 100%;
  }
}

.long-header {
  margin-bottom: math.div($base-spacing-unit, 2);
  line-height: 1.3;
  font-size: 1.4rem !important;
  font-weight: 400;
  letter-spacing: 1px;

  small {
    font-weight: 100;
    font-size: 80%;
  }

  &.light {
    font-weight: 200;
  }
}

.relative {
  position: relative;
}

.fixed-260 {
  max-width: 265px;

  @include media-query(palm) {
    max-width: 369px;
  }
}

.small-fixed265 {
  @include media-query(palm) {
    max-width: 369px;
  }
}

[placeholder] {
  text-overflow: ellipsis;
}

.noBorder_last_child {
  &:last-child {
    border: 0 !important;
  }
}

.tooltip {
  pointer-events: auto !important;
  background-color: $modal-backdrop-color !important;
  border-color: $modal-backdrop-color !important;
  padding: 8px 10px !important;
  //left             : -999em !important;
  border-radius: 0 !important;
  -webkit-font-smoothing: subpixel-antialiased !important;
  font-size: 14px;
  //font-weight            : 400;

  &.show {
    opacity: 1 !important;
  }

  &:after {
    border-top-color: $modal-backdrop-color !important;
    border-bottom-color: $modal-backdrop-color !important;
  }

  &.place-right,
  &.place-left {
    &:after {
      border-color: transparent $modal-backdrop-color !important;
    }
  }

  &.place-top,
  &.place-bottom {
    &:after {
      border-color: $modal-backdrop-color transparent !important;
    }
  }

  &.place-top {
    &:before {
      bottom: -8px !important;
      left: 13px !important;
      margin-left: 0 !important;
    }

    &:after {
      bottom: -6px !important;
      left: 13px !important;
      margin-left: -8px !important;
    }
  }
}

//  Todo experimental
img {
  font-size: 0;
  background-color: $off-white;
  display: inline-block;
}

.light-link {
  color: $light-link-color;
  text-decoration: underline;
}

.card--title {
  font-weight: 500;
  color: $mid-text-color;
  margin-bottom: 15px;
}

.multiline {
  //vertical-align : middle;
  .top {
    display: block;
    line-height: 1;
    text-align: right;
  }

  .down {
    display: block;
    line-height: 1;
    text-align: right;
  }
}

.multiline2 {
  @include media-query(portable) {
    .top {
      display: block;
      line-height: 1;
      text-align: right;
    }
    .down {
      display: block;
      line-height: 1;
      text-align: right;
    }
  }
}

.singleLine {
  @include media-query(portable) {
    top: -3px !important;
    position: relative;
  }
}

.ml-0 {
  margin-left: 0 !important;
}

.ml-6 {
  margin-left: 6px !important;
}

.ml-7 {
  margin-left: 7px !important;
}

.noAction {
  text-decoration: none !important;
  color: $inuit-base-text-color !important;
  cursor: default !important;

  &:hover {
    text-decoration: none !important;
    color: $inuit-base-text-color !important;
    cursor: default !important;
  }
}

.noActionDark {
  text-decoration: none !important;
  color: #dedede !important;
  cursor: default !important;

  &:hover {
    text-decoration: none !important;
    color: #dedede !important;
    cursor: default !important;
  }

  &.noOpa {
    opacity: 1 !important;
  }
}

.ad_image {
  width: 100% !important;
}

.displayNone {
  display: none;
}

.infoTitle {
  font-size: inherit;
  font-weight: 400;
  margin-bottom: 5px;
}

// FOOTER
.footer-navigation {
  display: block;
  color: #fff;
  background-color: $mid-background-color;
  position: relative;
  z-index: 20;
  min-height: 70px;
  transition: transform 0.2s;

  @include media-query(palm) {
    min-height: 88.56px;
  }
}

.with-main-menu .footer-navigation {
  transform: translateX($off-canvas-offset);
  transition: transform 0.26s ease-in-out;
}

.footer-Block {
  display: block;
  padding: $base-spacing-unit - 6 0 $base-spacing-unit - 6;

  @include media-query(palm) {
    padding: math.div($base-spacing-unit, 1.4) math.div($base-spacing-unit, 2) !important;
  }

  &-menu {
    display: block;
    list-style: none;
    padding: 0;
    margin: 0;
    color: $low-text-color;
    font-size: 85%;

    .footer-menu-item {
      > span {
        padding: 10px;
        display: inline-block;
      }

      @include media-query(lap-and-up) {
        display: inline-block;

        &.footer-menu-item--legal {
          float: right;
        }
        &:first-child {
          a {
            padding-left: 0;
          }
        }
        &:last-child {
          a,
          span {
            padding-right: 0;
          }
        }
      }

      @include media-query(palm) {
        display: block;

        &:nth-child(8) {
          order: -1;
          margin-bottom: 15px;

          a {
            color: $light-link-color;
          }
        }
      }
    }

    a {
      color: $off-white;
      padding: 10px;
      display: inline-block;
    }

    @include media-query(palm) {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      align-items: left;
      align-content: left;
      flex-direction: column;

      a,
      span {
        font-size: 110%;
        padding: 6px;
      }
    }
  }

  &-copyright {
    text-align: right;
    font-size: 85%;
    padding: 0 7px;
    color: #aaa;
    margin-bottom: 0;

    @include media-query(palm) {
      font-size: 95%;
      margin-top: 18px;
      text-align: left;
      margin-bottom: 15px;
    }
  }
}

table.privacy th,
table.privacy td {
  vertical-align: top;
  padding: 0 0.5em 0.5em 0;
}

.btn--link--underline {
  text-decoration: underline;
}

.strong-p {
  font-weight: 400;
}

.spinnerLoad {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -25px;
  width: 50px;
  height: 50px;
  display: block;
  z-index: 999;
  display: block;
  background-image: url('/static/media/images/wrap_m.png');
}

.oldSmartBtn {
  //padding : 10px;
  svg,
  .icon-group {
    margin-left: 0.5em !important;
    margin-bottom: -2.2px !important;
  }
}

.field--groupTitle {
  margin-bottom: 20px !important;
}

//.field--groupDesc, .field--groupTitle {
//  margin-bottom : 20px !important;
//}

.create_title {
  > label {
    font-weight: 300 !important;
    font-size: 1.71429rem;
    line-height: 1.46;
    margin-bottom: 10px;
  }

  input {
    @include placeholder {
      color: $low-text-color !important;
    }
  }
}

.flex-checkbox-container {
  position: relative;

  input {
    visibility: hidden;
    opacity: 0;
    position: absolute;
  }
}

.flex-checkbox-label {
  display: flex;
  cursor: pointer;
  align-items: center;

  &.right {
    justify-content: flex-start;
  }

  &.left {
    justify-content: flex-end;
  }
}

.flex-checkbox-label-text {
  font-weight: 300;
  color: $light-link-color;

  &.checked {
    color: darken($inuit-base-text-color, 10);
  }

  &.right {
    margin-right: 10px;
  }

  &.left {
    margin-left: 10px;
  }
}

.landing-page__learning-video .video__play {
  top: auto;
  bottom: 20px;
  left: 20px;
  transform: none;
}
.landing-page__learning-video .video-react-has-started .video__play {
  display: none;
}

.cc-window {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 24em;
  z-index: 100;
  padding: 15px 20px 20px;
  max-width: 100%;
  background-color: #eee;
  border-radius: 4px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
  color: black;
}

.cc-window__inner {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  button {
    white-space: nowrap;
  }
  p {
    flex-grow: 1;
    margin: 0 20px 0 0;
  }
  a {
    color: $brand-color;
  }
  .btn {
    background-color: $brand-color;
    color: white;
    padding: 5px 20px;
  }
}

@include media-query(palm) {
  .cc-window {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
}
