.activity-indicator {
  opacity: 0.6;
  margin-bottom: $base-spacing-unit;
}

.global-activity-indicator {
  position: fixed;
  bottom: $base-spacing-unit;
  z-index: $global-activity-indicator-z-index;
  left: 50%;
  transform: translateX(-50%);
  height: 2em;
  pointer-events: none;
  transition: all 0.16s ease-in-out;
}

.global-activity-indicator__content {
  position: absolute;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
  padding: $half-spacing-unit $base-spacing-unit;
  border-radius: 3px;
  font-size: $small-font-size;
  line-height: 18px;
  span,
  .icon {
    display: inline-block;
    vertical-align: middle;
  }
  .icon {
    fill: #fff;
    margin-left: $half-spacing-unit;
  }
}

@include media-query(palm) {
  .global-activity-indicator__content {
    right: $base-spacing-unit;
  }
}

.icon-connecting {
  animation: icon-spinner-spin 1.7s infinite linear;
  fill: $brand-color;
}

.spinner {
  animation: icon-spinner-spin 1.7s infinite linear;

  // Modifier
  &.light {
    fill: $brand-color;
  }
  &.dark {
    fill: rgba(255, 255, 255, 0.6);
  }
}

.spinner--centered {
  position: absolute;
  top: 50%;
  left: 50%;
  animation: icon-spinner-spin--centered 1.7s infinite linear;
}

@keyframes icon-spinner-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}

@keyframes :global(icon-spinner-spin) {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}

@keyframes icon-spinner-spin--centered {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(359deg);
  }
}

@keyframes :global(icon-spinner-spin--centered) {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(359deg);
  }
}
