.page__header-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@include media-query(palm) {
  .page__header-inner {
    width: 100% !important;
  }
}

@include media-query(lap) {
  .page__header-inner {
    width: 100% !important;
    padding: 0 20px;
  }
}

.page__header__logo {
  flex-grow: 2;
  height: 38px;
}

.page__header--docsearch .page__header__logo {
  height: 50px;
}

.page__header__signin {
  margin-right: 20px;
  text-align: right;

  font-weight: 400;
  .btn {
    font-weight: 400;
  }
}

@media (min-width: 600px) {
  .page__header__already-member {
    margin-bottom: 0.25em;
    display: inline-block;
  }
  .page__header__already-member + .btn {
    margin-left: 0.5em;
  }
}

@media (max-width: 599px) {
  .page__header__already-member {
    display: none;
  }
}

@include media-query(lap-and-up) {
  .page__header .badge {
    top: -12px;
    right: -12px;
    border: 1px solid #fff;
    min-width: 17px;
    height: 17px;
  }
  .site-logo {
    float: left;
  }

  .page__header .user-menu__item--profile .badge {
    right: 4px;
    top: -6px;
  }
}

.site-logo__link {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  svg {
    margin-right: 8px;
  }
}

.current-specialties {
  float: left;
}

.current-specialties__label {
  max-width: 16em;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

@include media-query(palm) {
  .site-logo {
    margin-left: $base-spacing-unit;
  }

  .current-specialties {
    position: absolute;
    left: $base-spacing-unit + $base-spacing-unit + 35px;
    top: $base-spacing-unit - 2;
  }
}

.ghost-banner {
  height: 60px !important;
  position: sticky;
  top: 68px;
  z-index: 100;
  padding: 11px 0;
  border: none;
  background-color: #a64323;
}

.ghost-banner__inner {
  align-items: center;
  color: white;
  font-size: 2em;
  text-align: center;

  span {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial,
      sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol' !important;
  }
}
