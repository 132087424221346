@import '../../../style/variables';
@import '../../../style/foundation/mixins';

.flexContainer {
  display: flex;
  justify-content: flex-end;
  max-width: 100%;
  align-items: center;
}

.linkText {
  display: inline-block;
  color: $light-link-color;
  text-decoration: underline;
  margin-right: 3px;
  min-width: 100px;
  text-align: right;
  cursor: pointer;
  font-size: 0.95em;
  line-height: 1.1em;
  //font-weight             : 400;
  //-webkit-font-smoothing  : antialiased;
  //-moz-osx-font-smoothing : grayscale;
}

.btn {
  width: 29px !important;
  min-width: 29px !important;
  height: 28px !important;
  padding: 0 !important;
  margin: 0 !important;
}

.icon {
  margin-left: 0 !important;
  margin-bottom: -2px !important;
}
