.image--profile-small,
.image--profile-thumb {
  border-radius: 50%;
  background-color: transparent;
}

.image--profile-small {
  min-width: 30px;
  min-height: 30px;
}

.image--profile-thumb {
  min-width: 60px;
  min-height: 60px;
  img {
    min-width: 60px;
    min-height: 60px;
  }
}

.image--group-profile {
  border-radius: 0;
}

.card-row__image,
.image--profile-thumb {
  width: 60px;
  height: 60px;
}

.image-placeholder--thumb {
  width: 60px;
  height: 60px;
  background-color: #e2e2e2;
  position: relative;
  svg {
    @extend .center-both;
  }
}
