$inuit-base-text-color: #314559;

$color-core-brand-first-blue-100: rgb(4, 0, 87);
$color-core-brand-first-blue-60: rgb(86, 81, 126);

$color-core-brand-second-blue-100: rgb(4, 99, 213);
$color-core-brand-second-blue-60: rgb(54, 130, 221);

$color-core-brand-supporting-light-blue-100: rgb(101, 154, 255);
$color-core-brand-supporting-light-blue-20: rgb(224, 235, 255);
$color-core-brand-supporting-light-blue-40: rgb(193, 215, 255);
$color-core-brand-supporting-light-blue-60: rgb(163, 194, 255);
$color-core-brand-supporting-light-blue-80: rgb(132, 174, 255);

$color-core-brand-accent-100: rgb(76, 167, 157);
$color-core-brand-accent-20: rgb(204, 249, 246);
$color-core-brand-accent-60: rgb(1, 227, 210);

$base-foreground-color: $inuit-base-text-color;
$base-background-color: #f8f8f8;
$content-background-color: #fff;

$base-accent-color: #1b6ebf;
$brand-color: #1b6ebf;
$bright-brand-color: #4b9bef;
$accessible-bright-brand-color: #3178c4;

$mid-text-color: #6f6f6f;
$low-text-color: #999;

$apply-color: #f90;
$danger-color: #d0021b;
$success-color: #1dbf97;

$link-color: #1b6ebf;

$light-link-color: #4b9bef;

$off-white: #f2f2f2;
$light-grey: #dbdee0;
$light-grey3: #e2e2e2;
$light-grey2: #bbc2c9;

$dark-background-color: #021740;
$mid-background-color: #09336a;

$start-gradient-color: #021740;
$mid-gradient-color: #03204e;
$end-gradient-color: #09336a;

$footer-background-color: #021740;

$modal-backdrop-color: rgba(#314559, 0.95);
$modal-backdrop-color--palm: rgba(#314559, 0.5);
$modal-header-background-color: $dark-background-color;

$active-button-background-color: #314559;
$dark-button-background-color: #314559;
$standard-button-background-color: $brand-color;
$danger-button-background-color: $danger-color;
$urgent-button-background-color: $danger-color;

$email-color: #4483c7;
