$brand-face: 'Helvetica Neue', Arial, 'Lucida Grande', sans-serif;

$inuit-base-font-size: 14px;
$inuit-base-line-height: $inuit-base-font-size * 1.25;

$mega-heading-size: 26px !default;

$inuit-heading-size-1: 24px !default;
$inuit-heading-size-2: 22px !default;
$inuit-heading-size-3: 20px !default;
$inuit-heading-size-4: 18px !default;
$inuit-heading-size-5: 16px !default;
$inuit-heading-size-6: 14px !default;

$inuit-heading-size-1--palm: 24px !default;
$inuit-heading-size-2--palm: 22px !default;
$inuit-heading-size-3--palm: 20px !default;
$inuit-heading-size-4--palm: 18px !default;
$inuit-heading-size-5--palm: 16px !default;
$inuit-heading-size-6--palm: 14px !default;

$base-font-size: $inuit-base-font-size;
$large-font-size: $base-font-size + 2;
$small-font-size: $base-font-size - 2;
$mini-font-size: $small-font-size - 2;
