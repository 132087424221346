@import '../../../style/variables';
@import '../../../style/foundation/mixins';

.btn {
  padding: 8px 12px;
  background-color: transparent;
  outline: 0;
  border: 0;
  transition: all 0.3s ease 100ms;
  //font-weight             : 400;
  //-webkit-font-smoothing  : antialiased;
  //-moz-osx-font-smoothing : grayscale;

  svg {
    fill: $bright-brand-color;
  }

  // special style
  &.socialButtons {
    width: 28px;
    height: 28px;
    position: relative;

    svg {
      @include centerer(true, true);
    }
  }

  // color modifiers
  &.dark {
    box-shadow: 0 0 0 1.8px $bright-brand-color;

    svg {
      fill: $bright-brand-color;
    }
  }

  &.light {
    box-shadow: 0 0 0 1.8px white;

    svg {
      fill: white;
    }
  }

  // Shape modifier
  &.circle {
    border-radius: 50%;
  }

  &.square {
    border-radius: 0;
  }

  &.rounded {
    border-radius: 4px;
  }

  // hover and focus satate

  &:hover {
    &.dark {
      box-shadow: 0 0 0 3.2px $bright-brand-color;
    }

    &.light {
      box-shadow: 0 0 0 3.2px white;
    }
  }
}
