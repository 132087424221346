@use "sass:math";
@import '../../../style/variables';
@import '../../../style/tools';

$global-padding: $base-spacing-unit;

.container {
  list-style: none;
  padding: 0 1em;
  margin: 0;

  li {
    margin-bottom: $global-padding;

    &:before {
      content: '•';
      color: $inuit-base-text-color;
      display: inline-block;
      width: 1.3em;
      margin-left: -1.3em;
      font-size: 70%;
    }
  }

  &.space {
    margin-bottom: $global-padding;
  }

  &.dark {
    list-style: disc inside none;
    padding: 0;

    li {
      &:before {
        display: none;
      }
    }
  }

  //&.checked {
  //  li {
  //    &:before {
  //      height            : 13px !important;
  //      width             : 18px !important;
  //      margin-bottom     : -1px !important;
  //      margin-left       : -18px !important;
  //      content           : '' !important;
  //      background-repeat : no-repeat;
  //      background-image  : url("/static/media/svg/checkmark.svg");
  //    }
  //  }
  //}
}
