.breadcrumb {
  float: left;
  margin-top: 5px;
}

.breadcrumb__icon,
.breadcrumb__label {
  display: block;
  float: left;
}

.breadcrumb__label {
  margin-left: $base-spacing-unit;
  margin-top: 1px;
}
