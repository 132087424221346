
.dialog {
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: $base-spacing-unit;
  right: $base-spacing-unit;
}

.dialog__text {
  margin-bottom: 3 * $base-spacing-unit;
}

.dialog__button-list {
  width: 70%;
  margin: 0 auto;
}

.dialog__button-row {
  margin-bottom: $base-spacing-unit;

  button {
    width: 100%;
  }

  p {
    margin: 5px 0 25px;
    font-size: $small-font-size;
    color: #6f6f6f;
  }
}

