.resource__header,
.profile-header {
  background-color: $start-gradient-color;
  background-image: linear-gradient($start-gradient-color, $end-gradient-color);
  color: #fff;
  padding: $base-spacing-unit 0;

  .page__container {
    position: relative;
  }

  .media {
    position: relative;
  }

  .media__img {
    height: 80px;
    width: 80px;
    background-color: transparent;
  }
}

@include media-query(lap-and-up) {
  .profile-header .page__container {
    display: flex;
    .profile-basic {
      flex-grow: 1;
    }
  }
}

.profile-header--custom {
  color: #fff;

  .profile-header__inner {
    display: flex;
    justify-content: space-between;
  }

  .profile-header__buttons {
    .btn {
      min-width: 12em;
      text-align: center;
      margin: 5px 0;
    }
  }
}

@include media-query(palm-landscape-and-up) {
  .profile-header--custom {
    .profile-header__custom-image {
      width: 50%;
      border-bottom: 1px solid #ccc;
    }

    .profile-header__content {
      width: 50%;
      text-align: center;
    }

    .profile-header__buttons {
      margin: 20px 0;
    }

    .profile-header__share-buttons {
      margin: 30px 0;
    }
  }
}

@include media-query(palm-portrait) {
  .profile-header--custom {
    .profile-header__inner {
      flex-direction: column;
    }

    .profile-header__content {
      padding: 10px 20px 20px;
    }
  }
}

.resource-header__inner {
  position: relative;
}

@include media-query(palm) {
  .resource__header {
    .media__img {
      height: 60px;
      width: 60px;
    }

    .resource__creator.media--large .media__img {
      height: 50px;
      width: 50px;
    }
    .resource__creator.media--large .media__body {
      left: 60px;
    }
  }
}

.profile-header {
  .profile__name {
    margin-bottom: 8px;
    @include line-clamp(2, $mega-heading-size, $mega-heading-size + 5);

    .icon-editor-badge {
      position: relative;
      top: 3px;
      margin-left: 20px;
    }

    .icon-award-badge {
      position: relative;
      top: 4px;
    }

    .icon-editor-badge__bg {
      stroke: #fff;
      stroke-width: 1;
      fill: #fff;
    }

    .icon-editor-badge__fg {
      fill: #1b6ebf;
    }
  }

  .profile__name-text {
    margin-right: 5px;
  }

  .profile__job-title,
  .profile__institution,
  .profile__strapline p {
    font-size: $large-font-size;
    margin-bottom: 2px;
  }

  .profile__institution {
    font-weight: 100;
  }

  .profile__img {
    background-color: transparent;
  }
}

@include media-query(palm) {
  .profile-header {
    .profile__name {
      @include line-clamp(5, $mega-heading-size, $mega-heading-size + 5);
    }
  }

  .profile-header .profile__institution {
    font-weight: 200;
  }
}

.profile-header--user {
  .profile__name {
    @extend .clearfix;
  }
}

.profile-header__edit {
  margin-top: $base-spacing-unit;
}

.profile-header__stats {
  margin-bottom: $base-spacing-unit;
  li {
    margin-bottom: $half-spacing-unit;
  }
}

@include media-query(palm) {
  .profile-header__stats-group-count {
    float: left;
    margin-right: $half-spacing-unit;
    padding-right: $half-spacing-unit;
    border-right: 1px solid;
  }
}

.profile-header--full {
  .media {
    min-height: 120px;
  }

  .media__img {
    height: 120px;
    width: 120px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .profile-basic {
    .media__body {
      left: 120px + $base-spacing-unit;
    }
  }
}

.profile-header--compact {
  .profile__name {
    font-size: $inuit-heading-size-4;
    @include line-clamp(2, $inuit-heading-size-4, $inuit-heading-size-4 + 5);
    a {
      color: #ccc;
    }
  }

  .profile__job-title,
  .profile__institution,
  .profile__strapline p {
    font-size: $base-font-size;
  }
}

.resource-header {
  .profile-basic__name {
    font-size: $inuit-heading-size-4;
    line-height: $inuit-heading-size-4 * 1.25;
    font-weight: 200;
    color: #ccc;
    &:hover {
      text-decoration: none;
    }
  }

  .profile-basic__job-title,
  .profile-basic__institution {
    color: #fff;
  }
}

.resource__creator {
  .profile-basic__name {
    font-weight: 200;
    margin-bottom: 8px;
    color: #fff;
  }
  .profile-basic__job-title,
  .profile-basic__institution {
    font-size: $base-font-size;
    font-weight: 200;
    line-height: 1.25;
    margin-bottom: 2px;
    color: #ccc;
  }
  .profile-basic__institution {
    font-weight: 200;
  }
}

@include media-query(palm) {
  .resource-header {
    .profile-basic__name {
      font-size: $inuit-base-font-size;
      line-height: $inuit-base-font-size * 1.25;
      margin-bottom: 5px;
    }
    .profile-basic__job-title,
    .profile-basic__institution {
      font-size: $small-font-size;
    }

    .resource__sharing-tools {
      display: none;
    }
  }

  .resource__tags strong {
    font-weight: 300;
  }
  .resource__tags span {
    font-weight: 200;
  }
}

.resource-header__profile {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  margin-bottom: 30px;
  padding-bottom: $base-spacing-unit;
}

.resource-tags {
  font-size: $large-font-size;
  line-height: 1.4 * $large-font-size;
  transition: max-height 0.26s ease-in-out;
  color: #ccc;

  .icon-arrow-up {
    transition: transform 0.26s ease-in-out;
    transform: rotateX(0);
  }
}

.profile-header--group {
  .resource-tags {
    margin-bottom: $base-spacing-unit;
  }
}

.resource-tags--collapsed {
  max-height: 2 * 1.4 * $large-font-size;
  overflow: hidden;

  .icon-arrow-up {
    transform: rotateX(180deg);
  }
}

.resource__header--edit {
  .resource-tags {
    font-size: $base-font-size;
  }

  .resource-tags--collapsed {
    max-height: 2 * (1.2 * $large-font-size + $base-spacing-unit) + 1;
  }
}

.resource-tags__disclosure {
  float: right;
  font-size: $base-font-size;
}

.resource__engage-tools {
  .btn {
    min-width: 138px;
  }

  li {
    margin: 0 0 12px;
  }
}

@include media-query(palm) {
  .resource__engage-tools {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: $half-spacing-unit 0 0;
    li {
      margin: 0 10px 0 0;
    }
    li:last-child {
      margin-right: 0;
    }
  }
  .resource--editable .resource__engage-tools {
    margin-bottom: $half-spacing-unit;
  }
}

@include media-query(lap-and-up) {
  .resource__engage-tools {
    li:last-child {
      margin-bottom: 0;
    }
  }
}

.resource-header__detail {
  position: relative;
}

.resource-header__detail--has-tools {
  @extend .clearfix;
}

.resource-header__detail--has-previous {
  padding-left: 32px;
}

.resource-header__detail--has-next {
  padding-right: 32px;
}

@include media-query(lap-and-up) {
  .resource--case .resource-header__profile,
  .resource--editable .resource-header__detail--has-tools {
    margin-bottom: $base-spacing-unit;
  }

  .resource--case .resource-header__profile {
    display: flex;
    .resource__creator-wrapper {
      flex-grow: 1;
    }
  }

  .resource__sharing-tools {
    padding-top: 12px;
  }

  .resource__engage-tools {
    float: right;
    margin-left: $base-spacing-unit;
    text-align: right;
  }
}

.resource-header__detail h1 {
  margin-bottom: 11px;
  margin-top: -4px;
  line-height: 1.2;
}

.resource__header-edit-tools {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  padding-top: $base-spacing-unit;
  min-height: 60px;

  .action-link--edit {
    margin-left: $half-spacing-unit;
  }
}

@include media-query(palm) {
  .resource-header--has-files {
    .resource__header-edit-tool {
      border-top: 1px solid rgba(255, 255, 255, 0.2);
      padding-top: $base-spacing-unit;
      margin-top: $base-spacing-unit;
    }
  }
}

.resource__header--with-tabs {
  padding-bottom: 0;
}

.resource__header--edit {
  padding-bottom: 0;

  .media .media__body {
    right: 0;
    padding-bottom: 3em;
  }

  .btn--save {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}

@include media-query(lap-and-up) {
  .resource__header--edit {
    .profile__change-photo {
      position: absolute;
      bottom: 5px;
      left: 0;
    }
  }
}

.profile-header__actions,
.profile-header__action {
  @include center-vertically;
  right: 0;
}
.profile-header__actions li {
  padding: 5px 0;
  text-align: right;
}

.profile-header--full .profile-header__inner--has-sibling {
  position: relative;
  margin-bottom: $base-spacing-unit;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

@include media-query(palm) {
  .resource__header,
  .profile-header {
    > .page__container {
      padding: $half-spacing-unit $base-spacing-unit 0;
    }
  }

  .profile-header {
    .media__img {
      display: block;
      float: none;
      margin: 0 auto $base-spacing-unit;
      position: static;
      transform: none;
    }

    .media__body {
      overflow: visible;
      position: static;
      transform: none;
      margin-top: $base-spacing-unit;
    }

    .profile-basic {
      padding-bottom: $base-spacing-unit;
    }
  }

  .profile__social-share {
    display: none;
  }
}

.resource__visibility {
  margin-bottom: $half-spacing-unit;
  font-weight: 100;
}

@include media-query(palm) {
  .resource__visibility {
    font-weight: 200;
  }
}

.resource__visibility-value,
.resource__recommended-purpose {
  font-weight: 200;
}

.resource__recommended-purpose {
  margin: $half-spacing-unit 0 0;
  color: #ccc;
}

@include media-query(lap-and-up) {
  .resource__social-share {
    float: right;
  }

  .resource__header {
    .social-share__icon {
      margin-left: 10px;
      margin-right: 0;
    }

    > .page__container {
      &:after {
        content: '';
        display: table;
        clear: both;
      }
    }
  }

  .resource__header,
  .profile-header {
    .media__body {
      @include center-vertically;
      left: 80px + $base-spacing-unit;
    }
  }

  .modal-page--editor {
    .profile-header,
    .resource-header {
      .profile-basic {
        padding-right: 0;
      }
    }
  }

  .resource__visibility {
    margin-bottom: 15px;
    text-align: right;
  }

  .profile-header--full {
    .profile-basic {
      margin-bottom: $base-spacing-unit;
    }
  }

  .profile-header--group {
    .profile-header__inner {
      .profile-header__buttons {
        text-align: center;
        > button {
          margin-bottom: 15px;
        }
      }
    }

    .profile-header__sub {
      .profile-header__buttons {
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        justify-content: flex-end;

        > button,
        ul,
        .profile-header__private {
          margin-left: 15px;
          &:first-child {
            margin-left: 0;
          }
        }
      }
    }
  }

  .profile-header__sub {
    display: flex;
  }

  .profile-header--user {
    .profile-header__buttons {
      align-self: center;
    }
  }
}

@include media-query(palm) {
  .resource__header--edit {
    padding-bottom: $base-spacing-unit;

    .profile-basic,
    .media .media__body {
      padding-bottom: 0;
    }

    .profile__change-photo {
      display: block;
      margin-top: $base-spacing-unit;
    }
  }

  .profile-header__sub {
    display: block;
  }
  .profile-header__buttons {
    .btn {
      margin-right: 15px;
      margin-bottom: 15px;
    }
  }
}

@include media-query(palm-landscape) {
  .profile-header__button-groups {
    display: flex;
  }
  .profile-header__buttons--manage {
    text-align: right;
  }
}

.resource-header--has-previous > .page__container {
  padding-left: 32px;
}

.resource-header--has-next > .page__container {
  padding-right: 32px;
}

.resource-header__previous,
.resource-header__next {
  position: absolute;
}

.page__container .resource-header__previous,
.page__container .resource-header__next {
  @include center-vertically;
}

.resource-header__detail .resource-header__previous,
.resource-header__detail .resource-header__next {
  top: 15px;
}

.profile-header .resource-header__previous,
.profile-header .resource-header__next {
  padding-bottom: $base-spacing-unit;
}

.resource-header__previous {
  left: 0;
}

.resource-header__next {
  right: 0;
}

.profile__strapline {
  font-weight: 200;
}

.profile-header__private {
  max-width: 11em;
}

.profile-header .media__img.image--m-group-large {
  height: auto !important;
}
