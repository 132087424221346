
@mixin responsive-page-width($width: $page-max-width, $padding: $base-spacing-unit) {
  max-width: $width;
  width: 100%;
  margin: 0 auto;
  @if ($padding > 0) {
    padding-left: $padding;
    padding-right: $padding;
  }
}

@mixin center-vertically() {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

@mixin center-both() {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.center-vertically {
  @include center-vertically();
}

.center-horizontally {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.center-both,
.center-vertically.center-horizontally {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

