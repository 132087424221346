.tabs {
  width: 100%;
  display: table;
  list-style-type: none;
  margin: 0;
  li {
    display: table-cell;
  }
}

// This is the low-opacity white bg
.tabs__tab {
  padding: 13px $base-spacing-unit;
  @include inuit-font-size($inuit-heading-size-1);
  display: block;
  text-align: center;
  font-weight: 100;
  color: #fff;
}

@include media-query(palm) {
  .tabs__tab {
    padding: $half-spacing-unit;
    @include inuit-font-size($inuit-heading-size-2);
    font-weight: 200;
  }
}

.tabs__tab--active {
  background-color: $base-background-color;
  color: #314559;
}

.dark-background .tabs__tab {
  color: #fff;
}

.dark-background .tabs__tab--active {
  color: #314559;
}

.tabs--x2 li {
  width: 50%;
}

.tabs--x3 li {
  width: 33.3333%;
}

.tabs--x4 li {
  width: 25%;
}

.fullwidth-tabs {
  position: relative;
  background-color: #7c8899;
}

.fullwidth-tabs__bg {
  span {
    visibility: hidden;
  }
}

.fullwidth-tabs__fg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  .tabs__tab {
    background: transparent;
  }
}

.tabs-learning-home {
  background-color: #7c8899;
}
