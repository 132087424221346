@use "sass:math";
@import '../../../../style/variables';
@import '../../../../style/tools';
@import '../../../../style/foundation/medshrsvariables';

.input {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1) !important;
  -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1) !important;
  -moz-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1) !important;
  -o-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1) !important;

  //color              : transparent !important;
  //text-shadow        : 0 0 0 $bright-brand-color !important;

  @include media-query(palm) {
    border: 1px solid rgba(0, 0, 0, 0.2);
  }

  //&.scrolling {
  //  &:focus {
  //    color       : $bright-brand-color !important;
  //    text-shadow : 0 0 0 transparent !important;
  //  }
  //}
}

.pleaseConfirm {
  color: $light-grey2;
  font-size: 1rem;
}

.textError {
  display: inline-block;
  padding: 0;
  font-size: 14px;
  font-weight: 300;
  margin: 0 0 15px;
}
