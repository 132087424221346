
.debug { display: none !important; }

.accessibility,
.visually-hidden,
%visually-hidden,
.hide { @include visually-hidden(); }
.display-none { display: none !important; }

@each $breakpoint in $breakpoints {

  // Grab the alias and the condition from their respective locations in
  // the list.
  $alias:     nth($breakpoint, 1);
  $condition: nth($breakpoint, 2);

  @include media-query($alias) {
    .hide-#{$alias} { @include visually-hidden(); }
    .not-#{$alias} { display: none !important; }
    .only-#{$alias} { display: block !important; }
  }

}

.fade {
  transition: opacity 0.26s ease-in;
}

.fade--out {
  opacity: 0.0;
}

.fade--in {
  opacity: 1.0;
}

