
.mobile-nav {
  padding-bottom: 4em;
}

.mobile-nav-primary {
  background-color: $start-gradient-color;
  background-image: linear-gradient($start-gradient-color, $mid-gradient-color);
}

.mobile-nav-primary__item {
  width: 33.3333%;
}

.mobile-nav-primary__link {
  display: block;
  padding: $base-spacing-unit 0;
  text-align: center;
  font-size: 20px;
  white-space: nowrap;

  .badge {
    margin-top: -8px;
    margin-right: 4px;
    border: 1px solid #fff;
  }
}

.mobile-nav-secondary {
  display: none;
  background-color: $off-white;
  position: absolute;
  right: 0;
  left: 0;
  .active {
    background-color: $base-background-color;
    color: $base-foreground-color !important;
  }
}

.active .mobile-nav-secondary {
  display: block;
}

.mobile-nav-secondary__item {
  width: 33.3333%;
}

.mobile-nav-secondary--2 .mobile-nav-secondary__item {
  width: 50%;
}

.mobile-nav-secondary--3 .mobile-nav-secondary__item {
  width: 33.3333%;
}

.mobile-nav-secondary__link {
  display: block;
  padding: $base-spacing-unit 0;
  text-align: center;
}

