@use "sass:math";
@import '../../../style/settings.palette';
@import '../../../style/variables';
@import '../../../style/tools';
@import '../../../style/foundation/mixins';

.container {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-between;
  align-content: stretch;
  background-color: $content-background-color;
  position: relative;

  @include media-query(palm) {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
  }
}

.header {
  height: 42px;
  background-color: $content-background-color;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  z-index: 10;

  @include media-query(palm) {
    height: 42px;
    position: fixed;
    z-index: 1200;
    top: 0;
    left: 0;
    width: 100%;
  }
}

.body {
  background-color: $content-background-color;
  margin-right: -16px;
  padding-right: 16px;
  min-height: 86px;

  @include media-query(lap-and-up) {
    min-width: 460px;
    max-height: 540px;
    overflow-x: hidden;
    overflow-y: auto;
    -ms-overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    // Hide scroll bar
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      width: 0;
    }
    &::-webkit-scrollbar-thumb {
      background-color: transparent;
      outline: 0;
    }
    &::-webkit-scrollbar {
      display: none;
    }
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 0 rgba(255, 255, 255, 0);
    }
  }

  @include media-query(palm) {
    min-height: calc(100vh - 42px);
    min-width: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
    -ms-overflow-y: hidden;
    -webkit-overflow-scrolling: auto;
    margin-top: 42px !important;
  }
}

.footer {
  background-color: $content-background-color;
  padding: 10px;
}

.btnSquare {
  border-radius: 0;
}
