@import '../../../style/variables';
@import '../../../style/tools';
@import '../../../style/foundation/medshrsvariables';

.inputWrapper {
  display: block;
  position: relative;

  svg {
    position: absolute;
    stroke: $low-text-color;
    z-index: 20;
    right: 9px;
    top: 10px;
  }

  > input {
    font-weight: 400;

    &::placeholder {
      text-overflow: ellipsis !important;
    }
  }
}

.withIcon {
  padding-right: 32px !important;
}

// CHECKBOX LABEL
.checkboxLabel {
  @include media-query(palm) {
    display: flex !important;
  }
}

// INNER
.checkboxLabelInner {
  @include media-query(palm) {
    position: relative !important;
    top: 0 !important;
    transform: none !important;
  }
}
