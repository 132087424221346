.pad--base {
  padding: $base-spacing-unit;
}

.margin-top--base {
  margin-top: $base-spacing-unit;
}

.margin-right--base {
  margin-right: $base-spacing-unit;
}

.margin-bottom--base {
  margin-bottom: $base-spacing-unit;
}

.margin-left--base {
  margin-left: $base-spacing-unit;
}

.margin--base {
  margin: $base-spacing-unit;
}
