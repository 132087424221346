@use "sass:math";
// FUNCTIONS
// MAP function helper
@function color($key) {
  @if map-has-key($colors, $key) {
    @return map-get($colors, $key);
  }

  @warn "Unknown `#{$key}` in $colors.";
  @return null;
}

// MIXINS
@mixin clearfix {
  &::before,
  &::after {
    display: table;
    content: ' ';

    @if $global-flexbox {
      flex-basis: 0;
      order: 1;
    }
  }

  &::after {
    clear: both;
  }
}

// Ellipsis
@mixin ellipsis($max-width) {
  display: inline-block;
  max-width: $max-width;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// grayscale    : grayscale(100%);
// sepia        : sepia(100%);
// saturate     : saturate(0%);
// hue-rotate   : hue-rotate(45deg);
// invert       : invert(100%);
// brightness   : brightness(15%);
// contrast     : contrast(200%);
// blur         : blur(2px);
@mixin img-filter($filter-arg) {
  filter: $filter-arg;
}

// Placeholder
@mixin placeholder {
  &.placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &::-webkit-input-placeholder {
    @content;
  }
}

// usage
// @include responsive-ratio(16,9);
@mixin responsive-ratio($x, $y, $pseudo: false) {
  $padding: unquote((math.div($y, $x)) * 100 + '%');
  @if $pseudo {
    &:before {
      @include pseudo($pos: relative);
      width: 100%;
      padding-top: $padding;
    }
  } @else {
    padding-top: $padding;
  }
}

@mixin pseudo($display: block, $pos: absolute, $content: '') {
  content: $content;
  display: $display;
  position: $pos;
}

// Triangles
@mixin css-triangle(
  $color,
  $direction,
  $size: 6px,
  $position: absolute,
  $round: false
) {
  @include pseudo($pos: $position);
  width: 0;
  height: 0;
  @if $round {
    border-radius: 3px;
  }
  @if $direction == down {
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-top: $size solid $color;
    margin-top: 0 - round(math.div($size, 2.5));
  } @else if $direction == up {
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-bottom: $size solid $color;
    margin-bottom: 0 - round(math.div($size, 2.5));
  } @else if $direction == right {
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-left: $size solid $color;
    margin-right: -$size;
  } @else if $direction == left {
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-right: $size solid $color;
    margin-left: -$size;
  }
}

@mixin centerer($horizontal: true, $vertical: true) {
  position: absolute;
  @if ($horizontal and $vertical) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  } @else if ($horizontal) {
    left: 50%;
    transform: translate(-50%, 0);
  } @else if ($vertical) {
    top: 50%;
    transform: translate(0, -50%);
  }
}
