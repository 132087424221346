.poll {
  // allow space for "show results"
  padding-bottom: 1em;
}

.resource section.poll h2,
.poll h2 {
  font-weight: bold;
  font-size: 100%;
  margin-bottom: $base-spacing-unit;
  line-height: 1.25;
}

.poll ol {
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 100%;
}

.poll__answer {
  background-color: transparent;
  border: 1px solid $light-grey;
  border-radius: 1px;
  color: #5f5f5f;
  display: block;
  margin-bottom: 10px;
  padding: 10px 10px;
  text-align: left;
  width: 100%;
  transition: background-color ease-in-out 0.26s, border-color ease-in-out 0.26s;
  position: relative;
}

button.poll__answer {
  color: $light-link-color;
}
button.poll__answer:hover {
  background-color: rgba(0, 0, 0, 0.01);
  border-color: $light-grey2;
}

.poll__answer-letter {
  font-weight: bold;
  position: absolute;
}

.poll p {
  color: $mid-text-color;
  margin: 0 0 10px;
}
.poll .smaller {
  display: block;
  font-size: 90%;
  margin-bottom: 0.25em;
}

.poll__answer-bar {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(190, 190, 190, 0.5);
}

.poll__answer--winner {
  color: $base-text-color;
}

.poll--expired .poll__answer--winner .poll__answer-bar {
  background-color: #b1d4f8;
}

.poll__answer-text {
  position: relative;
  z-index: 1;
  display: block;
  padding-right: 4em;
}

.poll__answer-answer {
  display: block;
  margin-left: 1.5em;
}

.poll__score {
  display: block;
  text-align: right;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

.poll__your_score {
  display: block;
  font-size: 0.8rem;
  color: $brand-color;
}

.poll__stats {
  color: $mid-text-color;
}

.poll--edit .poll__answer {
  color: $light-link-color;
}

.poll--extendOnly .poll__answer {
  color: #999;
}

.poll__answer input[type='text'] {
  background-color: transparent;
  border: 0;
  box-shadow: none;
  display: inline-block;
  margin-bottom: 0;
  padding: 5px 10px;
  width: 90%;
}

.poll__answer input[type='text']:focus {
  box-shadow: none;
}

.prepoll {
  background: $dark-background-color;
  padding-top: 15px;
  padding-bottom: $base-spacing-unit;
}

.cms-page .prepoll {
  position: absolute;
  top: 68px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
}

.cms-page .poll {
  .poll__answer--answered {
    border-color: #ccc;
    background-color: #f0f0f0;
  }
}

.prepoll-header {
  color: white;
  margin-bottom: $base-spacing-unit;
  h2 {
    margin: 0;
  }
}

.prepoll-content {
  background: $content-background-color;
  padding: $base-spacing-unit;
  margin-bottom: $base-spacing-unit * 2;
  border-radius: 1px;
  border: 5px solid rgba(218, 222, 225, 1);

  button.poll__answer {
    background-color: $brand-color;
    color: white;
    border-radius: 3px;
    transition: background-color 0.26s ease-in-out;
    &:hover {
      background-color: $active-button-background-color;
    }
  }

  .poll {
    margin-bottom: 0;
  }

  .poll-stats {
    display: none;
  }
}

.prepoll-skip {
  text-align: right;
}

@include media-query(palm) {
  .prepoll-header {
    padding: 0 $base-spacing-unit;
  }
  .prepoll-content {
    margin-left: $base-spacing-unit;
    margin-right: $base-spacing-unit;
  }
}
