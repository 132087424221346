@use "sass:math";
table {
  width: 100%;
}

.table--compact {
  th,
  td {
    padding: math.div($base-spacing-unit, 4) math.div($base-spacing-unit, 4);
  }
}
