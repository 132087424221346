@use "sass:math";
@import '../../../style/variables';
@import '../../../style/tools';
@import '../../../style/foundation/medshrsvariables';
@import '../../../style/foundation/mixins';

$image-layer: 1;
$overlay-layer: 2;
$text-layer: 3;

.imageOverlay {
  display: inline-block;
  position: relative;
  box-shadow: 0 0 0 1px $light-grey;
  transition: opacity 0.3s ease-in-out 150ms;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: $overlay-layer;
    transition: opacity 0.3s ease-in-out 150ms,
      visibility 0.3s ease-in-out 150ms;
  }

  // childs
  span {
    @include centerer(true, true);
    display: inline-block;
    text-align: center;
    z-index: $text-layer;
    font-size: $large-font-size;
    font-weight: 400;
    transition: opacity 0.3s ease-in-out 150ms,
      visibility 0.3s ease-in-out 150ms;
  }

  img {
    position: relative;
    z-index: $image-layer;
  }

  // ellpsis
  &.ellipsis {
    @include ellipsis(100%);
  }

  // Overlay Color modifier
  &.dark {
    &:before {
      background-color: $opa-60;
    }
    span {
      color: $content-background-color;
    }
  }

  &.light {
    &:before {
      background-color: $opa-w-60;
    }
    span {
      color: $mid-text-color;
    }
  }
}

.visibility {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.3s ease-in-out 150ms, visibility 0.3s ease-in-out 150ms;

  &:hover,
  &:focus {
    span,
    &:before {
      opacity: 0;
      visibility: hidden;
    }
  }
}
