@use 'sass:math';
.search-bar__parent-wrapper {
  background: $base-background-color;
}

@include media-query(palm) {
  .page__body .search-bar {
    display: none;
  }
}

.native-embed .search-bar {
  display: none;
}

.search-bar {
  padding: 13px 0;
  background-color: #021740;
  border-bottom: solid 1px rgba(255, 255, 255, 0.1);
  color: #fff;
  height: $search-bar-height;

  .sep {
    color: rgba($light-link-color, 0.4);
    padding: 0 math.div($base-spacing-unit, 4);
  }
  a {
    color: $light-link-color;
  }

  .page__container {
    position: relative;
  }
}

.search-bar__status {
  float: left;
  padding-top: 8px;
  opacity: 1;
  transition: opacity 0.26s ease-in-out;
}

.search-field {
  position: relative;
  .icon-search {
    position: absolute;
    left: 14px;
    top: 8px;
    z-index: 14;
    stroke: #a9a9a9;
    stroke-width: 2px;
  }

  .icon-x-circle {
    position: absolute;
    left: 100%;
    margin-left: -25px;
    top: 8px;
    cursor: text;
    opacity: 0;
    transition: opacity 0.26s ease-in-out;
    stroke: #999;
    z-index: 20;
  }
}

.search-field--has-value {
  .icon-x-circle {
    cursor: pointer;
    opacity: 1;
  }
}

.search-field__input {
  color: $base-text-color;
  padding: 8px $base-spacing-unit 9px 40px;
  width: 430px;
  border-radius: 200px;
  border: 0;
  transition: width 0.26s ease-in-out;
  outline: none;
}

@media screen and (max-width: 580px) {
  .specialties-modal .search-field__input {
    width: 300px;
  }
}

@include media-query(palm-portrait) {
  .specialties-modal .search-field__input {
    width: 200px;
  }
}

.search-bar__cancel {
  opacity: 0;
  position: absolute;
  right: $base-spacing-unit;
  top: 6px;
  transition: opacity 0.26s ease-in-out;
}

.search-bar--focused {
  .search-bar__status {
    opacity: 0;
  }

  .search-field__input {
    position: absolute;
    z-index: 10;
  }

  .search-bar__cancel {
    opacity: 1;
  }
}

@include media-query(lap) {
  .search-bar__container {
    width: 100% !important;
    padding: 0 20px;
  }
}

@include media-query(palm) {
  .search-bar__search {
    position: absolute;
    left: $base-spacing-unit;
    right: 8em;

    .search-field__input {
      width: 100%;
    }
  }
}

@include media-query(lap-and-up) {
  .search-bar__search {
    position: absolute;
    right: 0;
    transition: right 0.26s ease-in-out;
  }

  .search-bar--focused {
    position: fixed;
    width: 100%;
    z-index: $search-z-index;

    .search-bar__search,
    .search-field__input {
      width: 550px;
    }

    .search-bar__search {
      right: 210px;
    }
  }

  .search-bar--focused + div {
    padding-top: $search-bar-height;
  }
}

@include media-query(lap) {
  .search-bar__search {
    right: 20px;
  }
  .search-bar--focused {
    .search-bar__search {
      right: 150px;
    }
  }
}

/*
@include media-query(large-desk) {

  .search-bar--focused {
    .search-bar__search {
      right: 335px;
    }
  }

}
*/
