
.account-settings {
  margin-bottom: $base-spacing-unit;
}

.account-settings-password-form__reset {
  margin: $base-spacing-unit 0;
}

.delete-account {
  border-top: 1px solid #ccc;
  padding-top: $base-spacing-unit;
}

