.container {
  display: inline-block;
  vertical-align: bottom;
}

.icon {
  cursor: pointer;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  width: 20px;
  height: 20px;
}

.noHover {
  cursor: default !important;
  color: #314559;
  &:hover,
  &:focus {
    text-decoration: none !important;
  }
}

.likeList {
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    padding: 0;
    margin: 0;
  }
}
