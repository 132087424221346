.invite-picker__body {
  background-color: #fff;
  height: 289px;
  overflow-y: auto;
  overflow-x: hidden;
}

.invite-bar {
  padding: $base-spacing-unit 150px $base-spacing-unit $base-spacing-unit;
  background-color: $brand-color;
  color: #fff;
  position: relative;

  .btn {
    position: absolute;
    right: $base-spacing-unit;
    @include center-vertically;
  }
}

.simple-results__divider {
  display: flex;
  justify-content: space-between;
  background-color: #f2f2f2;
  padding: 10px 20px;
  position: relative;
  margin-top: -1px;
}

.simple-results__row {
  font-size: $large-font-size;
  padding: 0 $base-spacing-unit;

  & > .custom-checkbox {
    display: block;
    position: relative;
    label {
      margin: 0;
      padding: 0;
      height: 60px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      width: 100%;

      @include media-query(palm) {
        height: auto;
        min-height: 60px;
      }
    }
  }

  & > .custom-checkbox .custom-checkbox__icon {
    @include center-vertically;
    right: 0;
  }

  & > .custom-checkbox > .custom-checkbox__label {
    position: relative;

    & > .custom-checkbox__label-inner {
      @include center-vertically;
      width: 100%;
      padding-right: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}

.simple-results__row--divider {
  border-bottom: 4px solid $base-foreground-color;
}

.simple-results_invited {
  position: relative;
}

.simple-results__row__status {
  position: absolute;
  right: 60px;
  top: 50%;
  transform: translateY(-50%);
}

.simple-results__row-subtitle {
  color: $base-foreground-color;
  font-size: $base-font-size;
  display: block;
}

@include media-query(lap-and-up) {
  .simple-results__row-subtitle {
    margin-right: 1em;
    float: right;
  }
}

.invite-picker__email-share {
  padding: 15px 0;
  display: block;
  position: relative;
  .btn,
  .btn:hover {
    color: #fff;
    border-color: #fff;
  }
  .btn:hover .icon__content,
  .icon__content {
    stroke: none;
    fill: #fff;
  }
}

.simple-results__row .invite-picker__email-share {
  color: $light-link-color;
  .btn {
    color: $light-link-color;
    border-color: $light-link-color;
  }
  .icon__content {
    fill: $light-link-color;
  }
  .btn:hover {
    background-color: $link-color;
    border-color: $link-color;
  }
  .btn:hover .icon__content {
    fill: #fff;
  }
}

.invite-picker__email-button {
  position: absolute;
  right: 0;
  top: 10px;
  font-size: $base-font-size;
}

.invite-picker__invited-profile-previews-wrapper {
  padding: 5px 10px;
  margin-bottom: -5px;
}

.invite-picker__invited-profile-preview {
  padding: 6px 15px 6px 6px;
  border: solid 1px rgba(0, 0, 0, 0.1);
  border-radius: 100px;
  display: inline-flex;
  margin-right: 5px;
  margin-bottom: 5px;
  align-items: center;
}

.invite-picker__invited-profile-img {
  width: 25px;
  height: 25px;
  border-radius: 25px;
  margin-right: 10px;
}

@include media-query(palm) {
  .invite-picker__email-share {
    display: none;
  }

  .invite-picker .prompt-text {
    display: none;
  }

  .simple-results__row {
    & > .custom-checkbox {
      label {
        border-bottom: 0;
      }
    }
  }
}

.invite-picker-header__input-wrapper {
  background: #09336a;
  padding: 10px;
}

.invite-picker-header__input {
  border-radius: 200px;
  outline: none;
  height: 40px;
  text-indent: 20px;
  border: solid 1px rgba(0, 0, 0, 0.1);
  width: 100%;
}

.invite-picker-header__tab-buttons-wrapper {
  display: flex;
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
}

.invite-picker-header__tab-button {
  flex-grow: 1;
  flex-basis: 0;
  padding: 10px;
  outline: none;
  border: none;
  border-bottom: solid 3px #fff;
  transition: border-bottom 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
}

.invite-picker-header__tab-button[disabled] {
  opacity: 0.5;
}

.invite-picker-header__tab-button.active {
  border-bottom: solid 3px #09336a;
}

.invite-picker__empty-results {
  padding: 60px 20px;
  text-align: center;
  max-width: 350px;
  margin: 0 auto;
}

.invite-picker__footer-wrapper {
  background: #09336a;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  position: absolute;
  bottom: 0;
  width: 100%;
  color: white;
  padding: 0px 20px;
}

.invite-picker__selections-slide-up-wrapper {
  background: white;
  position: absolute;
  bottom: 60px;
  top: 0px;
  width: 100%;
  overflow: auto;
}

.invite-picker__selections-slide-up-fixed-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  padding: 20px;
  width: 100%;
}

.invite-picker__resource-type-heading {
  padding: 0px 20px;
  height: 35px;
  background: #f2f2f2;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.invite-picker-row__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);

  &:last-child {
    border-bottom: none;
  }
}

.invite-picker-row__img {
  width: 35px;
  border-radius: 35px;
  margin-right: 10px;
}
