
/*
@include media-query(large-desk) {

  .u-6-of-12 .feed__entry:nth-child(3n) .card {
    margin-right: 0;
  }

}
*/

@include media-query(desk) {

  .u-6-of-12 .feed__entry:nth-child(even) .card {
    margin-right: 0;
  }

}

