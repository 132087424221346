@use 'sass:math';
.btn {
  display: inline-block;
  vertical-align: middle;
  white-space: nowrap;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.75;
  cursor: pointer;
  border: none;
  margin: 0;
  padding: 1px 1em 0;
  text-decoration: none;
  border-radius: $brand-round;
  outline: none;
  font-weight: 200;

  &:focus {
    outline: 0;
    box-shadow: 0 1px 5px 0px rgba(#999, 0.6);
  }

  &.space-bottom {
    margin-bottom: math.div($base-spacing-unit, 1.5);
  }

  &:active,
  &:focus,
  &:hover {
    text-decoration: none;
  }
}

.btn--active--disabled {
  background-color: $brand-color !important;
  cursor: wait;
  opacity: 1 !important;
}

.btn--active {
  background-color: $dark-background-color !important;
  color: $off-white !important;
  border-color: $off-white !important;

  &:hover,
  &:focus {
    border-color: $off-white !important;
  }
}

@include media-query(palm) {
  .btn {
    font-weight: 300;
  }
}

@include media-query(lap-and-up) {
  .no-touchevents .btn:not(.btn--disabled, [disabled]):hover {
    text-decoration: none;
  }
}

@mixin btn-foreground($color) {
  color: $color;
  .icon__content {
    stroke: $color;
    fill: $color;
  }
}

.btn {
  @include btn-foreground($light-link-color);
  background-color: transparent;
  border: 2px solid;
  border-radius: 200px;
  transition: background-color 0.1s ease-in-out, color 0.1s ease-in-out,
    border-color 0.1s ease-in-out, opacity 0.1s ease-in-out;
}

@include media-query(lap-and-up) {
  .btn:not(.btn--disabled, [disabled]).ui-state-hover,
  .no-touchevents .btn:not(.btn--disabled, [disabled]):hover {
    @include btn-foreground(#fff);
    background-color: $link-color;
    border-color: $link-color;
  }
}

.btn--secondary {
  @include btn-foreground(#fff);
  background-color: $color-core-brand-accent-100;
  border-color: $color-core-brand-accent-100;
}

@include media-query(lap-and-up) {
  .btn--secondary:not(.btn--disabled, [disabled]).ui-state-hover,
  .btn--secondary:not(.btn--disabled, [disabled]):hover,
  .no-touchevents .btn--secondary:not(.btn--disabled, [disabled]):hover {
    @include btn-foreground(#fff);
    background-color: darken($color-core-brand-accent-100, 10%);
    border-color: darken($color-core-brand-accent-100, 10%);
  }
}

.dark-background {
  .btn--secondary.btn--fill,
  .btn--secondary {
    @include btn-foreground($color-core-brand-first-blue-100);
    background-color: $color-core-brand-accent-60;
    border-color: $color-core-brand-accent-60;
  }

  @include media-query(lap-and-up) {
    .btn--secondary:not(.btn--disabled, [disabled]).ui-state-hover,
    .btn--secondary:not(.btn--disabled, [disabled]):hover,
    .no-touchevents .btn--secondary:not(.btn--disabled, [disabled]):hover,
    .btn--secondary.btn--fill:not(.btn--disabled, [disabled]).ui-state-hover,
    .btn--secondary.btn--fill:not(.btn--disabled, [disabled]):hover,
    .no-touchevents
      .btn--secondary.btn--fill:not(.btn--disabled, [disabled]):hover {
      @include btn-foreground($color-core-brand-first-blue-100);
      background-color: darken($color-core-brand-accent-60, 10%);
      border-color: darken($color-core-brand-accent-60, 10%);
    }
  }
}

.btn--danger {
  @include btn-foreground($danger-color);
  background-color: transparent;
  border: 2px solid;
}

.btn--off,
.btn[disabled],
.btn--disabled {
  opacity: 0.5;
  cursor: default;
}

.no-touchevents .btn--off:hover {
  opacity: 1;
}

.btn--dark {
  @include btn-foreground($base-text-color);
  border-color: $base-text-color;
  background-color: transparent;
  border: 2px solid;
}

@include media-query(lap-and-up) {
  .btn--dark:not(.btn--disabled, [disabled]).ui-state-hover,
  .no-touchevents .btn--dark:not(.btn--disabled, [disabled]):hover {
    @include btn-foreground(#fff);
    background-color: $base-text-color;
    border-color: $base-text-color;
  }
}

.btn--small {
  line-height: 2;
  font-size: 80%;
}

.btn--medium {
  font-size: 120%;
  line-height: 2;
  border-radius: 6px;
}

.btn--large {
  font-size: 150%;
  line-height: 2.5;
  border-radius: 6px;
}

.btn--pill {
  border-radius: 200px;
}

.btn--large.btn--pill {
  border-radius: 200px;
}

.btn--wide {
  padding-left: 2em;
  padding-right: 2em;
}

.btn--block {
  width: 100%;
}

.btn--center-text {
  text-align: center;
}

.btn--fill {
  @include btn-foreground(#fff);
  background-color: $bright-brand-color;
  border-color: $bright-brand-color;
}

.btn--whiteStroke,
.btn--whiteStroke:hover {
  border-color: #fff !important;
}

@include media-query(lap-and-up) {
  .btn--fill:not(.btn--disabled, [disabled]).ui-state-hover,
  .no-touchevents .btn--fill:not(.btn--disabled, [disabled]):hover {
    border-color: $link-color;
    @include btn-foreground(#fff);
    background-color: $link-color;
  }
}

.btn--dark.btn--fill {
  @include btn-foreground(#fff);
  background-color: $base-text-color;
  border-color: $base-text-color;
}

@include media-query(lap-and-up) {
  .btn--dark.btn--fill:not(.btn--disabled, [disabled]).ui-state-hover,
  .no-touchevents .btn--dark.btn--fill:not(.btn--disabled, [disabled]):hover {
    @include btn-foreground(#fff);
    background-color: $link-color;
    border-color: $link-color;
  }
}

.btn--danger.btn--fill {
  background-color: $danger-color;
  border-color: $danger-color;
  &.btn--active,
  &.btn--active--disabled,
  &:active,
  &:hover {
    background-color: $danger-color !important;
    border-color: $danger-color !important;
  }
}

@include media-query(lap-and-up) {
  .btn--danger.btn--fill:not(.btn--disabled, [disabled]).ui-state-hover,
  .btn--danger.btn--fill:not(.btn--disabled, [disabled]).btn--active,
  .btn--danger.btn--fill:not(.btn--disabled, [disabled]).btn--active--disabled,
  .btn--danger.btn--fill:not(.btn--disabled, [disabled]):active,
  .no-touchevents .btn--danger:not(.btn--disabled, [disabled]):hover {
    @include btn-foreground(#fff);
    background-color: darken($danger-color, 10%) !important;
    border-color: darken($danger-color, 10%) !important;
  }
}

.btn > .icon {
  margin-left: 0.5em;
}

.btn--wide > .icon {
  margin-left: 0.5em;
}

.media__body .btn > .icon-user-connect {
  margin-left: 0.25em;
}

.dark-background {
  .btn,
  .btn--fill,
  a.btn--fill {
    @include btn-foreground(#fff);
    border-color: #fff;
  }

  .btn:not(.btn--disabled, [disabled]).ui-state-hover,
  .btn:not(.btn--disabled, [disabled]):hover {
    border-color: #fff;
    @include btn-foreground(#fff);
    background-color: rgba(255, 255, 255, 0.2);
  }

  .btn--fill:not(.btn--disabled, [disabled]).ui-state-hover,
  .btn--fill:not(.btn--disabled, [disabled]):hover {
    border-color: $link-color;
    @include btn-foreground(#fff);
    background-color: $link-color;
  }

  .btn--dark,
  .btn--dark:not(.btn--disabled, [disabled]),
  .btn--dark.ui-state-hover,
  .btn--dark:not(.btn--disabled, [disabled]).ui-state-hover,
  .btn--dark:hover,
  .btn--dark:not(.btn--disabled, [disabled]):hover {
    color: #fff;
    border-color: $base-text-color;
    background-color: $base-text-color;
  }

  .btn--primary:not(.btn--disabled, [disabled]):hover {
    border-color: #fff;
    @include btn-foreground($light-link-color);
    background-color: #fff;
  }

  .btn--off:not(.btn--disabled, [disabled]):hover {
    border-color: rgba(255, 255, 255, 0.5);
  }

  .btn--danger {
    @include btn-foreground(#fff);
    border-color: $danger-color;
    background-color: $danger-color;
  }

  .btn--danger.ui-state-hover,
  .btn--danger:not(.btn--disabled, [disabled]):hover,
  .btn--danger:hover {
    @include btn-foreground($danger-color);
    border-color: #fff;
    background-color: #fff;
  }
}

.btn--custom-checkbox {
  width: 30px;
  height: 30px;
  padding: 0;
  position: relative;
  overflow: visible;

  > .icon {
    margin-left: 0;
  }

  > a > span,
  > span {
    position: absolute;
    top: 0;
    right: 100%;
    margin-right: 10px;
    text-decoration: underline;
  }
}

.checkbox__icon_opa-50 {
  opacity: 0.5;
}

.no-touchevents .btn--custom-checkbox:not(.btn--disabled, [disabled]):hover {
  @include btn-foreground($light-link-color);
  background-color: transparent;
  span {
    text-decoration: underline;
  }
}

.btn--custom-checkbox.btn--dark:not(.btn--disabled) {
  span {
    @include btn-foreground($base-text-color);
  }
}

.no-touchevents
  .btn--custom-checkbox.btn--dark:not(.btn--disabled, [disabled]):hover {
  border-color: $base-text-color;
}

@include media-query(lap-and-up) {
  .no-touchevents
    .btn--custom-checkbox.btn--dark:not(.btn--disabled, [disabled]):hover {
    @include btn-foreground(#fff);
    background-color: $base-text-color;
    border-color: $base-text-color;
    color: $base-text-color;
  }
}

.text-icon-link {
  display: flex;
  justify-content: flex-end;
  max-width: 100%;
  align-items: center;
}

.text-icon-link__text {
  display: inline-block;
  color: $light-link-color;
  text-decoration: underline;
  margin-right: 3px;
  min-width: 100px;
  text-align: right;
  cursor: pointer;
  font-size: 0.95em;
  line-height: 1.1em;
}

.text-icon-link .icon {
  width: 29px;
  min-width: 29px;
  height: 28px;
  margin: 0;
  padding: 6px;
  fill: $light-link-color;
  border: 2px solid $light-link-color;
  border-radius: 200px;
}

@include media-query(desk-and-up) {
  .btn--iconic {
    width: 30px;
    height: 30px;
    padding: 0;
    position: relative;
    overflow: visible;
    font-size: 0.95em;

    > .icon {
      margin-left: 0;
      margin-top: 6px;
    }

    > .icon-user {
      margin-left: 0;
      margin-top: 6px;
    }

    > .icon-user-connect {
      margin-left: 3px;
      margin-top: 6px;
    }

    > .icon-spinner {
      margin-left: 0;
      margin-top: 7px;
    }

    &.stethoscope {
      > .icon {
        margin-left: 7px;
        margin-top: 7px;
      }
    }

    &.stethoscope2 {
      > .icon {
        margin-left: 1px;
        margin-top: 7px;
      }
    }

    > a > span,
    > span {
      position: absolute;
      top: -1px;
      right: 100%;
      margin-right: 8px;
      text-decoration: underline;
      color: $light-link-color;
    }
  }

  .no-touchevents .btn--iconic:not(.btn--disabled, [disabled]):hover {
    span {
      color: $light-link-color;
    }
  }

  .btn--iconic.btn--dark,
  .no-touchevents .btn--iconic.btn--dark:hover {
    cursor: default;
    span {
      text-decoration: none;
    }
  }

  .btn--iconic.btn--dark:not(.btn--disabled) {
    span {
      @include btn-foreground($base-text-color);
    }
  }

  .no-touchevents .btn--iconic.btn--dark:not(.btn--disabled, [disabled]):hover {
    @include btn-foreground(#fff);
    background-color: $base-text-color;
    border-color: $base-text-color;
    color: $base-text-color;
  }

  .invite-picker__email-button .icon-email {
    margin-left: 1px;
    margin-top: 7px;
  }

  .btn--iconic--mini {
    border: 0;
    font-size: 90%;
    width: 20px;
    height: 20px;
  }
  .no-touchevents
    .btn--iconic.btn--iconic--mini:not(.btn--disabled, [disabled]):hover {
    @include btn-foreground($link-color);
    background-color: transparent;
    border-color: transparent;
  }
}

@include media-query(portable) {
  .btn--iconic {
    border: 0;
    border-radius: 0;
    padding: 0;
    text-decoration: underline;
    .icon {
      display: none;
    }
  }

  .btn--iconic.btn--fill {
    @include btn-foreground($light-link-color);
    background-color: transparent;
  }

  .btn--iconic:not(.btn--disabled, [disabled]).ui-state-hover,
  .no-touchevents .btn--iconic:not(.btn--disabled, [disabled]):hover {
    @include btn-foreground($light-link-color);
    background-color: transparent !important;
  }
}

.btn--with-icon {
  position: relative;
  padding-right: 2em;

  .icon {
    @include center-vertically;
    // works for x-circle, may need to re-jig when we style other icons inside button
    right: 4px;
  }
}

.btn--connect {
  .icon-cross {
    margin-left: 2px;
  }

  .icon-tick {
    margin-left: 3px;
  }

  .icon-user-connect {
    //margin-left : 2px;
  }
}

.btn {
  .icon-group {
    margin-bottom: -1px;
  }
}

.primary-action {
  position: fixed;
  bottom: $base-spacing-unit;
  right: $base-spacing-unit;
  z-index: $primary-action-z-index;
  width: 70px;
  height: 70px;
  border-radius: 35px;
  border: 0;
  background: $light-link-color;
  color: #fff;
  text-align: center;
  word-wrap: normal;
  font-size: $small-font-size;

  .icon {
    fill: #fff;
  }
}

.btn--white {
  color: #fff;
  background-color: transparent;
  //border           : 1px solid;
  border: 0;
  box-shadow: 0 0 0 1.5px white;

  &:hover {
    color: $base-text-color;
    background-color: #fff;
    border-color: #fff;
    text-decoration: none;
  }
}

.btn--square {
  border-radius: 0 !important;
}

.btn-list {
  .btn {
    margin-right: 1em;
  }
}

.btn--promote .icon {
  margin-left: 1px;
}

.profile-basic__remove .icon {
  margin-left: 2px;
}

.btn.btn--promote--promote .icon__content {
  fill: transparent;
  stroke: $light-link-color;
  stroke-width: 1.5px;
}

.btn.btn--promote--pending .icon__content {
  fill: #ccc;
  stroke: #ccc;
}

.btn.btn--promote--demote .icon__content {
  fill: #fc0;
  stroke: #fc0;
}

.btn-group {
  display: inline-flex;
  border-radius: 100px;
  overflow: hidden;
  @include btn-foreground($light-link-color);
  border: 2px solid $light-link-color;
  outline: none;

  button {
    border: none;
    background-color: transparent;
    display: flex;
    align-items: center;
    line-height: 1.75;
    border-right: solid 1px rgba(0, 0, 0, 0.2);
    padding: 1px 1em 0;
    outline: none;

    &.active {
      background: $bright-brand-color;
      .icon__content {
        fill: white !important;
      }
    }
    &:last-child {
      border-right: none;
    }
  }

  &.btn-group--fill {
    button {
      // background-color: $light-link-color;
      border-color: rgba(255, 255, 255, 0.1);
      color: white;
    }
  }
}

.btn--iconic-small {
  padding: 5px;
  align-self: center;
  width: 2.5rem;
  height: 2.5rem;
  line-height: 1;
  position: relative;
  &.btn--connect--sent {
    > span {
      position: absolute;
      right: 40px;
      top: 0;
      text-align: right;
      .top {
        display: block;
        span {
          display: block;
        }
      }
    }
  }
  &:not(.btn--connect--sent) {
    span {
      @include visually-hidden();
    }
  }
  .icon {
    margin: 0;
  }
}
