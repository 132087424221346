a,
.fake-link {
  color: $link-color;
  cursor: pointer;
  text-decoration: none;
}

.action-link {
  color: $light-link-color;
  cursor: pointer;
  text-decoration: underline;
  //font-weight             : 400;
  //-webkit-font-smoothing  : antialiased;
  //-moz-osx-font-smoothing : grayscale;

  .icon {
    stroke: $light-link-color;
    margin-left: 0.5em;
  }

  &:focus {
    outline: 0;
  }
}

.truncated-link {
  @include ellipsis(calc(100vw - 165px));
}

.dark-background {
  .fake-link,
  .action-link {
    text-decoration: none;
  }
}

a.ui-state-hover,
.fake-link.ui-state-hover,
.action-link.ui-state-hover,
a:hover,
.fake-link:hover,
.action-link:hover {
  text-decoration: underline;
  cursor: pointer;
}

a.media__title:hover,
a.activity__title:hover {
  text-decoration: none;
}

.activity__title {
  &.bold {
    font-weight: 400;
  }
}

button.fake-link,
button.action-link {
  border: 0;
  background: transparent;
  appearance: none;
  padding: 0;
}

a.active {
  color: $low-text-color;
  cursor: default;
}

a.active.ui-state-hover,
a.active:hover {
  text-decoration: none;
}

.action-link.btn--disabled {
  text-decoration: none;
  cursor: default;
}

.dark-background {
  a,
  .fake-link {
    color: $light-link-color;
  }

  a.active {
    color: #fff;
  }

  .action-link {
    color: #fff;
    text-decoration: underline;

    .icon {
      stroke: #fff;
      fill: #fff;
      vertical-align: bottom;
      margin-left: 0.5em;
    }

    .icon-arrow-up,
    .icon-arrow-down {
      vertical-align: baseline;
    }

    .icon-pencil {
      fill: none;
      stroke: #fff;
    }

    .icon-pdf {
      fill: #fff;
      stroke: none;
    }

    &:hover {
      opacity: 0.8;
    }
  }
}
