@use "sass:math";
@import '../../../../style/variables';
@import '../../../../style/tools';

.uploadImg {
  padding: 1.4rem 1.7rem;
  border: 2px solid $bright-brand-color;
  display: inline-block;
  border-radius: 80px;

  img {
    height: auto;
  }

  @include media-query(lap-and-up) {
    img {
      max-width: 40px;
    }
  }

  @include media-query(palm) {
    img {
      max-width: 25px;
    }
  }
}

.showMeExample {
  margin-bottom: 10px;
  font-size: 98%;
}

.uploadBtn {
  margin-bottom: 10px;
}

.emailUs {
  color: $mid-text-color;
  a {
    &:hover,
    &:focus {
      color: $brand-color;
      outline: 0;
    }
  }
}

.link {
  color: $light-link-color;
  text-decoration: underline;

  &:hover,
  &:focus {
    color: $brand-color;
    outline: 0;
  }
}
