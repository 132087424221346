.social-signin__item {
  display: flex;
  height: 58px;
  border-radius: 8px;
  color: #fff;
  font-size: 130%;
  margin-bottom: 20px;
  position: relative;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  width: 24rem;
  svg {
    position: absolute;
    left: 17px;
  }
}

.social-signin__item--facebook {
  background-color: #4267b2;
  svg {
    left: 15px;
  }
}

.social-signin__item--google {
  background-color: #4285f4;
  svg {
    left: 10px;
  }
}

.social-signin__item--apple {
  background-color: #4c5862;
}

.social-signin__item--linkedin {
  background-color: #0077b5;
}
