.subscriptions__section {
  padding: 20px 10px;
}

.subscription__controls {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 15px;
}

.subscription__controls .controls--wrapper {
  display: flex;
  align-self: center;
  gap: 10px;
}

.subscriptions__table {
  font-size: 16px;
}

.subscriptions__table--tr {
  flex: none;
  flex-grow: 0;
  font-style: normal;
  font-weight: 300;
  line-height: 22px;
  color: #4c5862;
}

.subscriptions__table--td {
  padding: 0.5rem;
  width: 1%;
  border-bottom: 1px solid #e0e0e0;
}

.subscriptions__table--td-title {
  font-weight: bold;
  line-height: 33px;
  border-bottom: 1px solid #ccc;
}

.subscriptions__table--td-subtitle {
  font-weight: 300;
  font-size: 16px;
  color: #999;
}

.dropdown-indicator-filter {
  font-size: 14px;
  line-height: 25px;

  font-weight: 700;
  color: #092c4c;

  flex: none;
  order: 0;
  flex-grow: 0;
  margin-right: 10px;
}
